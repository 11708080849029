export default class PageAccessService {
    constructor($sbPermission, SABLONO_STATES, $sbFeatureDecisions, PageFlags) {
        "ngInject";

        const PAGE = SABLONO_STATES; // just to avoid some line breaks ;)

        this.$sbPermission = $sbPermission;
        this.$sbFeatureDecisions = $sbFeatureDecisions;

        this.stateToRequiredFeature = new Map();
        this.stateToFeaturePackage = new Map();

        const Feature = this.$sbPermission.Feature;

        const DASHBOARD_PAGE_DEFINITION = [
            [PAGE.dashboardPro, PageFlags.PageNotMappedToFeatureFlagYet],
        ];

        const COMMERCIAL_PAGE_DEFINITION = [
            [PAGE.commercialDashboard, PageFlags.Commercial],
            [PAGE.commercialProDashboard, PageFlags.CommercialValuation],
            [
                PAGE.requestAccessCommercialProDashboard,
                PageFlags.RequestAccessCommercialProDashboard,
            ],
            [
                PAGE.requestAccessCommercialDashboard,
                PageFlags.RequestAccessCommercialDashboard,
            ],
        ];

        const UNRESTRICTED_PAGE_DEFINITIONS = [
            [PAGE.profile, PageFlags.PageNotMappedToFeatureFlagYet],
            [PAGE.projectSettings, PageFlags.ProjectSettings],
            [PAGE.projectSelection, PageFlags.PageNotMappedToFeatureFlagYet],
            [PAGE.info, PageFlags.PageNotMappedToFeatureFlagYet],
        ];

        const PAGE_ACCESS_DEFINITION = [
            ...UNRESTRICTED_PAGE_DEFINITIONS.map(([state, featurePackage]) => [
                state,
                undefined, // all pages in the list share no access requirement
                featurePackage,
            ]),
            ...DASHBOARD_PAGE_DEFINITION.map(([state, featurePackage]) => [
                state,
                Feature.Review, // all dashboard pages share this access requirement
                featurePackage,
            ]),
            ...COMMERCIAL_PAGE_DEFINITION.map(([state, featurePackage]) => [
                state,
                Feature.Commercial, // all commercial pages share this access requirement
                featurePackage,
            ]),

            [PAGE.siteDiary, Feature.Work, PageFlags.SiteDiary],
            [PAGE.leanboard, Feature.Work, PageFlags.LeanPlanning],
            [
                PAGE.requestAccessLeanPlanning,
                Feature.Work,
                PageFlags.RequestAccessLeanPlanning,
            ],
            [PAGE.tools, Feature.Plan, PageFlags.ImportExport],
            [
                PAGE.templates,
                Feature.Plan,
                PageFlags.PageNotMappedToFeatureFlagYet,
            ],
            [PAGE.checklistTemplatesList, Feature.Plan, PageFlags.QaChecklists],
            [
                PAGE.requestAccessQAChecklists,
                Feature.Plan,
                PageFlags.RequestAccessQAChecklists,
            ],
            [
                PAGE.checklistTemplatesEditor,
                Feature.Plan,
                PageFlags.PageNotMappedToFeatureFlagYet,
            ],
            [PAGE.teams, Feature.Plan, PageFlags.PageNotMappedToFeatureFlagYet],
            [PAGE.noteTemplates, Feature.Plan, PageFlags.NoteTemplates],
            [PAGE.wbs, Feature.Plan, PageFlags.ProjectStructure],
            [PAGE.export, Feature.Plan, PageFlags.ImportExport],
            [PAGE.visualizationSketch, Feature.Work, PageFlags.VisualTrackers],
            [
                PAGE.requestAccessVisualTrackers,
                Feature.Work,
                PageFlags.RequestAccessVisualTrackers,
            ],
            [
                PAGE.visualizationGenerated,
                Feature.Work,
                PageFlags.VisualTrackers,
            ],
            [
                PAGE.visualizationConfiguration,
                Feature.Plan,
                PageFlags.VisualTrackers,
            ],
            [PAGE.deliverablesProgress, Feature.Review, PageFlags.Deliverables],
            [
                PAGE.requestAccessDeliverables,
                Feature.Review,
                PageFlags.RequestAccessDeliverables,
            ],
            [PAGE.issues, Feature.Review, PageFlags.Notes],
            [
                PAGE.requestAccessNotes,
                Feature.Review,
                PageFlags.RequestAccessNotes,
            ],
            [PAGE.visualization, Feature.Work, PageFlags.VisualTrackers],
            [
                PAGE.timeline,
                Feature.Review,
                PageFlags.PageNotMappedToFeatureFlagYet,
            ],
            [
                PAGE.tracker,
                Feature.Review,
                PageFlags.PageNotMappedToFeatureFlagYet,
            ],
            [
                PAGE.processViewer,
                Feature.Review,
                PageFlags.PageNotMappedToFeatureFlagYet,
            ],
        ];

        PAGE_ACCESS_DEFINITION.forEach(([state, privilege, featurePackage]) => {
            this.registerState(state, privilege, featurePackage);
        });
    }

    registerState(state, requiredFeature, requiredFeaturePackage) {
        this.stateToRequiredFeature.set(state, requiredFeature);
        this.stateToFeaturePackage.set(state, requiredFeaturePackage);
    }

    isAccessible(state, withPrivilege) {
        const feature = this.stateToRequiredFeature.get(state);
        const requiredFeaturePackage = this.stateToFeaturePackage.get(state);

        if (!this.isPrivilegeGranted(withPrivilege, feature)) {
            return false;
        }

        return this.$sbFeatureDecisions.isFeatureEnabled(
            requiredFeaturePackage
        );
    }

    isPrivilegeGranted(privilege, feature) {
        if (feature === undefined) {
            return true;
        }

        if (privilege === undefined) {
            return false;
        }

        return this.$sbPermission.hasPermission(privilege, feature);
    }
}
