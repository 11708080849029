const translation = {
    LANGUAGE: "de",
    SABLONO: "Sablono",

    /**
     * DICTIONARY
     */

    _DECIMAL: "{{n}},{{m}}",

    _SECONDS: "Sekunden",
    _MINUTES: "Minuten",
    _HOUR: "Stunde",
    _HOURS: "Stunden",
    _WORK_HOUR: "Arbeitsstunde",
    _WORK_HOURS: "Arbeitsstunden",
    _DAY: "Tag",
    _DAYS: "Tage",
    _WORKDAY: "Arbeitstag",
    _WORKDAYS: "Arbeitstage",
    _YEARS: "Jahre",

    //Aliases for units
    _wd: "Arbeitstag{{duration == 1 ? '' : 'e'}}",
    _wh: "Arbeitsstunde{{duration == 1 ? '' : 'n'}}",
    _h: "Stunde{{duration == 1 ? '' : 'n'}}",
    _d: "Tag{{duration == 1 ? '' : 'e'}}",

    _wd_ABBREVIATION: "AT",
    _wh_ABBREVIATION: "Ah",
    _h_ABBREVIATION: "h",
    _d_ABBREVIATION: "KT",

    _LAST_MODIFIED: "Zuletzt bearbeitet",
    _SCHEDULE: "Zeitplan",

    _ON_SCHEDULE: "Im Zeitplan",
    _AT_RISK: "Gefährdet",
    _BEHIND_SCHEDULE: "Hinter dem Zeitplan",
    _BEHIND_BASELINE_SCHEDULE: "Hinter dem ursprünglichen Zeitplan",
    _AHEAD_SCHEDULE: "Vor SOLL",
    _UNSCHEDULED: "Ungeplant",
    _NO_PROCESS: "Ohne Workflow",

    _PLANNED: "Geplantes Soll",
    _PLANNED_TO_BE_DONE: "Geplantes Soll",
    _ACTUAL: "Aktuelles Ist",

    _ISSUES_CLOSED: "Probleme behobene",
    _NEW_NOTES: "Neue Meldungen",
    _NOTES: "Meldungen",
    _NOTE_TEXT: "Meldungstext",
    _ISSUE_TEMPLATE_CODE: "Meldungsvorlage",

    _CLAIM: "Mangel",
    _CLAIMS: "Mängel",
    _OPEN_CLAIM: "noch nicht behobener Mangel",
    _OPEN_CLAIMS: "noch nicht behobene Mängel",

    _OBSTRUCTION: "Behinderung",
    _OBSTRUCTIONS: "Behinderungen",
    _OPEN_OBSTRUCTION: "noch nicht behobene Behinderung",
    _OPEN_OBSTRUCTIONS: "noch nicht behobene Behinderungen",

    _CLOSED_CLAIMS: "Behobene Mängel",
    _OPEN_ISSUE: "noch nicht behobenes Problem",
    _CLOSE_ISSUE: "Problem behoben",
    _CLOSED_ISSUES: "Behobene Probleme",
    _CLOSED_OBSTRUCTIONS: "Behobene Behinderungen",

    _ISSUE_STATUS: "Status",
    _ISSUE_STATUS_OPEN: "Offen",
    _ISSUE_STATUS_CLOSED: "Behoben",
    _ISSUE_STATUS_WFC: "In Prüfung",
    _ISSUE_STATUS_REJECTED: "Nicht behoben",
    _ISSUE_STATUS_CONFIRMED: "Behoben",
    _ISSUE_STATUS_REMOVED: "Gelöscht",
    _ISSUE_STATUS_NOT_REMOVED: "Nicht gelöscht",
    INPUT_INCLUDE_REMOVED_ISSUES_LABEL: "Gelöscht",

    _INFO_S: "Informationen",
    _NO_NOTES: "Keine",
    _ISSUE_TYPE_INFO: "Information",

    _OPEN_CLAIMS_N:
        "{{items}} {{ (items === 1) ? 'offener Mangel' : 'offene Mängel' }}",
    _OPEN_ISSUES_N:
        "{{issues}} {{ (issues === 1) ? 'ungelöstes Problem' : 'ungelöste Probleme' }}",
    _OPEN_OBSTRUCTIONS_N:
        "{{items}} {{ (items === 1) ? 'offene Behinderung' : 'offene Behinderungen' }}",
    _INFO_N: "{{items}} {{ (items === 1) ? 'Information' : 'Informationen'}}",

    _ACTIVITIES_FINISHED_N: "{{done}} von {{quantity}} sind fertiggestellt",
    _ACTIVITIES_FINISHED: "Aktivitäten fertiggestellt",
    _ACTIVITIES: "Aktivitäten",
    _ACTIVITIES_N:
        "{{activities | number}} Aktivität{{ (activities === 1) ? '' : 'en' }}",
    _CUSTOM_ACTIVITIES: "Nutzerdefinierte Aktivitäten",

    _CONTAINS: "Enthält:",
    _CREATED: "Erstellt:",
    _DRAFT: "Entwurf",

    _DATE: "Datum",
    _DATE_REPORTED: "Erfasser",
    _DATE_UPDATED: "Aktualisiert",
    _TODAY: "Heute",
    _END_OF_LAST_MONTH: "Ende letzten Monat",
    _CUSTOM_DATE: "Benutzerdefiniert",
    _UNTIL: "Bis",
    _ANYTIME: "zu jeder Zeit",
    _DAYS_BETWEEN_OVER_30_DAYS: "Älter als 30 Tage",
    _DAYS_BETWEEN_TO_30_DAYS: "Letzte 30 Tage",
    _DAYS_BETWEEN_TO_7_DAYS: "Letzte 7 Tage",
    _DAYS_BETWEEN_AFTER_30_DAYS: "Nächste 30 Tage",
    _DAYS_BETWEEN_AFTER_7_DAYS: "Nächste 7 Tage",
    _TEN_DAYS_AGO: "In den vergangenen 10 Tagen",
    _LAST_MONTH: "Letzten Monat",
    _LAST_WEEK: "Letzte Woche",
    _NEXT_WEEK: "Nächste Woche",
    _THIS_WEEK: "Diese Woche",
    _WEEK_OF_YEAR: "Kalenderwoche",

    _TO_DATE_NOT_SET: "offen",
    _FROM_DATE_NOT_SET: "offen",
    _UNSET: "nicht festgelegt",
    _TOPOLOGICAL_INDEX: "Aktivität-Position im Workflow",

    _CUSTOM: "Benutzerdefiniert...",
    _STATUS: "Projektelement Status",
    _UPDATING_STATUS: "Status wird aktualisiert",
    _STATUS_UPDATED: "Status aktualisiert",
    _FROM: "Von",
    _TO: "Bis",
    _ALL: "Alle",
    _NO_DESCRIPTION: "Keine Beschreibung",

    _INFO: "Info",
    _ALL_TYPES: "Alle",
    _IMAGES: "Bilder",

    _OPEN_ISSUES: "Ungelöste Probleme",

    _EVERYBODY: "Jeder",

    _ROLE_DEFAULT: "",
    _ROLE_ADMIN: "Projektadministrator",
    _ROLE_MANAGER: "Projektmanager",
    _ROLE_INSPECTOR: "Mobiler Erfasser",
    _ROLE_CONTROLLER: "Projektcontroller",
    _ROLE_REVIEWER: "Auswerter (nur lesend)",
    _ROLE_REPLACEMENT: "Ersatz",

    _ROLE_COMMERCIAL_MANAGE: "Kaufmännischer Leiter",
    _ROLE_COMMERCIAL_READ: "Kaufmännischer Mitarbeiter",

    _ROLE_INITIALS_ADMIN: "PA",
    _ROLE_INITIALS_MANAGER: "PM",
    _ROLE_INITIALS_INSPECTOR: "ME",
    _ROLE_INITIALS_CONTROLLER: "PC",
    _ROLE_INITIALS_REVIEWER: "LA",

    _COMPONENT: "Bauteil",
    _DELIVERY: "Lieferung",
    _DOCUMENT: "Dokument",
    _ROOM: "Raum",
    _TRADE: "Gewerk",
    _GROUP: "Gruppe",
    _OTHERS: "Anderes",
    _ACTIVITY: "Aktivität",
    _DELIVERABLE: "Projektelement",

    _PROGRESS_0: "nicht begonnen",
    _PROGRESS_100: "abgeschlossen",
    _PROGRESS_50: "Arbeit begonnen",
    _PROGRESS_CHANGES: "Fortschrittsänderungen",
    _PROGRESS_CANNOT_START: "Meldung anlegen",

    _NOT_STARTED: "Nicht begonnen",
    _STARTED: "Arbeit begonnen",
    _DONE: "Abgeschlossen",
    _WAITING_FOR_CONFIRMATION: "Wartet auf Prüfung",
    _CONFIRMED: "Geprüft",
    _REJECTED: "Abgelehnt",
    _APPROVED: "Vorabgenommen",
    _COMPLETED: "Finalisiert: Geprüft + Abgeschlossen nach Gewerk",
    _REVIEW_CHECKLIST: "Prüfe QS Protokoll",

    _PREDECESSOR: "Vorgänger",
    _SUCCESSOR: "Nachfolger",

    _PREDECESSOR_N: "{{items}} Vorgänger",
    _SUCCESSOR_N: "{{items}} Nachfolger",

    _COPYRIGHT: "© {{year}} Sablono GmbH",

    _NEXT: "Weiter",
    _REFRESH: "Aktualisieren",
    _UPDATE: "Aktualisieren",
    _IMPORT: "Importieren",

    _STRUCTURE: "Struktur",
    _PROJECT_STRUCTURE: "Projektstruktur",
    _NAME: "Name",
    _CODE: "Code",
    _DESC: "Beschreibung",

    ENTER_FULLSCREEN: "Baustellen-Dashboard starten",
    EXIT_FULLSCREEN: "Baustellen-Dashboard schließen",
    LAST_UPDATED: "Daten zuletzt aktualisiert",
    FULLSCREEN_REFRESH: "Daten aktualisieren",

    PLANNED_DATES_TOOLTIP:
        "Diese Daten wurden von Ihnen oder einem Ihrer Partner festgelegt.",
    CALCULATED_DATES_TOOLTIP:
        "Diese Daten wurden basierend auf Ihren angelegten Workflows automatisch durch Sablono berechnet.",
    FORECASTED_DELIVERY_TOOLTIP:
        'Sablono prognostiziert die Fertigstellung basierend auf dem aktuellen Fortschritt. "Gefährdet" wird angezeigt, wenn eine Ihrer Aktivitäten hinter dem Zeitplan läuft, die nicht auf dem kritischen Pfad liegt.',

    NAME: "Name",
    CODE: "Code",
    _PROGRESS: "Fortschritt",
    _INSPECTION: "Erfassung",
    START_DATE: "Startet",
    USER_DEFINED_START: "Benutzerdefinierter Start",
    USER_DEFINED_END: "Benutzerdefiniertes Ende",
    EARLIEST_START: "Frühester Start (Terminplan)",
    EARLIEST_END: "Frühestes Ende (Terminplan)",
    DUE_BETWEEN: "Fällig zwischen",
    END_DATE: "Endet",
    LATEST_START: "Spätester Start (Terminplan)",
    LATEST_END: "Spätestes Ende (Terminplan)",
    DUE_DATE: "Soll-Termin",
    DURATION: "Dauer",
    DURATION_UNIT: "Einheit der Dauer",
    LAST_PLANNER_START: "Start (Arbeitsplan)",
    LAST_PLANNER_END: "Ende (Arbeitsplan)",

    REVISION_NUMBER_TOOLTIP: "Vorlagen-Version",

    TOTAL_FLOAT: "Gesamtpuffer (Terminplan)",
    TOTAL_FLOAT_OF_X:
        "{{float >= 0 ? (float + ' Tag' + (float === 1 ? 'e' : '')) : '-'}}",
    TOTAL_FLOAT_TOOLTIP:
        "Dieser Puffer steht zur Verfügung, um das Projektelement zwischen frühestem Start und spätestem Ende abzuschließen.",
    TOTAL_FLOAT_TOOLTIP_IS_POSITIVE_DELIVERABLE:
        "Dieser Puffer steht zur Verfügung, um das Projektelement zwischen frühestem Start und spätestem Ende abzuschließen.",
    TOTAL_FLOAT_TOOLTIP_IS_POSITIVE_ACTIVITY:
        "Dieser Puffer steht zur Verfügung, um die Aktivität zwischen frühestem Start und spätestem Ende abzuschließen.",
    TOTAL_FLOAT_TOOLTIP_IS_ZERO_DELIVERABLE:
        "Dieses Projektelement ist Teil eines kritischen Pfads, weshalb kein Puffer zur Verfügung steht.",
    TOTAL_FLOAT_TOOLTIP_IS_ZERO_ACTIVITY:
        "Diese Aktivität ist Teil eines kritischen Pfads, weshalb kein Puffer zur Verfügung steht.",
    TOTAL_FLOAT_TOOLTIP_IS_NEGATIVE:
        "Basierend auf den Dauern Ihrer Aktivitäten sowie den definierten Abhängigkeiten, werden die benutzerdefinierten Enddaten nicht gehalten werden können.",
    TOTAL_FLOAT_TOOLTIP_IS_NULL:
        "Der Gesamtpuffer wurde nicht berechnet, da entweder das Start- oder End-Datum dieses Pfads nicht definiert wurde.",

    PRIORITY: "Priorität",
    TIME_OF_DAY: "Uhrzeit",
    PERCENTAGE_FINISHED: "fertiggestellt",
    _FINISHED: "Fertiggestellt",
    _REMOVE: "entfernen",
    _PROCESS: "Workflow",
    _PROCESS_TEMPLATE: "Workflow-Vorlage",
    _UNSTRUCTURED: "Unstrukturiert",
    _NO_TEMPLATE_ASSIGNED: "Kein Workflow zugewiesen",
    _NOT_SET: "Ohne",
    _STAGE: "Aktivität",
    _SELECT_STATUS: "...und deren Status.",
    _ANY: "Alle",
    WORK_SCHEDULED: "Arbeit geplant",
    _LEGEND: "Legende",

    _STATE: "Status",
    _ACTIVITY_NUMBER: "Anzahl Aktivitäten",
    _DELIVERABLE_NUMBER: "Anzahl Projektelemente",

    _LAST_INSPECTION: "Letzte Aktualisierung",

    _SELECT_ALL: "Alles auswählen",
    _DESELECT_ALL: "Auswahl aufheben",
    _SHOW_MORE: "Mehr",
    _DOWNLOAD: "Herunterladen",
    _DOWNLOAD_FILE: "Datei herunterladen",
    _DOWNLOAD_MACRO: "Macro herunterladen",
    _CANCEL: "Abbrechen",
    _CONTINUE: "Weiter",
    _GO_TO: "Zum ",
    _CLOSE: "Schließen",
    _FINISH: "Fertig",
    _YES: "Ja",
    _NO: "Nein",
    _SAVED: "Gespeichert",

    _TEAM_COLOR: "Teamfarbe",
    _COLOR: "Farbe",
    _STREET: "Straße",
    _POST_CODE: "PLZ",
    _CITY: "Stadt",
    _COUNTRY: "Land",
    _CONTACT_INFO: "Verantwortlich:",
    _COMPANY: "Anschrift:",

    _EDIT: "Bearbeiten",

    LATE_BY_DAYS: "{{ days }} verspätet",

    _NOTE_RESPONSIBLE_TEAM: "Verantwortliches Team",
    _NOTE_RESPONSIBLE_TEAM_SHORT: "Verantwortlich",

    _NOTE_CONFIRMATION_TEAM: "Abnehmendes Team",
    _NOTE_CONFIRMATION_TEAM_SHORT: "Abnehmendes",

    _PROJECT_TEAM: "Vollzugriffs-Team",
    PROJECT_TEAM_INVITED_MEMBERS: "Ausstehende Einladungen",
    _NO_DEFAULT_CONFIRMATION_TEAM: "Kein vorausgewähltes Abnehmendes Team",
    _REPORTERS_TEAM:
        "Team, zu welchem der Nutzer gehört, der die Meldung anlegt",
    _UNRESTRICTED_TEAM: "Nicht zugeordnet",
    _NO_CONFIRMATION_TEAM_AVAILABLE: "Nicht gefordert",

    _AREA_MANAGER: "Bauleiter",

    _CREATION_DATE: "Erstellungsdatum",

    _PROJECT_START_DATE: "Startdatum",
    _PROJECT_DUE_DATE: "Enddatum",

    _FILTER_TOOLTIP: "Filtern",
    _SEND_MAIL: "Email senden",

    _AUTOCOMPLETE_SEARCH_BY_EMAIL_OR_USERNAME:
        "Nutzer via ihrer E-Mail einladen...",
    _AUTOCOMPLETE_SEARCH_USER_NOT_FOUND:
        "Bitte nutzen Sie die exakte E-Mail der Person, die Sie zu Ihrem Projekt hinzufügen möchten. Nur aktivierte Nutzer können hier gefunden werden.",

    SECTION_TEAMS_SUPPORT_CENTER_TITLE: "Team Management",
    SECTION_TEAMS_ADD_NEW_TITLE: "Neues Team erstellen",
    SECTION_TEAMS_ADD_NEW_TEXT:
        "Ein Team kann bspw. eine Firma oder ein Gewerk repräsentieren. Aktivitäten, die in Sablono angelegt werden, können bestimmten Teams zugeordnet werden, um Verantwortlichkeiten sowie Sichtbarkeit zu regeln.",
    SECTION_TEAMS_EDIT_TITLE: "Team bearbeiten",
    SECTION_TEAMS_EDIT_TEXT:
        "Ein Team kann bspw. eine Firma oder ein Gewerk repräsentieren. Aktivitäten, die in Sablono angelegt werden, können bestimmten Teams zugeordnet werden, um Verantwortlichkeiten sowie Sichtbarkeit zu regeln.",
    SECTION_TEAMS_EDIT_CONTACT_TITLE: "Kontaktdaten",
    SECTION_TEAMS_EDIT_CONTACT_TEXT:
        "Bitte geben Sie Kontaktdaten der Person an, die für dieses Team verantwortlich ist.",
    SECTION_TEAMS_EDIT_LOCATION_TEXT:
        "Bitte geben Sie Namen und Anschrift der Firma an, die für dieses Team verantwortlich ist.",
    SECTION_TEAMS_EDIT_DETAILS_TITLE: "Team Informationen",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TITLE:
        "Aministrationsrolle verlassen?",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TITLE:
        "Projektmanagerrolle verlassen?",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_ADMIN_TEXT:
        "Möchten Sie sich selbst aus der Aministrationsrolle verschieben? Dies kann nicht rückgängig gemacht werden.",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_MANAGER_TEXT:
        "Möchten Sie sich selbst aus der Projektmanagerrolle verschieben? Dies kann nicht rückgängig gemacht werden, da Sie alle Rechte verlieren, um Nutzer zu verwalten.",
    SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TITLE: "Wirklich entfernen?",
    SECTION_TEAMS_DELETE_YOURSELF_FROM_TEAM_TEXT:
        "Sind Sie sicher, dass Sie sich selbst aus diesem Projekt entfernen möchten? Anschließend haben Sie keinen Zugriff mehr darauf.",
    SECTION_TEAMS_PROJECT_TEAM_INFO_TOOLTIP:
        "Das Vollzugriffs-Team ist als einziges Team in der Lage alle Aktivitäten im Projekt zu bearbeiten, auch wenn diese einem anderen Team zugeordnet wurden. Es eignet sich daher bspw. für die Projektüberwachung.",
    ACTION_DELETE_TEAM: "Team löschen",
    ACTION_DELETE_USER_FROM_TEAM: "Nutzer entfernen",
    ACTION_CONFIRM_DELETE_USER_FROM_TEAM: "Entfernen bestätigen",
    ACTION_UPLOAD_TEAM_LOGO: "Logo hochladen",

    INSPECTED_BY: "Letzte Erfassung {{timestamp}} von {{inspector}}",
    REPORTED_BY: "Erfasst am {{timestamp}} von {{inspector}}",
    UPDATED_BY: "Letzte Aktualisierung am {{timestamp}} von {{inspector}}",
    LAST_MODIFIED_BY: "Zuletzt geändert am {{timestamp}} von {{user}}",
    REMOVED_BY: "Gelöscht durch {{timestamp}} von {{user}}",
    EDITED: "Bearbeitet",
    REMOVED: "Gelöscht",

    _FILTER_NAME_TEXT_PLACEHOLDER: "Geben Sie eine Projektelement Namen ein",
    _FILTER_CODE_TEXT_PLACEHOLDER: "Geben Sie eine Projektelement Code ein",
    _FILTER_TYPE_TEXT_PLACEHOLDER: "Geben Sie eine Projektelement-Typ ein",
    _FILTER_DESC_TEXT_PLACEHOLDER:
        "Geben Sie eine Projektelement Beschreibung ein",
    _FILTER_STAGE_TEXT_PLACEHOLDER:
        "Wählen Sie eine Aktivität oder eine Gruppe...",
    _FILTER_LABEL_STAGE: "Leistung des Projektelements",
    _FILTER_LABEL_PROCESS: "Workflow",
    _FILTER_WORKFLOW_TEXT_PLACEHOLDER: "Wählen Sie einen Workflow aus...",
    _FILTER_STRUCTURE_TEXT_PLACEHOLDER:
        "Wählen Sie einen Teil der Projektstruktur aus...",
    _FILTER_SEARCH_DELIVERABLE_PLACEHOLDER:
        "Suchen Sie nach Projektelementen anhand von Name, Code oder Beschreibung...",
    _FILTER_SEARCH_TEAM_MEMBERS_PLACEHOLDER: "Nach Team-Mitglied suchen...",
    _FILTER_SEARCH_NOTES_PLACEHOLDER:
        "Suchen Sie nach Meldungen anhand des Textes...",
    _SEARCH_BY_STAGE_PLACEHOLDER: "Suchen Sie nach einer Aktivität",

    _DELIVERABLE_SEARCH: "Suche",

    INFO_NO_MATCHES_FOUND: "Keine Übereinstimmung gefunden",
    INFO_CONTACT_CUSTOMER_SUPPORT:
        "Benötigen Sie Hilfe oder weitere Informationen?",
    ACTION_WATCH_VIDEO: "Video ansehen",

    _ACTION_SHOW_ADVANCED_FILTERS: "Erweiterte Optionen",
    _ACTION_SHOW_REDUCED_FILTERS: "Grundlegende Optionen",

    /**
     * LOCALIZED LINKS
     */
    LINK_VERSION_PAGE:
        "https://support.sablono.com/de-form/articles/4783534-sablono-web-plattform-updates",
    LINK_VISUALIZATION_VIDEO: "https://www.youtube.com/watch?v=v49RnAFW88Y",
    LINK_DOWNLOAD_MACRO: "/downloads/modAstaSablonoImporter.bas",
    LINK_SUPPORT_CENTER: "https://support.sablono.com",
    LINK_IDEAS_PORTAL: "https://sablono.ideas.aha.io/?sort=recent",
    LINK_IMPRESSUM: "https://www.sablono.com/impressum/",

    /**
     * ACTIONS
     */

    ACTION_LOGIN: "Anmelden",
    ACTION_REGISTER_USER: "Registrieren",
    ACTION_FINALISE_REGISTER_USER: "Abschließen",
    ACTION_CHANGE_USER: "Benutzer wechseln",
    ACTION_CHANGE_PASSWORD: "Passwort ändern",
    ACTION_ARIA_LABEL_CHANGE_LANGUAGE: "Sprache ändern",

    ACTION_SETUP_PASSWORD: "Neues Passwort festlegen",
    LOGIN__PASSWORD_RESET_REQUIRED_MESSAGE:
        "Sie müssen ein neues Passwort für Ihren Account festlegen. Bitte nutzen Sie den unteren Button.",

    ACTION_CREATE_CLONE_TOOLTIP: "Projekt kopieren",
    ACTION_CREATE_EMPTY_TOOLTIP: "Erstellen Sie Ihr eigenes Projekt",
    ACTION_PROJECT_FROM_IMPORT_TOOLTIP: "Projekt importieren",

    ACTION_CALL_THE_SUPPORT: "Support Chat öffnen",
    ACTION_SHOW_ERROR_DETAILS: "Details anzeigen",

    ACTION_OPEN_IN_TEMPLATE_EDITOR: "Vorlageneditor öffnen",
    ACTION_ASSIGN_ACTIVITIES: "Aktivitäten zuweisen",
    ACTION_SHOW_WHOLE_PROCESS: "Gesamten Workflow anzeigen",
    ACTION_SHOW_ALL_ACTIVITIES: "Alle Aktivitäten anzeigen",

    // NAV
    ACTION_NAV_TO_IMPRINT: "Impressum",
    ACTION_NAV_TO_AGB: "AGB",
    ACTION_NAV_TO_FAQ: "Online Support",
    ACTION_NAV_TO_VERSION: "Updates & Versionen",
    ACTION_NAV_TO_IDEAS: "Ideenportal",
    ACTION_NAV_TO_CONTACT: "Kontakt",
    ACTION_NAV_TO_APP: "Inspect Web-App",
    ACTION_NAV_TO_LEARNING_CENTER: "Ab zum Learning Center",
    ACTION_NAV_TO_LOGIN: "Zum Login",
    ACTION_RESEND_VERIFICATION: "Neuen Verifizierungscode per E-Mail senden",
    ACTION_NAV_TO_PROJECTS: "Zum Projekte",
    ACTION_NAV_TO_CONFIRM_RESET_PASSWORD: "Passwort zurücksetzen",
    ACTION_NAV_TO_SITE_DIARY: "Bautagebuch",
    ACTION_NAV_TO_DASHBOARD: "Leistungsübersicht",
    ACTION_NAV_TO_LEANBOARD: "Lean Board",
    ACTION_NAV_TO_PROJECT_SELECTION: "Meine Projekte anzeigen",
    ACTION_NAV_TO_ALL_PROJECTS: "Meine Projekte",
    ACTION_NAV_TO_PROJECT_DETAILS: "Projekteinstellungen",
    ACTION_NAV_TO_WBS: "Projektstruktur",
    ACTION_NAV_TO_TEAMS: "Teams",
    ACTION_NAV_TO_PROJECT_DELIVERABLES: "Projektelemente",
    ACTION_NAV_TO_TEMPLATE_ELEMENT: "Workflow-Vorlagen",
    ACTION_NAV_TO_TEMPLATE_CHECKLIST: "QS Protokolle",
    ACTION_NAV_TO_TEMPLATE_NOTES: "Meldungsvorlagen",
    ACTION_NAV_TO_TEMPLATE_NOTES_IMPORT: "Import: Meldungsvorlagen",
    ACTION_NAV_TO_IMPORT_SELECTION: "Projektelemente importieren",
    ACTION_NAV_TO_INSPECTIONS: "Erfassungen",
    ACTION_NAV_TO_ANALYSIS: "Projektelemente",
    ACTION_NAV_TO_PROCESS_VIEWER: "Aktivitäten",
    ACTION_NAV_TO_TRACKER: "Status Tracker",
    ACTION_NAV_TO_NOTES: "Meldungen",
    ACTION_NAV_TO_HELP_FEEDBACK: "Hilfe & Feedback",
    ACTION_NAV_TO_USER_PROFILE: "Mein Profil",
    ACTION_NAV_TO_VISUALIZATION: "Fortschrittsvisualisierung",
    ACTION_NAV_TO_ASSIGN_WORKFLOW: "Workflowvorlage zuordnen",

    ACTION_NAV_TO_TEAM_EDIT_TOOLTIP: "Nutzer hinzufügen",

    ACTION_NAV_TO_IMPORT_CLONE: "Projektkopie",
    ACTION_NAV_TO_IMPORT_EXCEL: "Projektelemente - Microsoft© Excel",
    ACTION_NAV_TO_IMPORT_MSPROJECT: "Microsoft© Project",
    ACTION_NAV_TO_IMPORT_ASTA: "Asta Power Project",
    ACTION_NAV_TO_IMPORT_PRIMAVERA: "Oracle Primavera",
    ACTION_NAV_TO_IMPORT_XML: "XML Datei",
    ACTION_NAV_TO_IMPORT_EMPTY: "neues Projekt",
    ACTION_NAV_TO_IMPORT_ANOTHER_SOURCE: "Weitere Projektelemente importieren",
    ACTION_NAV_TO_TOOLS: "Import/Export Werkzeuge",
    ACTION_NAV_TO_EXPORT: "Projektfortschritt exportieren",

    ACTION_NAV_TO_EXPORT_PRIMAVERA: "Exportiere P6 Fortschritt",
    ACTION_NAV_TO_REQUEST_ACCESS: "Zugang anfordern",

    ACTION_IMPORT_INTEGRATE:
        "Verbinden Sie Sablono mit Ihren gewohnten Programmen | Sablono Support Center",
    ACTION_IMPORT_INTEGRATE_URL:
        "https://support.sablono.com/de-form/articles/2967622-schritt-4-verbinden-sie-sablono-mit-ihren-gewohnten-programmen",

    ACTION_ASK_IMPORT_SOURCE: "Haben Sie Ihr Programm nicht gefunden?",
    ACTION_ASK_IMPORT_SOURCE_SUBTITLE: "Kontaktieren Sie uns gerne!",

    ACTION_ISSUE_EDITOR: "Neue Meldung anlegen",

    /**
     * SECTIONS
     */
    SECTION_PROJECT_SELECTION_TITLE: "Meine Projekte",
    SECTION_PLATFORM_INFO_TITLE: "Die Plattform",

    SECTION_DASHBOARD_TITLE: "Leistungsübersicht",
    SECTION_PROJECT_DETAIL_TITLE: "Projekteinstellungen",
    SECTION_PROJECT_DELIVERABLES_TITLE: "Projektelemente",
    SECTION_TEMPLATE_ELEMENTS_TITLE: "Workflow-Vorlagen",
    //"SECTION_TEMPLATE_NOTES_TITLE": "Meldungs-Vorlagen",
    SECTION_TEMPLATE_NOTES_IMPORT_TITLE: "Import: Meldungsvorlagen",
    SECTION_INSPECTIONS_TITLE: "Erfassungen",
    SECTION_ANALYSIS_TITLE: "Projektelemente",
    SECTION_NOTES_TITLE: "Meldungen",
    SECTION_IMPORT_UPLOAD_TITLE: "Import wird abgeschlossen",
    SECTION_EXCEL_IMPORT_TITLE: "Microsoft© Excel Import",
    SECTION_XML_IMPORT_TITLE: "XML Import",
    SECTION_NOTE_TEMPLATES_TITLE: "Meldungsvorlagen",
    SECTION_TEMPLATES_TITLE: "Workflow-Vorlagen",
    SECTION_WBS_TITLE: "Projektstruktur",
    SECTION_IMPORT_SELECTION_TITLE: "Import: Projektdaten",
    SECTION_EXPORT_TITLE: "Fortschritt exportieren",

    SECTION_EXCEL_IMPORT_DATES_TITLE: "Microsoft© Excel Import",
    TUTORIAL_IMPORT_DATES:
        "https://support.sablono.com/de-form/articles/5879573-how-to-aktivitats-daten-und-dauern-aus-microsoft-excel-importieren",
    SECTION_EXCEL_IMPORT_DATES_SUCCESS_TITLE:
        "Daten & Dauern erfolgreich importiert",
    SECTION_EXCEL_IMPORT_DATES_SUCCESS_TEXT:
        "{{numberOfUpdates}} Aktivitäten haben neuen Daten und/oder Dauern erhalten.",
    SECTION_EXCEL_IMPORT_DATES_TEXT:
        "Bitte stellen Sie vor dem Import sicher, dass Ihre Excel das folgende Format hat: <br> Projektelement Code - Aktivitäts-Name - Start - Ende - Datumsformat - Dauer - Einheit - Projekt-ID <br> Bitte beachten Sie, dass der Import von Start und Ende für die gleiche Aktivität aktuell nicht unterstützt wird. ",

    SECTION_TOOLS_TITLE: "Import/Export Werkzeuge",
    SECTION_TOOLS_IMPORT: "Projektdaten importieren",
    SECTION_TOOLS_EXPORT: "Projektfortschritt exportieren",

    SECTION_TOOLS_TILE_IMPORT:
        "<h3>Import</h3> von <b>neuen</b> Projektelementen, Fortschritt oder Aktivitätsdaten",
    SECTION_TOOLS_TILE_UPDATE:
        "<h3>Aktualisierender Import</h3> zur Überarbeitung <b>existierender</b> Projektelemente",
    SECTION_TOOLS_TILE_EXPORT:
        "<h3>Export</h3> des Projektfortschritts, um ihn offline weiter zu verarbeiten",
    SECTION_TOOLS_IMPORT_ACTIVITIES:
        "Attribute für existierenden Tracker - Microsoft© Excel",
    SECTION_TOOLS_IMPORT_PACKAGE: "Neuen Status Tracker - Microsoft© Excel",

    SECTION_TOOLS_GO_DASHBOARD: "Zum Projektüberblick",

    SECTION_REQUEST_FOR_INFORMATION_TITLE: "Fortschritt dokumentieren",
    SECTION_REQUEST_FOR_INFORMATION_OPT_OUT_TITLE: "Fortschritt dokumentieren",

    SECTION_TOOLS_DIALOG_CREATED: "Importiert am {{at}} von {{by}}",
    SECTION_TOOLS_DIALOG_UPDATED: "Zuletzt aktualisiert am {{at}} von {{by}}",

    SECTION_TOOLS_DIALOG_MERGE_TITLE: "Aktualisieren Sie Ihr Sablono Projekt",
    SECTION_TOOLS_DIALOG_MERGE_TEXT:
        "Bitte wählen Sie die Quelldatei, anhand derer Sie das Projekt aktualisieren möchten.",
    SECTION_TOOLS_DIALOG_EXPORT_TITLE:
        "Exportieren Sie Ihren Projektfortschritt",
    SECTION_TOOLS_DIALOG_EXPORT_TEXT:
        "Bitte wählen Sie den Teil Ihres Projekt aus, für den Sie den Fortschritt exportieren möchten.",
    SECTION_TOOLS_DIALOG_EXPORT_PROJECT_TILE:
        "@:INFO_DELIVERABLES_AND_ACTIVITIES_REPORT_TITLE",
    SECTION_TOOLS_DIALOG_EXPORT_PROJECT_TEXT:
        "Exportieren Sie den Fortschritt des gesamten Projekts in eine CSV Datei.",
    /**
     *
     * EXPORT PAGE TRANSLATIONS
     */
    SECTION_EXPORT_SELECT_FILE_SUBTITLE:
        "Der Fortschritt Ihres Projektes wird in die Datei geschrieben, die Sie hochladen. <br />Anschließend können Sie die Datei herunterladen und erneut in das von Ihnen genutzte Programm importieren.",
    SECTION_EXPORT_PROCESSING_TITLE: "Wir aktualisieren Ihre Datei...",
    SECTION_EXPORT_PROJECT_PROGRESS_TITLE:
        "Wir exportieren Ihren Projektfortschritt...",
    SECTION_EXPORT_SUCCESS_TITLE: "Ihre Datei kann nun heruntergeladen werden.",
    SECTION_EXPORT_SUCCESS_TITLE_PRIMAVERA:
        "Ihre aktualisierte Datei kann nun heruntergeladen werden.",
    SECTION_EXPORT_SUCCESS_SUBTITLE:
        "Der Status von {{changed}} {{(changed==1)?'Vorgang':'Vorgängen'}} wurde aktualisiert.",
    SECTION_EXPORT_CSV_SUCCESS_SUBTITLE:
        "{{platformTotal}} {{(platformTotal==1)?'Zeile wurde':'Zeilen wurden'}} exportiert.",
    SECTION_EXPORT_ASTA_RESOURCES:
        "Um Ihren Projektfortschritt in Asta Power Project zu importieren, nutzen Sie bitte das unten zur Verfügung gestellte Macro.",
    SECTION_EXPORT_ASTA_RESOURCES_DETAIL:
        "* Wenn Sie das Macro bereits verwendet haben, müssen Sie es nicht erneut herunterladen.",
    SECTION_EXPORT_WRONG_FILE_TITLE:
        "Ihre Datei scheint nicht zu Ihrem Sablono Projekt zu gehören...",
    SECTION_EXPORT_WRONG_FILE_SUBTITLE:
        "Die Vorgänge Ihrer Datei passen nicht zu den auf Sablono gespeicherten. Um fortzufahren, wählen Sie bitte eine andere Datei aus.",
    SECTION_EXPORT_UP_TO_DATE_FILE_TITLE:
        "Ihre Datei ist bereits auf dem neuesten Stand!",
    SECTION_EXPORT_UP_TO_DATE_FILE_SUBTITLE:
        "Der Status Ihres Projekts hat sich nicht verändert, seit Sie Ihre Datei zuletzt aktualisiert haben.",

    SECTION_EXPORT_PRIMAVERA_SELECT_FILE_TITLE:
        "Bitte laden Sie Ihre Primavera-Datei hoch",
    SECTION_EXPORT_PRIMAVERA_SELECT_FILE_SUBTITLE:
        "Um den Fortschritt Ihres Projektes zu exportieren, benötigen wir Ihr Primavera-Projekt im Primavera-XML Dateiformat.<br />Nachdem Sie die Datei hochgeladen haben, wird der mit Sablono dokumentierte Fortschritt zu dieser hinzugefügt.<br />Anschließend laden Sie die Datei einfach wieder herunter und importieren Sie erneut in Primavera.",

    DIALOG_EXPORT_PROGRESS_TITLE: "Huch...",
    DIALOG_EXPORT_PROGRESS_CONTENT:
        "Leider konnten wir nicht feststellen, ob Sie mit einem Primavera System arbeiten, das 'Komma' oder 'Punkt' als Trennzeichen nutzt. Damit Sie die von uns zur Verfügung gestellte Datei importieren können, teilen Sie uns bitte mit, wie Ihr System konfiguriert ist.",
    DIALOG_EXPORT_PROGRESS_ACTION_COMMA: "Komma",
    DIALOG_EXPORT_PROGRESS_ACTION_FULL_STOP: "Punkt",

    /**
     *
     * MERGE/UPDATE TRANSLATIONS
     */
    SECTION_MERGE_TITLE: "Projekt aktualisieren",
    SECTION_MERGE_TOOLBAR_TITLE: "Aktualisieren Sie Ihr Sablono Projekt",

    SECTION_MERGE_STEP_1_STEPPER: "Wählen Sie eine Datei",
    SECTION_MERGE_STEP_2_STEPPER: "Optionen auswählen",
    SECTION_MERGE_STEP_3_STEPPER: "Projekt wird aktualisiert",
    SECTION_MERGE_STEP_5_STEPPER: "Aktualisierung abgeschlossen",

    SECTION_MERGE_STEP_1_TITLE:
        "Um Ihr Sablono Projekt zu aktualisieren, laden Sie bitte die Datei hoch, die die neueste Version Ihres Terminplanes enthält.",
    SECTION_MERGE_STEP_1_SUBTITLE:
        "Bitte wählen Sie eine Datei, die zu <strong>{{title}}</strong> (<em>{{fileName}}</em>) passt.",

    SECTION_MERGE_STEP_2_TITLE: "Projektelemente löschen",
    SECTION_MERGE_STEP_2_SUBTITLE:
        "Möchten Sie Projektelemente löschen, die zuvor über diese Datei importiert wurden, nun aber nicht mehr Teil dieser sind? Bitte beachten Sie, dass dies auch alle zugehörigen Aktiviäten und Meldungen löscht.",
    SECTION_MERGE_STEP_2_KEEP: "Nein, Elemente behalten",
    SECTION_MERGE_STEP_2_DELETE: "Ja, Elemente löschen",

    SECTION_MERGE_STEP_2A_TITLE:
        "Reaktivieren von zuvor gelöschten Projektelementen",
    SECTION_MERGE_STEP_2A_SUBTITLE:
        "Möchten Sie Projektelemente dieser Datei wiederherstellen, die nach einem früheren Import auf der Sablono Plattform gelöscht wurden?",
    SECTION_MERGE_STEP_2A_KEEP: "Nein, Elemente gelöscht lassen",
    SECTION_MERGE_STEP_2A_DELETE: "Ja, Elemente wiederherstellen",
    SECTION_MERGE_STEP_3_TITLE: "Wir aktualisieren Ihr Sablono Projekt...",

    SECTION_MERGE_STEP_4_TITLE: "Wir verarbeiten Ihre Eingaben...",

    SECTION_MERGE_ERROR_TITLE: "Ups, etwas ist schief gelaufen....",
    SECTION_MERGE_ERROR_SUBTITLE:
        "Um sicher zu gehen, dass Ihr Sablono Projekt erfolgreich aktualisiert wird, versuchen Sie es bitte noch einmal.<br/>Sollten Sie weiterhin Probleme haben, kontaktieren Sie uns gerne in unserem Chat Support.",

    SECTION_MERGE_SUCCESSFULL_PROJECT_UPLOAD_HEADER:
        "Projekt erfolgreich aktualisiert",
    SECTION_MERGE_SUCCESSFULL_PROJECT_UPLOAD_TEXT:
        "Ihr Sablono Projekt, einschließlich Ihrer Projektelemente und der Projektstruktur, wurde an die neueste Version Ihres Terminplanes angepasst.",
    SECTION_MERGE_SUCCESSFULL_XLS_PROJECT_UPLOAD_TEXT:
        "Ihr Sablono Projekt, einschließlich Ihrer Projektelemente und der Projektstruktur, wurde an die neueste Version Ihrer Excel Tabelle angepasst.",

    SECTION_MERGE_INFO_EXCEL_MAPPING:
        "Verwenden Sie die Spalte <b>{{mappingKey}}</b> Ihrer Datei als Code, um die Aktualisierung erfolgreich durchzuführen.",
    SECTION_MERGE_INFO_EXCEL_MAPPING_HINT:
        "Der Code der Projektelemente wird verwendet, um gleiche Elemente zu identifizieren und zu aktualisieren.",
    /**
     * INPUT
     */

    INPUT_USERNAME_TITLE: "E-Mail",
    INPUT_PASSWORD_TITLE: "Passwort",
    INPUT_VERIFICATION_CODE_TITLE: "Verifizierungscode",
    INPUT_NEW_PASSWORD_TITLE: "Neues Passwort",
    INPUT_PASSWORD_REPEAT_TITLE: "Passwort wiederholen",

    INPUT_FULL_NAME_TITLE: "Name",
    INPUT_FIRST_NAME_TITLE: "Vorname",
    INPUT_LAST_NAME_TITLE: "Nachname",
    INPUT_EMAIL_TITLE: "Arbeits-E-Mail",
    INPUT_COMPANY_NAME_TITLE: "Firmenname",
    INPUT_REGISTER_QUESTION_TITLE: "Wie sind sie auf uns aufmerksam geworden?",
    INPUT_REGISTER_QUESTION_OPTION_OTHER: "Anderes",
    INPUT_REGISTER_QUESTION_OTHER_TITLE: "Wie genau?",
    INPUT_REGISTER_QUESTION_OPTION_WEBSITE: "Sablono Homepage",
    INPUT_REGISTER_QUESTION_OPTION_TELESALE: "Telefonkampagne",
    INPUT_REGISTER_QUESTION_OPTION_RECOMMENDATION: "Persönliche Empfehlung",
    INPUT_REGISTER_QUESTION_OPTION_GOOGLE: "Internet-Suche",
    INPUT_REGISTER_QUESTION_OPTION_ADVERTISMENT: "Werbung",
    INPUT_REGISTER_PHONE_TITLE: "Arbeits-Telefonnummer",

    INPUT_REGISTER_QUESTIONNAIRE_LABEL: "Schreiben Sie Ihre Antwort hier",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1:
        "Wie haben Sie von Sablono erfahren?",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2:
        "Wofür möchten Sie Sablono verwenden?",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3:
        "Warum wollen Sie Sablono verwenden?",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_1:
        "Persönliche Einladung oder Empfehlung",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_2: "Soziale Medien",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_3: "Blog-Artikel",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_4: "Suchmaschine",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_5: "Veranstaltung",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_1_OPTION_6: "Sonstiges",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_1:
        "Verfolgung des Fortschritts",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_2:
        "Qualitätsdokumentation",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_3: "Reporting",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_4:
        "Abrechnungsmanagement",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_5: "Planung",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_2_OPTION_6:
        "Tägliche/wöchentliche Fortschrittsbesprechungen",

    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_1:
        "Ersatz vorhandener Excel-Tracker",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_2:
        "Vereinfachtes Gewerke-Management",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_3:
        "Qualitätsdokumentation an einem zentralen Ort",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_4:
        "Vereinfachtes Reporting von der Baustelle aus",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_5:
        "Tägliche Fortschrittsbesprechungen",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_6:
        "Leichteres Verständnis des Projektstatus",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_7:
        "Lean Planung und Ausführung",
    INPUT_REGISTER_QUESTIONNAIRE_GENERAL_QUESTION_3_OPTION_8:
        "Abrechnungsmanagement",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1:
        "Wie haben Sie von Sablono Track erfahren?",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2:
        "Was werden Sie auf Sablono tracken?",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3:
        "Warum möchten Sie Sablono zum tracken verwenden?",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_1:
        "Persönliche Einladung oder Empfehlung",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_2: "Soziale Medien",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_3: "Blog-Artikel",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_4: "Suchmaschine",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_5: "Veranstaltung",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_1_OPTION_6: "Sonstiges",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_1: "Rammarbeiten",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_2:
        "Innenausstattung",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_3: "Fassaden",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_4:
        "Vorgefertigtes Paket",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_5:
        "Strukturelle Arbeiten",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_6:
        "Komplettes Projekt",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_7:
        "Brandabschottung",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_2_OPTION_8: "Andere",

    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_1:
        "Ersatz vorhandener Excel-Tracker",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_2:
        "Vereinfachtes Gewerke-Management",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_3:
        "Vereinfachtes Reporting von der Baustelle aus",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_4:
        "Tägliche Fortschrittsbesprechungen",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_5:
        "Leichteres Verständnis des Projektstatus",
    INPUT_REGISTER_QUESTIONNAIRE_FREEMIUM_QUESTION_3_OPTION_6:
        "Abrechnungsmanagement",

    INPUT_PROJECT_NAME_TITLE: "Projektname",
    INPUT_PROJECT_CODE_TITLE: "Projekt-Code",
    INPUT_PROJECT_DESCRIPTION_TITLE: "Projektbeschreibung",
    INPUT_PROJECT_START_DATE_TITLE: "Projektstart",
    INPUT_PROJECT_LANGUAGE_TITLE: "Projektsprache",
    INPUT_PROJECT_TIMEZONE_TITLE: "Projektzeitzone",
    INPUT_PROJECT_START_TITLE: "Projekt-Start",
    INPUT_PROJECT_COMPLETION_TITLE: "Projekt-Ende",
    INPUT_PROJECT_TYPE: "Projekttyp",
    INPUT_PROJECT_TYPE_LABEL: "Schreiben Sie Ihre Antwort hier",
    INPUT_PROJECT_TYPE_OPTION_1: "Wohnungsbau",
    INPUT_PROJECT_TYPE_OPTION_2: "Gewerbeflächen",
    INPUT_PROJECT_TYPE_OPTION_3: "Fertigung & Produktion",
    INPUT_PROJECT_TYPE_OPTION_4: "Erneuerbare Energie",
    INPUT_PROJECT_TYPE_OPTION_5: "Kulturerbe, Denkmalschutz, Renovierung",
    INPUT_PROJECT_TYPE_OPTION_6:
        "Öffentliche Einrichtungen (Gesundheit, Bildung, Verteidigung)",
    INPUT_PROJECT_TYPE_OPTION_7:
        "Industriebau (Vertrieb, Logistik, Rechenzentren)",
    INPUT_PROJECT_TYPE_OPTION_8: "Infrastruktur, Schienenverkehr, Straßenbau",
    INPUT_PROJECT_TYPE_OPTION_9: "Privater Wohnungsbau",
    INPUT_PROJECT_TYPE_OPTION_10: "Sonstiges",
    INPUT_PROJECT_IMAGE_SIZE_SUGGESTION: "Empfohlen: 800x600px",
    INPUT_PROJECT_CURRENCY_TITLE: "Projektwährung",

    ACTION_CREATE_PROJECT: "Projekt erstellen",
    ACTION_LOGOUT: "Abmelden",

    SECTION_SIDENAV_SETUP_TITLE: "Konfiguration",
    SECTION_SIDENAV_DASHBOARDS_AND_TRACKERS_TITLE: "Dashboards & Trackers",
    SECTION_SIDENAV_LISTS_TITLE: "Listen",
    SECTION_SIDENAV_SCHEDULES_AND_TIMELINES_TITLE: "Terminpläne & Zeitleisten",
    SECTION_SIDENAV_RESOURCES_TITLE: "Informationen",
    SECTION_SIDENAV_NO_PROJECT: "Kein Projekt ausgewählt.",

    SECTION_CREATE_CLONE: "Kopiere existierendes Projekt",
    SECTION_CREATE_EMPTY: "Neues Projekt erstellen",
    SECTION_CREATE_FROM_IMPORT: "Projekt aus anderen Programmen importieren",

    SECTION_LOGIN_TITLE: "Anmelden",
    SECTION_REGISTRATION_TITLE: "Konto erstellen",
    SECTION_REGISTRATION_LANGUAGE: "Sprache",
    SECTION_CREATE_PROJECT_TITLE: "Projekt erstellen",

    INFO_NO_INSPECTIONS: "noch keine Erfassung vorhanden",
    INFO_RECENT_INSPECTION: "letzte Erfassung ",
    INFO_TEAM_MEMBER: "Team-Mitglied",
    INFO_TEAM_MEMBERS: "Workflow & QS Nutzer",
    INFO_MORE_TEAM_MEMBER: "und 1 anderer",
    INFO_MORE_TEAM_MEMBERS: "und {{numTeam}} andere",
    INFO_PROJECT_PROGRESS: "fertig gestellt",
    INFO_PROJECT_EXPIRY: "Demoprojekte verfallen nach 14 Tagen Inaktivität.",
    INFO_COMMERCIAL_USERS: "Kaufmännische Nutzer",

    //Simple Labels
    BACK: "Zurück",
    CLOSE: "Schließen",
    YES: "Ja",
    NO: "Nein",
    NOT_APPLICABLE: "n.z.",
    PROJECT_LANGUAGE: "Projektsprache",
    PROJECT_PREVIEW: "Projektvorschau",
    FILE_VIEW: "Dateiansicht",
    PREVIEW_PROJECT: "Projektvorschau",
    VIEW_FILE: "Dateiansicht",
    SELECT_FILE: "Datei auswählen",

    REFRESH: "Aktualisieren",
    UPLOAD: "Hochladen",
    SETTINGS: "Einstellungen",
    VISUALIZATION_SHOW_SETTINGS_TOOLTIP: "Einstellungen",
    VISUALIZATION_SWITCH_SHOW_BORDER_INDICATOR:
        "Mängel und Behinderungen markieren",

    _UPLOADED: "Hochgeladen",
    _GENERATED: "Generiert",

    // Basic Error Messages
    ERROR_UNKNOWN_TITLE: "Unbekannter Fehler",
    ERROR_UNKNOWN_MESSAGE:
        "Ein unerwarteter Fehler ist aufgetreten. Bitte kontaktieren Sie die Support-Hotline oder support@sablono.com.",
    ERRORS_NO_DATA_TITLE: "Keine Daten",
    ERRORS_NO_DATA_MESSAGE:
        "Es existieren keine Daten für die gewünschte Funktion.",
    ERRORS_AUTHENTICATION_TITLE: "Authentifizierungsfehler",
    ERRORS_AUTHENTICATION_MESSAGE: "Ihre Session ist abgelaufen.",

    // HANA Native Errors
    ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_TITLE: "Der Wert exisitiert bereits",
    ERROR_HANA_UNIQUE_CONSTRAINT_VIOLATED_MESSAGE:
        "Der Wert kann nicht verwendet werden, da er bereits im System existiert",
    ERROR_HANA_INSUFFICIENT_PRIVILEGE_TITLE:
        "Operation abgebrochen: Nicht genügend Rechte",
    ERROR_HANA_INSUFFICIENT_PRIVILEGE_MESSAGE:
        "Die Operation kann nicht ausgeführt werden, da Sie nicht die nötigen Systemrechte besizten",
    ERROR_HANA_VALUE_TO_LARGE_TITLE: "Wert ist zu groß",
    ERROR_HANA_VALUE_TO_LARGE_MESSAGE:
        "Die Operation kann nicht ausgeführt werden, da ein Wert das Limit der Datenbank überschreitet.",

    ERROR_DATE_END_BEFORE_START: "Startdatum muss vor Enddatum liegen",
    // Custom HANA errors
    MISSING_INPUT:
        "Operation abgebrochen: Erforderliche Parameter nicht gesetzt.",
    ERROR_DATE_END_BEFORE_START_MESSAGE:
        "Datumsänderung nicht möglich! Startdatum muss vor Enddatum liegen",
    ERROR_PROJECT_OPERATION_DENIED:
        "Operation abgebrochen: Nicht genügend Rechte",
    ERROR_COMPONENT_OPERATION_DENIED:
        "Operation abgebrochen: Nicht genügend Rechte",
    ERROR_ACTIVITY_OPERATION_DENIED:
        "Operation abgebrochen: Nicht genügend Rechte",
    ERROR_INVALID_ALLOCATION_UNIT:
        "Operation abgebrochen: Kalendertyp unbekannt",
    ERROR_INVALID_COLOR: "Operation abgebrochen: Farbe ist ungültig",
    ERROR_MISSING_REQUIRED_PARAM:
        "Operation abgebrochen: Erforderliche Parameter nicht gesetzt",
    ERROR_CHANGING_PROGRESS_SIGNED_CHECKLIST_REQUIRED:
        "Bitte melden Sie Fortschritt an dieser Aktivität über die mobile App, da ein QS Protokoll angehängt ist.",
    ERROR_CONFIRMING_SIGNED_CHECKLIST_REQUIRED:
        "Bitte melden Sie Fortschritt an dieser Aktivität über die mobile App, da ein QS Protokoll angehängt ist.",
    ERROR_CHANGING_PROGRESS_ALREADY_CONFIRMED:
        "Diese Aktivität wurde bereits geprüft.",
    ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED:
        "Alle Bauleiter müssen zum gleichen Team gehören.",
    ERROR_AREA_MANAGER_MULTIPLE_TEAMS_UNSUPPORTED_TITLE:
        "Leider nicht möglich...",
    ERROR_CHAIN_LEAVE_AREA_MANAGER_UNSUPPORTED:
        "Dieser Bauleiter vertritt aktuell einen Anderen, weshalb er nicht vertreten werden kann.",
    ERROR_AREA_MANAGER_SUBSTITUTE_IS_ALREADY_ASSIGNED:
        "Der ausgwählte Nutzer vertritt bereits diesen Bauleiter.",
    ERROR_AREA_MANAGER_SUBSTITUTE_IS_ALREADY_ACTIVE:
        "Die Vertretung ist bereits aktiviert.",
    ERROR_INSUFFICIENT_PRIVILEGES_TO_BULK_STATE_CHANGE_TITLE:
        "Nicht verantwortlich",
    ERROR_INSUFFICIENT_PRIVILEGES_TO_BULK_STATE_CHANGE_MESSAGE:
        "Es scheint als haben Sie Projektelemente ausgewählten, für die ein anderer Nutzer als verantwortlicher Bauleiter festgelegt wurde. Das Update konnte daher nicht durchgeführt werden. Bitte wählen Sie nur Projektelemente aus, für die Sie verantwortlich sind und versuchen Sie es erneut.",
    ERROR_CHANGING_PROGRESS_REJECTED_TITLE: "Aktualisierung nicht möglich",
    ERROR_CHANGING_PROGRESS_REJECTED_MESSAGE:
        "Mindestens eine der ausgewählten Aktivitäten hat den Status abgelehnt. Abgelehnte Aktivitäten können nur auf abgeschlossen gesetzt werden.",
    ERROR_CHECKLIST_EXPORT_THRESHOLD:
        "Vorgang abgebrochen! Dieser Service unterstützt derzeit ein Maximum von 2000 QS Protokollen für eine einzelne Anfrage. Bitte wiederholen Sie den Vorgang mit weniger Projektelementen bzw. weniger Aktivitäten.",
    ERROR_CHECKLIST_EXPORT_SIZE_THRESHOLD:
        "Die von Ihnen angeforderten Berichte überschreiten unser Exportlimit von {{sizeLimitInMb}} MB. Ihre Anfrage enthält {{numberOfDocumentsIncluded}} QS Protokolle inkl. {{numberOfImagesIncluded}} Fotos ({{sizeEstimateInMbOfImages}} MB) und {{numberOfAttachmentsIncluded}} PDF-Anhänge ({{sizeEstimateInMbOfAttachments}} MB). Bitte reduzieren Sie die Größe Ihrer Exportanfrage mit Hilfe der Filter. Wenn Sie diese Meldung weiterhin sehen, wenden Sie sich bitte an unseren Support.",
    ERROR_UNIQUE_CODE_VIOLATION:
        "Die Excel Liste, die Sie importieren möchten enthält Projektelement Codes, die in diesem Projekt bereits genutzt werden. Wenn Sie existierende Projektelemente ändern möchten, dann nutzen Sie bitte die Aktualisierungs-Funktion. Wenn Sie neue Projektelemente importieren möchten, dann ändern Sie die Codes bitte in Excel, so dass sie für dieses Projekt einzigartig sind.",
    ERROR_UNIQUE_CODE_VIOLATION_TITLE: "Codes müssen einzigartig sein",

    //Request Errors
    ERROR_REQUEST_TITLE:
        "Fehler bei der Serveranfrage. Bitte kontaktieren Sie unser Support-Team.",
    ERROR_REQUEST_MESSAGE:
        "Die Anfrage an den Server ist fehlgeschlagen. Bitte versuchen Sie er erneut und kontaktieren Sie unser Support-Team, falls das Problem weiterhin besteht.",
    ERROR_REQUEST_TIMEOUT_TITLE:
        "Zeitüberschreitung während der Anfrage. Bitte versuchen Sie er erneut und kontaktieren Sie unser Support-Team, falls das Problem weiterhin besteht.",
    ERROR_REQUEST_TIMEOUT_MESSAGE:
        "Die Anfrage hat zu lange gedauert. Bitte versuchen Sie er erneut und kontaktieren Sie unser Support-Team, falls das Problem weiterhin besteht.",

    ERROR_REQUIRED_EMAIL_USERNAME: "E-Mail ist erforderlich.",
    ERROR_REQUIRED_PASSWORD: "Passwort erforderlich",
    ERROR_REQUIRED_FIRST_NAME: "Vorname erforderlich",
    ERROR_REQUIRED_LAST_NAME: "Nachname erforderlich",
    ERROR_REQUIRED_COMPANY: "Unternehmen erforderlich",
    ERROR_REQUIRED_EMAIL: "E-Mail ist erforderlich",

    //Authentication Errors
    ERROR_REQUIRED_VERIFICATION_CODE: "Verifizierungscode erforderlich",
    ERROR_AUTHENTICATION_UNKNOWN_TITLE: "Login fehlgeschlagen",
    ERROR_AUTHENTICATION_UNKNOWN_MESSAGE:
        "Login unerwartet fehlgeschlagen. Bitte versuchen Sie es erneut und kontaktieren Sie unser Support-Team unter support@sablono.com.",
    ERROR_AUTHENTICATION_NO_SESSION_TITLE: "Session abgelaufen",
    ERROR_AUTHENTICATION_NO_SESSION_MESSAGE: "Ihre Session ist abgelaufen.",
    ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD_TITLE:
        "E-Mail oder Passwort ungültig",
    ERROR_AUTHENTICATION_INVALID_USERNAME_OR_PASSWORD_MESSAGE:
        "Ihre E-Mail oder Passwort ist nicht gültig.",
    ERROR_AUTHENTICATION_USER_DEACTIVATED_TITLE: "Benutzer deaktiviert",
    ERROR_AUTHENTICATION_USER_DEACTIVATED_MESSAGE:
        "Ihr Benutzer ist deaktiviert. Bitte wenden Sie sich an den Support.",
    ERROR_AUTHENTICATION_USER_OUTSIDE_VALIDITY_PERIOD_TITLE:
        "Benutzer abgelaufen",
    ERROR_AUTHENTICATION_USER_OUTSIDE_VALIDITY_PERIOD_MESSAGE:
        "Ihr Benutzerkonto ist abgelaufen. Bitte wenden Sie sich an den Support.",
    ERROR_AUTHENTICATION_USER_LOCKED_TITLE: "Benutzer gesperrt",
    ERROR_AUTHENTICATION_USER_LOCKED_MESSAGE:
        "Ihr Benutzerkonto wurde automatisch wegen zu vielen Login versuchen gesperrt. Bitte warten Sie 15 Minuten und versuchen Sie es erneut. Wenn das Problem bestehen bleibt, kontaktieren Sie bitte unser Support-Team unter support@sablono.com.",
    ERROR_AUTHENTICATION_USER_PWD_CHANGE_TITLE: "Passwortänderung erforderlich",
    ERROR_AUTHENTICATION_USER_PWD_CHANGE_MESSAGE:
        "Bitte ändern Sie ihr Passwort, bevor sie fortfahren.",

    ERROR_PASSWORD_NOT_EQUAL_TITLE: "Passwort ist nicht gleich",
    //form validation
    ERROR_INPUT_REQUIRED_TITLE: "Pflichtfeld",
    ERROR_INPUT_EMAIL_REQUIRED_TITLE:
        "Bitte geben Sie eine E-Mail ein, damit wir Sie kontaktieren können.",
    ERROR_MINLENGTH_TITLE: "min. {{minlength}} Zeichen",
    ERROR_MAXLENGTH_TITLE: "max. {{maxlength}} Zeichen",
    ERROR_MIN_ONE_LOWERCASE_LETTER_TITLE: "mindestens einen Kleinbuchstaben",
    ERROR_MIN_ONE_UPPERCASE_LETTER_TITLE: "mindestens einen Großbuchstaben",
    ERROR_MIN_ONE_SPECIAL_CHAR_TITLE: "mindestens ein Sonderzeichen",
    ERROR_MIN_ONE_NUMBER_TITLE: "mindestens eine Ziffer (0-9)",
    ERROR_NUMBER_ONLY: "Geben Sie nur Zahlen ein.",
    ERROR_NO_DECIMAL: "Bitte wählen Sie nur volle Zahlen",

    //Roles error
    ERROR_INSUFFICIENT_PRIVILEDGES_FOR_REMOVE_USER_FROM_GROUP_MESSAGE:
        "Sie sind nicht autorisiert einen Nutzer zu entfernen.",
    ERROR_GROUP_DOES_NOT_EXIST_MESSAGE:
        "Diese Gruppe existiert nicht auf der Plattform.",
    ERROR_INSUFFICIENT_PRIVILEDGES_FOR_ADD_USER_TO_GROUP_MESSAGE:
        "Sie sind nicht autorisiert einen Nutzer hinzuzufügen.",
    ERROR_USER_ALREADY_IN_PROJECT:
        "Der hinzugefügte Nutzer is bereits Teil des Projekts.",
    ERROR_USER_ALREADY_IN_PROJECT_MESSAGE:
        "{{users}} {{(num === 1) ? 'ist' : 'sind'}} bereits Teil des Projekts.",
    ERROR_LAST_ADMIN_IN_PROJECT:
        "Ein Projekt muss mindestens einen Projektadministrator haben.",
    ERROR_FORBIDDEN_USER_IN_MULTIPLE_SYSTEM_GROUPS_MESSAGE:
        "Es ist nicht erlaubt den selben Nutzer in mehreren Standardgruppen haben.",

    // ui errors
    ERROR_UNEXPECTED_TITLE:
        "Ups.. das sollte eigentlich nicht passieren. Besser Sie gehen zurück zum Projektüberblick.",

    ERROR_UNSUPPORTED_FILE_EXTENSION:
        "Ungültiges Dateiformat. Unterstützt werden {{fileTypes}}",
    ERROR_FAILED_FILE_UPLOAD: "Upload fehlgeschlagen. Bitte erneut versuchen",
    ERROR_MULTIPLE_INVITATIONS_PER_PROJECT: "Benutzer ist bereits eingeladen",

    /**
     * translations for dialogs
     */
    DIALOG_ALERT_OK: "OK",

    DIALOG_CONFIRM_OK: "OK",
    DIALOG_CONFIRM_CANCEL: "Abbrechen",
    DIALOG_CONFIRM_CREATE: "Speichern",
    DIALOG_CONFIRM_CREATE_AND_NEW: "Speichern & Weiteren erstellen",

    DIALOG_SELECT_OK: "Auswählen",
    DIALOG_SELECT_CANCEL: "Abbrechen",
    DIALOG_SELECT_NEXT: "Weiter",
    DIALOG_SELECT_BACK: "Zurück",

    //Unable to fetch progress for the project
    DIALOG_FETCH_PROGRESS_ERROR_TITLE:
        "Fehler beim Laden der Fortschrittsdaten",
    DIALOG_FETCH_PROGRESS_ERROR_CONTENT:
        "Beim Laden der Fortschrittsdaten ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",

    //Unable to apply filters due to resulting query being too large too process
    DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_TITLE: "Uups...",
    DIALOG_FILTER_QUERY_TOO_LARGE_ERROR_CONTENT:
        "Leider kann der aktuell ausgewählte Projektstruktur-Filter nicht angewendet werden. Dies liegt an Limitationen der Web-Browser Technologie. Bitte wählen Sie einen tieferliegenden Strukturknoten (bspw. Etage statt Haus) oder ein anderes Filterkriterium.",

    //wrong extension
    DIALOG_WRONG_EXTENSION_TITLE: "Falsches Dateiformat",
    DIALOG_WRONG_EXTENSION_CONTENT:
        "Bitte wählen Sie eine Datei mit dem richtigen Dateiformat.",

    // error reading file
    DIALOG_READING_FILE_ERROR_TITLE: "Lesen der Datei fehlgeschlagen",
    DIALOG_READING_FILE_ERROR_CONTENT:
        "Beim Lesen der Datei ist ein Fehler aufgetreten, bitte versuchen sie es erneut",

    // error file has false or not existing fields
    DIALOG_ANALYSING_FILE_ERROR_TITLE: "Fehler beim Lesen der Datei",
    DIALOG_ANALYSING_FILE_ERROR_CONTENT:
        "Beim Lesen der Datei ist ein Fehler aufgetreten, einige Felder sind nicht vorhanden oder beschädigt. Bitte überprüfen Sie Ihre Datei.",

    // import error
    DIALOG_IMPORT_FILE_ERROR_TITLE: "Import der Datei fehlgeschlagen",
    DIALOG_IMPORT_FILE_ERROR_CONTENT:
        "Beim importieren der Datei ist ein Fehler aufgetreten, bitte versuchen sie es erneut",
    ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF_TITLE:
        "@:SECTION_MERGE_ERROR_TITLE",
    ERROR_TOO_MANY_PAGES_FOR_LOOK_AHEAD_PDF:
        "Die PDF-Datei konnte aufgrund der großen Anzahl von Seiten nicht exportiert werden. Bitte wählen Sie einen kleineren Zeitraum oder eine geringere Anzahl Projektelemente.",

    //user interaction
    DIALOG_USER_CANCELED: "Aktion abgebrochen.",

    DIALOG_IMPORT_MERGE_CONFLICT_TITLE: "Hinweis",
    DIALOG_IMPORT_MERGE_CONFLICT_CONTENT:
        "Sie scheinen einen Terminplan importieren zu wollen, der einem bereits in dieses Projekt importiertem gleicht. Wenn Sie fortfahren, werden sämtliche Vorgänge erneut zu Ihrem Sablono Projekt hinzugefügt. Sie können Ihr Projekt allerdings auch aktualisieren, um das Anlegen doppelter Projektelemente zu vermeiden.",
    DIALOG_IMPORT_MERGE_ACTION: "Projekt aktualisieren",
    DIALOG_IMPORT_CONTINUE_ACTION: "Import fortsetzen",

    DIALOG_HELP_DESK_SCRIPT_BLOCKED_TITLE: "Support Chat Warnung!",
    DIALOG_HELP_DESK_SCRIPT_BLOCKED_CONTENT:
        "Eines Ihrer Browser-Addons blockiert den Support Chat. Bitte deaktivieren Sie alle Werbeblocker, um eine reibungslose Nutzung zu gewährleisten.",

    DIALOG_ASSIGN_TEMPLATE_TITLE: "Workflow-Vorlage zuweisen",
    DIALOG_ASSIGN_TEMPLATE_TEXT:
        "Wählen Sie eine Workflow-Vorlage aus und weisen Sie diese dem aktuell ausgewählten Projektelement zu.",
    DIALOG_ASSIGN_TEMPLATE_NO_DATA_TEXT:
        "Es scheint als haben Sie noch keine Workflow-Vorlage erstellt. Nutzen Sie den unteren Button, um den Editor zu öffnen.",
    DIALOG_ASSIGN_TEMPLATE_NO_TEMPLATES_NAV_TO: "Zur Vorlagenübersicht",
    ACTION_ASSIGN_TEMPLATE: "Vorlage zuweisen",
    DIALOG_ASSIGN_TEMPLATE_SEARCH_PLACEHOLDER:
        "Suchen Sie Vorlagen nach Name, Code...",
    DIALOG_ASSIGN_TEMPLATE_EMPTY_SEARCH_TEXT:
        "Es existiert keine Workflow-Vorlage, die zu Ihrem Suchbegriff passt.",

    DIALOG_CLONE_TEMPLATE_TITLE: "@:ACTION_CLONE_TEMPLATE",
    DIALOG_CLONE_TEMPLATE_TEXT:
        "Bitte wählen Sie die Workflow-Vorlage, die Sie in das aktuelle Projekt kopieren möchten, aus der unteren Liste aus.",
    DIALOG_CLONE_TEMPLATE_BUTTON_TEXT: "Vorlage kopieren",
    DIALOG_CLONE_TEMPLATE_SUCCESS_TOAST_TEXT: "Vorlage erfolgreich kopiert",
    DIALOG_CLONE_TEMPLATE_SEARCH_PLACEHOLDER:
        "Suchen Sie Vorlagen nach Name, Code oder Projekt...",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_AND_QA_TITLE:
        "Nun legen Sie bitte fest, was mit QS Protokollen und Teams geschehen soll, die an die gewählte Workflow-Vorlage angehängt sind.",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_SKIP:
        "Keine QS Protokolle oder Teams kopieren und zuweisen",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_REUSE:
        "Nutze bestehende und kopiere neue QS Protokolle und Teams, um sie an die kopierte Workflow-Vorlage zuzuweisen",
    DIALOG_CLONE_TEMPLATE_COPY_TEAMS_OPTION_CLONE:
        "Alle QS Protokolle und Teams kopieren, um sie an die kopierte Workflow-Vorlage zuzuweisen (kann zu Duplikaten führen)",

    // Creating project

    DIALOG_CREATING_PROJECT: "Projekt wird erstellt",
    DIALOG_BUSY_TAKE_TIME: "Kann einige Momente dauern.",

    // creating new activities
    DIALOG_CREATE_ACTIVITY_TOOLBAR_TITLE: "Neue Aktivität erstellen",
    DIALOG_ACTION_CREATE_ACTIVITY: "Aktivität erstellen",
    _RECENT_ACTIVITIES: "Kürzlich angelegte Aktivitäten",
    _ADD_ACTIVITY_TOOLTIP: "Aktivität zu diesem Projektelement hinzufügen.",

    // md-table
    ROWS_PER_PAGE: "Zeilen pro Seite",

    // Create deliverables dialog
    ACTION_CREATE_DELIVERABLES: "Projektelemente erstellen",
    DIALOG_CREATE_NEW_DELIVERABLES_TITLE: "Neue Projektelemente erstellen",
    INPUT_NAME_NEW_DELIVERABLE_PLACEHOLDER:
        "z.B. Wohneinheit 3.2.1, Raum 202, Fassadensegement 3777-08",
    INPUT_CODE_NEW_DELIVERABLE_PLACEHOLDER:
        "z.B. We. 3.2.1, R-202, F-3777-08 - maximal 64 Zeichen",
    DIALOG_CREATE_NEW_DELIVERABLE_SAVED: "Projektelemente gespeichert",
    DIALOG_CREATE_NEW_DELIVERABLES_ERROR_EXISTING_CODE:
        "Nicht möglich: In Ihrem Projekt ist bereits ein Projektelement mit identischem Code. Bitte nutzen Sie einen anderen Code.",

    //upload

    UPLOAD_ERROR: "Hochladen fehlgeschlagen",

    UPLOAD_SUCCESSFUL: "Hochladen erfolgreich",
    UPLOAD_SUCCESSFUL_CREATED_INFO:
        "{{elements}} Projektelement{{(elements > 1) ? 'e' : ''}} neu angelegt und dem Projekt zugeordnet.",
    UPLOAD_SUCCESSFUL_UPDATED_INFO:
        "{{elements}} Projektelement{{(elements > 1) ? 'e' : ''}} mit den neuen Werten der Datei aktualisiert.",
    UPLOAD_SUCCESSFUL_DELETED_INFO:
        "{{elements}} Projektelement{{(elements > 1) ? 'e' : ''}} aus dem Projekt entfernt.",
    UPLOAD_SUCCESSFUL_CREATED_STRUCTURE_INFO:
        "{{elements}} Strukturelement{{(elements > 1) ? 'e' : ''}} neu angelegt und dem Projekt zugeordnet.",
    UPLOAD_SUCCESSFUL_UPDATED_STRUCTURE_INFO:
        "{{elements}} Strukturelement{{(elements > 1) ? 'e' : ''}} mit den neuen Werten der Datei aktualisiert.",
    UPLOAD_SUCCESSFUL_DELETED_STRUCTURE_INFO:
        "{{elements}} Strukturelement{{(elements > 1) ? 'e' : ''}} aus dem Projekt entfernt.",
    UPLOAD_SUCCESSFUL_UPDATED_INFO_NO_FILE:
        "{{elements}} Projektelement{{(elements > 1) ? 'e' : ''}} mit den neuen Werten aktualisiert.",
    UPLOAD_SUCCESS_WARN_LIMITS_TITLE:
        "Das Projekt, das Sie gerade importiert haben, enthält Texte, die unsere Limits überschreiten. Daher wurden sie automatisch gekürzt. ",
    UPLOAD_SUCCESS_WARN_LIMITS_MESSAGE:
        "Unsere Limits sind: 64 Zeichen für Codes, 120 Zeichen für Namen und 1000 Zeichen für Beschreibungen Ihrer Projektelemente.",

    TO_PROJECT_DATA: "Zu den Projektelementen",
    GO_TO_DELIVERABLES: "Zu den Projektelementen",

    DIALOG_UPLOADING_FILE_ERROR_TITLE: "Fehler beim Hochladen der Daten",
    DIALOG_UPLOADING_FILE_ERROR_CONTENT:
        "Beim Hochladen der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",

    DIALOG_UPLOADING_REMAINING_TIME_LEFT:
        "Geschätzte verbleibende Zeit: {{time}} Sekunden.",
    DIALOG_PREVENT_CLOSING:
        "Der Uploadvorgang läuft noch und sollte nicht unterbrochen werden!",

    // import
    INFO_IMPORT_PROJECT_DATA_INTRODUCTION_MESSAGE:
        "Wenn Sie ein neues Projekt anlegen, importieren Sie bitte zunächst Projektelemente aus Microsoft© Excel oder Asta Powerproject.\n Später können Sie auch Aktivitätstermine oder Fortschritte über Microsoft© Excel importieren.",
    IMPORT_UPLOAD_COMPONENTS_IN_PROGRESS:
        "Es werden {{ elements }} Projektelemente zu Sablono gesendet.",
    SECTION_IMPORT_SUCCESSFULL_PROJECT_IMPORT_HEADER:
        "Projekt erfolgreich importiert",

    //excel import
    LEVEL: "Ebene ",
    ASSIGN_PROJECT_ELEMENTS: "Projektelemente",
    CREATE_STRUCTURE: "Projektstruktur",
    ADD_STURCUTRE_LEVEL: "Ebene hinzufügen",
    NOT_ASSIGNED: "Nicht Zugewiesen",
    INFO_IMPORT_NO_DEFINED_STRUCTURE: "Keine Struktur angelegt",
    ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_TITLE: "Strukturen fehlerhaft",
    ERROR_IMPORT_EXCEL_INVALID_STRUCTURE_ROWS_MESSAGE:
        "Die Strukturen in den Zeilen {{ invalidRowNumberRanges.join(', ') }} sind fehlerhaft. Bitte überprüfen und ändern Sie Ihre Daten in der Datei.",
    INFO_HOW_TO_IMPORT_A_EXCEL_PROJECT:
        "How to: Ein Microsoft Excel-Projekt importieren",

    NO_MATCH_FOUND: "Keine Übereinstimmung...",

    // import preview
    STRUCTURE: "Projektstruktur",
    STRUCTURE_BRACES: "Projektstruktur (Sammelvorgänge)",
    PROJECT_ELEMENTS: "Projektelemente",
    PROJECT_ELEMENTS_BRACES: "Projektelemente (Vorgänge)",
    REFRESH_PREVIEW: "Projektvorschau Aktualisieren",

    //Select file
    ACTION_REFRESH_SELECTED_FILE_TOOLTIP: "Ausgewählte Datei aktualisieren",
    PLEASE_SELECT_FILE: "Bitte wählen Sie eine Datei aus",

    EDGE_MODE_ACTIVE:
        "Kanten Modus aktiv - Klicke auf ein Projektelement um eine Kante zwischen ihnen zu erstellen.",
    EDGE_CREATE_SUCCESSFUL: "Kante erfolgreich erstellt",
    EDGE_DELETE_SUCCESSFUL: "Kante erfolgreich gelöscht",

    TOGGLE_EDIT_MODE_TOOLTIP: "Bearbeiten",

    ACTION_ACTIVATE_EDIT_MODE: "Projektelement editieren",
    ACTION_DEACTIVATE_EDIT_MODE: "Editieren beenden",

    // xml import
    XML_IMPORT_NO_STRUCTURE:
        "Leider konnten wir keine Projektstruktur in Ihrer Datei erkennen. Nach dem erfolgreichen Import können Sie allerdings einfach eine neue in Sablono anlegen.",

    // general PDF
    PDF_GEN_PROGRESS: "Datei erstellt.",

    //qr codes
    ACTION_QR_CODE_GENERATE: "Drucke QR-Codes",
    ACTION_QR_CODE_GENERATE_TOOLTIP:
        "Erstellen Sie druckbare QR-Codes für Ihre Strutur als PDF.",
    QR_DIALOG_TITLE: "Wählen Sie ein Format",
    QR_2x6_TITLE: "2 x 6 QR-Codes",
    QR_1x2_TITLE: "1 x 2 QR-Codes",
    QR_STICKER_POWERED_BY: "Powered by ",
    QR_STICKER_WARN_TEXT:
        "Aufkleber nur auf Anweisung des Bauherren entfernen!",
    QR_DIALOG_LAST_LEVEL_TEXT:
        "Erzeuge QR-Codes für Strukturelemente der untersten Ebenen",
    QR_DIALOG_SELECT_STRUCTURE_TITLE: "Strukturelemente zum Drucken auswählen",
    QR_DIALOG_STRUCTURE_SELECTION_TEXT:
        "Erzeuge QR-Codes für ausgewählte Strukturelemente",
    QR_DIALOG_STRUCTURE_ALL_TEXT:
        "Erzeuge QR-Codes für die gesamte Projektstruktur",
    ERROR_REPORT_SIZE_LIMIT_TITLE: "Ihr angefragter Export ist zu groß",
    QR_DIALOG_ERROR_REPORT_SIZE_MESSAGE:
        "Bitte erstellen Sie Ihre QR-Codes für weniger als {{max}} Projektelemente.",
    QR_DIALOG_WBS_ERROR_REPORT_SIZE_MESSAGE:
        "Bitte erstellen Sie Ihre QR-Codes für weniger als {{max}} Strukturelemente.",
    DELIVERABLE_REPORT_ERROR_SIZE_MESSAGE:
        "Bitte erstellen Sie diesen Report mit weniger als {{max}} Projektelementen.",
    ACTIVITIES_REPORT_ERROR_SIZE_MESSAGE:
        "Bitte erstellen Sie diesen Report mit weniger als {{max}} Aktivitäten.",

    // code generation
    ACTION_GENERATE_CODES: "Kodierungen erzeugen",
    ACTION_GENERATE_CODES_TOOLTIP:
        "Generiert die Kodierungen für alle Strukturknoten.",
    CODE_GENERATION_CONFIRM_DIALOG_TITLE: "Strukturcodes Generierung",
    CODE_GENERATION_CONFIRM_DIALOG_CONTENT:
        "Für alle Elemente der Projektstruktur werden neue Codes generiert. Alle zuvor festgelegten Codes werden überschrieben. Möchten Sie fortfahren?",
    CODE_GENERATION_SUCCESS_MESSAGE: "Codes generiert.",

    // merge structure in wbs
    ACTION_MERGE_STRUCTURE: "Struktur zusammenfassen",
    ACTION_MERGE_STRUCTURE_TOOLTIP:
        "Führt Struktur-Duplikate automatisch zusammen",
    DIALOG_MERGE_STRUCTURE_TITLE: "Fassen Sie Ihre Projektstruktur zusammen",
    DIALOG_MERGE_STRUCTURE_TEXT:
        "Bitte wählen Sie die Projektstruktur (anhand ihrer Quelle) aus, in die doppelte Strukturelemente integriert werden sollen.<br /><br /><b>Hinweis:</b> Um Strukturelemente zusammenfassen zu können, müssen diese gleich benannt und auf der gleichen Strukturebene eingeordnet sein.",
    DIALOG_MERGE_STRUCTURE_LOADING_TITLE: "Nur noch einige Momente...",
    DIALOG_MERGE_STRUCTURE_LOADING_TEXT:
        "Wir fassen gerade Ihre Projektstruktur zusammen...",
    DIALOG_MERGE_STRUCTURE_SUCCESS_TITLE: "Projektstruktur zusammengefasst",

    // WBS
    WBS_CREATE_CHILD_TOOLTIP: "Unterkategorie anlegen",
    WBS_CREATE_SIBLING_TOOLTIP: "Nebenkategorie anlegen",
    WBS_DELETE_NODE_TOOLTIP: "Kategorie löschen",
    CONFIRM_DELETE_STRUCTURE_NODE_TITLE: "Sind Sie sicher?",
    CONFIRM_DELETE_STRUCTURE_NODE_TEXT:
        "Möchten Sie wirklich diesen Teil Ihrer Projektstruktur löschen?",

    //SECTIONS

    // login and registration page
    SUCCESSFUL_USER_SIGNUP:
        "Wir haben ihre Daten erhalten und werden uns umgehend bei Ihnen melden.",
    SUCCESSFUL_USER_SIGNUP_GET_MORE_INFO:
        "In der Zwischenzeit lernen Sie doch Sablono ein bisschen näher kennen:",

    // project selection
    PROJECT_SELECTION_PENDING_INVITES_TITLE: "Ausstehende Einladungen",
    PROJECT_SELECTION_PENDING_INVITES_CONTROL_ACCEPT: "Akzeptieren",
    PROJECT_SELECTION_PENDING_INVITES_CONTROL_DECLINE: "Ablehnen",
    PROJECT_SELECTION_ACCEPT_INVITATION: "Einladung angenommen",
    PROJECT_SELECTION_DECLINE_INVITATION: "Einladung abgelehnt",
    PROJECT_SELECTION_WELCOME_CARD_TITLE: "Willkommen bei Sablono!",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_PROJECT_MANAGER_TEXT:
        "Noch kein Mitglied in einem Projekt? Bitten Sie den verantwortlichen Projektmanager, Sie hinzuzufügen!",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_PROJECT_MANAGER_BUTTON_TEXT:
        "Anfrage senden",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_SUPPORT_TEXT:
        "Sollten Sie auf Fragen stoßen, kontaktieren Sie uns gerne werktags zwischen 09-18Uhr.",
    PROJECT_SELECTION_WELCOME_CARD_CONTACT_SUPPORT_BUTTON_TEXT:
        "Support kontaktieren",
    PROJECT_SELECTION_WELCOME_CARD_EMAIL_SUBJECT:
        "Zugang zum Projekt auf der Sablono Plattform",
    PROJECT_SELECTION_WELCOME_CARD_EMAIL_BODY:
        "Guten Tag,%0D%0A%0D%0A ich habe meinen Sablono Account aktiviert. Bitte fügen Sie mich nun zum Projekt hinzu, sodass ich anfangen kann, die Plattform zu verwenden.%0D%0A%0D%0A Vielen Dank!%0D%0A%0D%0A Zur Plattform: https://app.sablono.com/",
    PROJECT_SELECTION_FILTERS_STATUS: "Status",

    // project edit
    PROJECT_LANGUAGE_HELP_TOOLTIP_MESSAGE:
        "Die Sprache aller E-Mail-Benachrichtigungen wird über Projektsprache festgelegt.",
    PROJECT_TIMEZONE_HELP_TOOLTIP_MESSAGE:
        "Die Projektzeitzone wird genutzt, um einen Terminplan für die auf der Plattform angelegten Aktivitäten zu berechnen.",

    PROJECT_CURRENCY_HELP_TOOLTIP_MESSAGE:
        "Die Projektwährung wird in Kombination mit allen Budgets angezeigt, bspw. auf der Budgetübersicht oder dem Status Tracker.",

    //Dashboard
    SECTION_INSPECTIONS_PROGRESS: "Projektfortschritt (letzte 10 Wochen)",

    // deliverable cards
    DASHBOARD_DELIVERABLES_UNSCHEDULED:
        "Projektelement{{(items ===0 || items > 1) ? 'e' : ''}} ohne Start- und Solltermin",
    DASHBOARD_DELIVERABLES_BEHIND:
        "Projektelement{{(items ===0 || items > 1) ? 'e' : ''}} hinter dem Zeitplan",
    DASHBOARD_DELIVERABLES_WITH_CLAIMS:
        "Projektelement{{(items ===0 || items > 1) ? 'e' : ''}} mit offenen Mängeln",
    DASHBOARD_DELIVERABLES_WITH_OBSTRU:
        "Projektelement{{(items ===0 || items > 1) ? 'e' : ''}} mit offenen Behinderungen",
    DASHBOARD_DELIVERABLES_WITH_INFO:
        "Projektelement{{(items ===0 || items > 1) ? 'e' : ''}} mit Informationen",
    DASHBOARD_ALL_DELIVERABLES: "Alle Projektelemente",

    DASHBOARD_STATS_CHART_SELECTION_TITLE: "Balkendiagramm: Soll-Ist-Vergleich",
    DASHBOARD_STATS_CHART_SELECT_TYPE: "Workflow-Vorlage auswählen",
    DASHBOARD_S_CURVE_CHART_SELECTION_TITLE: "S-Kurve: Soll-Ist-Vergleich",
    DASHBOARD_S_CURVE_CHART_CHART_SELECT_TYPE: "Aktivitäten auswählen",
    DASHBOARD_S_CURVE_CHART_CHART_DESELECT_TYPE: "Aktivitäts-Auswahl entfernen",
    DASHBOARD_S_CURVE_CHART_SELECTION_LABEL: "Auswahl:",
    DASHBOARD_S_CURVE_CHART_SELECTION_EVERYTHING:
        "Alle Aktivitäten, die den gewählten Filtern passen",
    DASHBOARD_S_CURVE_CHART_SELECTION_NO_MATCH:
        "Es gibt keine Ergebnisse für die aktiven Filter",
    DASHBOARD_PRODUCTIVITY_CHART_SELECTION_TITLE:
        "Produktivitätsdiagramm: Soll-Ist-Vergleich pro KW",
    DASHBOARD_CHARTS_LEGEND_PLANNED: "Geplant",
    DASHBOARD_CHARTS_LEGEND_FINISHED: "Arbeit Abgeschlossen",
    DASHBOARD_CHARTS_LEGEND_CONFIRMED: "Geprüft",
    DASHBOARD_CHARTS_LEGEND_UNPLANNED: "Ungeplant",
    DASHBOARD_CHARTS_LEGEND_COMPLETED:
        "Finalisiert: Geprüft + Abgeschlossen nach Gewerk",

    // chart cards
    FILTER_BY_STRUCTURE: "Nach Struktur filtern",
    FILTER_BY_ALL_STRUCTURES: "Gesamtes Projekt",
    DASHBOARD_S_CURVE_CHART_EMPTY:
        "Es gibt keine Ergebnisse für die aktiven Filter. Falls Sie bestimmte Aktivitäten ausgewählt haben, können Sie diese unten abwählen.",
    DASHBOARD_BAR_CHART_EMPTY:
        "Es gibt keine Ergebnisse für die aktiven Filter.",

    DASHBOARD_FILTER_BY_TEAM: "Nach Team filtern",
    DASHBOARD_FILTER_BY_ALL_TEAMS: "Alle Teams",

    DASHBOARD_FILTER_BY_PROCESS_TEMPLATE: "Nach Workflow filtern",
    DASHBOARD_FILTER_BY_ALL_PROCESS_TEMPLATES: "Alle Workflows",

    DASHBOARD_SELECT_ACTIVITIES_DIALOG_TITLE: "Nach Aktivitäten filtern",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_SEARCHBAR_PLACEHOLDER:
        "Suchen Sie nach Aktivitäten anhand von Workflow-Vorlage, Name und Team...",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_DESCRIPTION:
        "Bitte wählen Sie die Aktivitäts-Kombination, die Sie in der S-Kurve sehen möchten:",
    DASHBOARD_SELECT_ACTIVITIES_DIALOG_SELECT_BUTTON: "Kombination anzeigen",

    // Dashboard without data
    SECTION_DASHBOARD_NO_DATA_TITLE: "Bitte richten Sie Ihr Projekt fertig ein",
    SECTION_DASHBOARD_NO_DATA_CARD_1_TITLE:
        "Erstellen Sie Ihre ersten Projektelemente",
    SECTION_DASHBOARD_NO_DATA_CARD_1_TEXT:
        "Sie können Ihre Projektelemente aus Microsoft© Excel und Asta Powerproject importieren. Oder Sie nutzen den oberen Button, um sie manuell zu erstellen.",
    SECTION_DASHBOARD_NO_DATA_CARD_1_ACTION: "Zum Import",
    SECTION_DASHBOARD_NO_DATA_CARD_2_TITLE: "Projekteinrichtung abschließen",
    SECTION_DASHBOARD_NO_DATA_CARD_2_TEXT:
        "Um Ihre Arbeit mit diesem Projekt starten zu können, müssen Sie Projektelemente und eine Workflow-Vorlage erstellen. Anschließend weisen Sie die Vorlage Ihren Projektelementen zu, um Aktivitäten zu erstellen.",
    SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION: "Zu den Projektelementen",
    SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION_2: "Zu den Workflow-Vorlage",

    SECTION_DASHBOARD_NO_DATA_TEMPLATES_TITLE: "Workflow-Vorlage anlegen",
    SECTION_DASHBOARD_NO_DATA_TEMPLATES_TEXT:
        "Projektelementen sollte eine Workflow-Vorlagen zugewiesen werden, um deren Aktivitäten abhaken zu können.",
    SECTION_DASHBOARD_NO_DATA_TEMPLATES_ACTION: "Zum Vorlageneditor",

    INFO_STRUCTURE_PROGRESS:
        "{{allElements}} Projektelemente, {{finishedElements}}% fertig",
    ERROR_EMPTY_TIMELINE_TITLE: "Es gibt noch keine Erfassungen",

    //Timeline
    INFO_SHOW_ALL_INSPECTIONS:
        "Alle {{totalAmountOfInspections}} Erfassungen anzeigen",
    INSPECTION_DETAIL_TITLE: "Erfasst durch - {{author}}",
    INSPECTION_DETAIL_SUBTITLE: "Hochgeladen {{time}}",
    INSPECTION_DETAIL_HEADER_PROGRESS:
        "{{number}} Projektelemente aktualisiert",
    INSPECTION_DETAIL_HEADER_NEW_NOTES: "{{number}} Meldungen erstellt",
    INSPECTION_DETAIL_HEADER_RESOLVED_NOTES: "{{number}} Probleme behoben",
    INSPECTION_DETAIL_PAGE_NO_FILTERED_DATA:
        "Es existieren keine Erfassungen zu den aktiven Filtern.",
    INSPECTION_DETAIL_FILTER_AUTHOR: "Erfassser",
    INSPECTION_DETAIL_FILTER_TIMESTAMP: "Datum",
    INSPECTION_DETAIL_NO_DATA_TITLE: "Bisher keine Erfassungen",
    INSPECTION_DETAIL_NO_DATA_TEXT:
        "Es wurden noch keine Daten-Erfassungen in diesem Projekt durchgeführt. Nach abgeschlossener Projekteinrichtung, können Sie Sie unsere mobilen Anwendungen oder bspw. die Projektfortschitts-Seite nutzen, um Daten zu erfassen.",
    INSPECTION_DETAIL_NO_DATA_ACTION: "Zum Projektfortschritt",

    //PROFILE PAGE
    SECTION_USER_PROFILE_TITLE: "Benutzerprofil",
    ERROR_PASSWORD_CHANGE_LIFETIME_MESSAGE:
        "Änderung des Passwortes nur einmal pro Tag möglich. Bitte warten Sie {{time}}.",
    SECTION_YOUR_INFORMATION_TITLE: "Persönliche Informationen",
    INFO_GENDER_FEMALE_TITLE: "Frau",
    INFO_GENDER_MALE_TITLE: "Herr",
    ERROR_EMAIL_FORMAT_MESSAGE: "Keine gültige E-Mail ",
    INPUT_SALUTATION_TITLE: "Anrede",
    INPUT_TITLE_TITLE: "Titel",
    INPUT_FIRSTNAME_TITLE: "Vorname",
    INPUT_LASTNAME_TITLE: "Nachname",
    INPUT_COMPANY_TITLE: "Firma",
    ACTION_SAVE: "Speichern",
    ACTION_PUBLISH: "Veröffentlichen",
    SECTION_CHANGE_PASSWORD_TITLE: "Passwort ändern",
    CHANGE_PASSWORD_ENTER_OLD_TITLE: "Bestätigen",
    CHANGE_PASSWORD_ENTER_OLD_MESSAGE:
        "Zum Bestätigen bitte das alte Passwort eingeben.",
    ACTION_CHANGE: "Ändern",
    SECTION_EMAIL_ADRESSES_TITLE: "E-Mail-Adressen",
    INFO_PRIMARY_TITLE: "Primär",
    INFO_PRIMARY_NOT_VERIFIED: "Nicht verifiziert",
    INFO_PRIMARY_VERIFIED: "Verifiziert",
    INPUT_NEW_EMAIL_TITLE: "Neue E-Mail-Adresse hinzufügen",
    INFO_SAVE_SUCCESS_TITLE: "Erfolgreich gespeichert",
    INFO_PASSWORD_CHANGE_SUCCESS_TITLE: "Passwort erfolgreich geändert",
    ACTION_CHANGE_PICTURE: "Bild auswählen",

    ACTION_FILTER: "Filtern",
    ACTION_CLEAR_FILTERS: "Filter entfernen",

    //Add Role Dialog
    _ROLE_DESCRIPTION_ADMIN:
        "Ein Projektadministrator hat Zugriff auf alle Funktionen der Plattform.<br>Diese Rolle sollte nur für Administratoren der höchsten Ebene verwendet werden.",
    _ROLE_DESCRIPTION_MANAGER:
        "Ein Projektmanager hat Zugriff auf fast alle Funktionen der Plattform, mit Ausnahme von Projekteinstellungen und dem Bearbeiten von Meldungen.<br>Diese Rolle sollte für Benutzer verwendet werden, die das Projekt einrichten und Daten hochladen sollen.",
    _ROLE_DESCRIPTION_INSPECTOR:
        "Ein Erfasser hat Zugriff auf die mobile Sablono App sowie Fortschrittsvisualisierungen und das Lean Board.<br>Diese Rolle sollte für Benutzer verwendet werden, die Daten hochladen sollen, aber selbst nicht wirklich mit Sablono arbeiten.",
    _ROLE_DESCRIPTION_CONTROLLER:
        "Ein Projektcontroller hat Zugriff auf die mobile Sablono App und Seiten, die nicht zur Projektkonfiguration genutzt werden.<br>Die Rolle sollte für Benutzer verwendet werden, die mit allen Funktionen arbeiten sollen, aber nicht an der Einrichtung beteiligt sind.",
    _ROLE_DESCRIPTION_REVIEWER:
        "Ein Auswerter hat lesenden Zugriff auf das Projekt.<br>Die Rolle sollte für Benutzer verwendet werden, die mit den Daten arbeiten sollen, aber nicht selber Daten hochladen werden.",
    ACTION_ADD: "Hinzufügen",

    SECTION_TEAMS_ADD_USER_DIALOG_TITLE:
        "Neues Mitglied zu <b>{{name}}</b> einladen",
    SECTION_TEAMS_ADD_USER_DIALOG_TEXT:
        "Mitglieder dieses Teams haben nur Zugriff auf Aktivitäten, die entweder dem Team oder explizit 'jedem im Projekt ' zugewiesen wurden. Dies kann während der Erstellung von Workflow-Vorlagen eingerichtet werden.",
    SECTION_TEAMS_ADD_USER_DIALOG_OK: "Mitglied einladen",
    SECTION_TEAMS_ADD_USER_INPUT_INVALID:
        "Bitte geben Sie eine gültige E-Mail Adresse ein",

    SECTION_TEAMS_EDIT_USER_DIALOG_TITLE: "<b>{{name}}</b> anpassen",
    SECTION_TEAMS_EDIT_USER_DIALOG_OK: "Mitglied anpassen",
    SECTION_TEAMS_EDIT_USER_DIALOG_CHANGE_ROLE:
        "Oder seine Rolle im Projekt ändern.",
    SECTION_TEAMS_ADD_USERS_DIALOG_CHANGE_ROLE:
        "Bitte wählen Sie eine Rolle, die Zugangsrechte im Projekt definiert:",
    SECTION_TEAMS_EDIT_USER_DIALOG_CHANGE_TEAM:
        "Hier können Sie den Nutzer in ein anderes Team verschieben.",
    SECTION_TEAMS_ADD_NEW_USER_BADGE: "Neues Mitglied hinzufügen",
    SECTION_TEAMS_ADD_NEW_COMMERCIAL_USER_BADGE:
        "Kaufmännische Nutzer hinzufügen",

    SECTION_TEAMS_EDIT_INVITATION_DIALOG_TITLE:
        "Einladung für <b>{{name}}</b> anpassen",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_CHANGE_TEAM:
        "Hier können Sie den Nutzer in ein anderes Team verschieben.",
    SECTION_TEAMS_EDIT_INVITATION_DIALOG_CHANGE_ROLE:
        "Oder seine Rolle im Projekt ändern.",
    ACTION_INVITATION_RESEND: "Einladung erneut senden",
    ACTION_INVITATION_UPDATE_AND_RESEND: "Einladung anpassen und erneut senden",

    INPUT_SEARCH_USER_MESSAGE: "Nutzer suchen...",
    INPUT_SELECT_ROLE_MESSAGE: "Nutzer erhalten die Rolle...",

    //Project Team
    SECTION_INSPECT_APP_SETTINGS_TITLE: "Mobile App Einstellungen",
    SECTION_INSPECT_APP_SETTINGS_GEOLOCATION:
        "Erfassen der Geo-Koordinaten für Fotos",
    SECTION_INSPECT_APP_GEOLOCATION_PERMISSIONS:
        "Aufgenommene oder hochgeladene Fotos, enthalten die Geo-Koordinaten der Position, an der sie aufgenommen wurden, sofern diese Information verfügbar ist.",
    SECTION_INSPECT_APP_SETTINGS_HIDE_READONLY_ENTITIES_TITLE:
        "[Nur Inspect App] Aktivitäten reduzieren",
    SECTION_INSPECT_APP_SETTINGS_HIDE_READONLY_ENTITIES_ENABLED:
        "Die Inspect App stellt nur die Aktivitäten dar, die vom mobilen Nutzer bearbeitet werden dürfen.",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_TITLE:
        "[Nur Inspect App] Informationsdarstellung",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_NAME:
        "Die Namen Ihrer Projektelemente werden prominenter in der Inspect App dargestellt.",
    SECTION_INSPECT_APP_SETTINGS_INFORMATION_LAYOUT_CODE:
        "Die Kürzel Ihrer Projektelemente werden prominenter in der Inspect App dargestellt.",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_WORK_TITLE:
        "[Ausführendes Team] QS Protokolle mit Passwort unterschreiben",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_WORK_DESCRIPTION:
        "Als zusätzliche Absicherung müssen Nutzer des ausführenden Teams Ihre Identität durch Ihr Passwort bestätigen, wenn Sie QS Protokolle über die mobile App hochladen. Die App erfasst immer den Nutzernamen und Zeitpunkt, auch wenn diese Option nicht aktiviert ist.",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_INSPECTION_TITLE:
        "[Vorabnahme & Prüfendes Team] QS Protokolle mit Passwort unterschreiben",
    SECTION_INSPECT_APP_SETTINGS_ACTIVATE_SIGNATURE_INSPECTION_DESCRIPTION:
        "Als zusätzliche Absicherung müssen Nutzer des prüfenden Teams Ihre Identität durch Ihr Passwort bestätigen, wenn Sie QS Protokolle über die mobile App hochladen. Die App erfasst immer den Nutzernamen und Zeitpunkt, auch wenn diese Option nicht aktiviert ist.",

    SECTION_QUALITY_PRIVACY_TITLE: "Sichtbarkeit von Qualitätsinformation",
    SECTION_QUALITY_PRIVACY_ACTIVATE_LIMITED_NOTE_VISIBILITY_TITLE:
        "Eingeschränkte Meldungs-Sichtbarkeit",
    SECTION_QUALITY_PRIVACY_ACTIVATE_LIMITED_NOTE_VISIBILITY_DESCRIPTION:
        "Nutzer werden nur Meldungen sehen, die von ihrem Team hochgeladen wurden, für die ihr Team verantwortlich ist oder die an Aktivitäten hängen, für die ihr Team verantwortlich ist.",

    SECTION_EMAIL_NOTIFICATIONS_TITLE: "E-Mail Benachrichtigungen",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_DAILY_UPDATE_TITLE:
        "Tägliches Aktivitäts-Update",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_DAILY_UPDATE_DESCRIPTION:
        "Nutzer erhalten ein tägliches Update für ihr Team am Nachmittag, welches verfügbare, geprüfte, abgelehnte und Aktivitäten enthält, für die nachfolgende Teams bereits Fortschritt gemeldet haben.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_REALTIME_UPDATE_TITLE:
        "Echtzeit Aktivitäts-Update",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_REALTIME_UPDATE_DESCRIPTION:
        "Nutzer erhalten echtzeit Updates für ihr Team, welche verfügbare, geprüfte und abgelehnte Aktivitäten enthält.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_REMINDER_TITLE:
        "Wöchentliche Aktivitäts-Erinnerung",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_REMINDER_DESCRIPTION:
        "Nutzer erhalten eine wöchentliche Erinnerung für ihr Team am Montag, welches Aktivitäten enthält, für die nachfolgende Teams bereits Fortschritt gemeldet haben.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_LOOKAHEAD_TITLE:
        "Wöchentlicher Arbeitsplan",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_WEEKLY_LOOKAHEAD_DESCRIPTION:
        "Nutzer erhalten eine wöchentliche Zusammenfassung für ihr Team am Montag, welche für die kommenden 14 Tage geplante sowie hinter dem Zeitplan liegende Aktivitäten enthält.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_OBSTRUCTION_CONCERNING_MY_TEAM_TITLE:
        "Echtzeit Behinderungs-Update",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_OBSTRUCTION_CONCERNING_MY_TEAM_DESCRIPTION:
        "Nutzer erhalten echtzeit Updates bezüglich kürzlich erstellter Behinderungen, die ihr Team entweder beheben oder prüfen soll.",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_QUALITY_CONCERNING_MY_TEAM_TITLE:
        "Echtzeit Mangel-Update",
    SECTION_EMAIL_NOTIFICATIONS_ACTIVATE_QUALITY_CONCERNING_MY_TEAM_DESCRIPTION:
        "Nutzer erhalten echtzeit Updates bezüglich kürzlich erstellter Mängel, die ihr Team entweder beheben oder prüfen soll.",

    SECTION_NOTE_CONFIRMATION: "Mangelprüfungseinstellungen",
    OBSTRUCTION_CONFIRMATION_TITLE: "Behinderungen",
    OBSTRUCTION_CONFIRMATION_DESCRIPTION:
        "Wenn diese Option aktiviert ist, müssen Nutzer beim Erstellen einer Behinderung ein Abnehmendes Team hinzufügen. Ansonsten bleibt dies optional.",
    OBSTRUCTION_DEFAULT_TEAM:
        "Vorausgewähltes Abnehmendes Team für Behinderungen",
    QUALITY_ISSUE_CONFIRMATION_TITLE: "Mängel",
    QUALITY_ISSUE_CONFIRMATION_DESCRIPTION:
        "Wenn diese Option aktiviert ist, müssen Nutzer beim Erstellen eines Mangels ein Abnehmendes Team hinzufügen. Ansonsten bleibt dies optional.",
    QUALITY_ISSUE_DEFAULT_TEAM: "Vorausgewähltes Abnehmendes Team für Mängel",

    INFO_USER_ADD_SUCCESS_TITLE: "{{users}} erfolgreich hinzugefügt",
    INFO_USER_MOVED_SUCCESS_TITLE: "Nutzer erfolgreich verschoben",
    INFO_USER_REMOVE_SUCCESS_TITLE: "Nutzer aus dem Projekt entfernt",
    INFO_NO_MEMBER_1_TITLE: "Derzeit gibt es keine",
    INFO_NO_MEMBER_2_TITLE: "in Ihrem Projekt.",
    CONFIRM_DELETE_YOURSELF_MESSAGE:
        "Möchten sie sich selbst vom Projekt löschen? Dies kann nicht rückgängig gemacht werden.",
    ACTION_UNDO: "Rückgängig",
    INFO_USER_NOT_FOUND: "Nutzer {{user}} nicht gefunden",
    INFO_LOADING_ADD_MESSAGE: "Nutzer wird hinzugefügt",
    INFO_LOADING_ADD_MESSAGE_PLURAL: "Nutzer werden hinzugefügt",
    INFO_LOADING_REMOVE_MESSAGE: "Nutzer wird gelöscht",
    INFO_LOADING_REMOVE_MESSAGE_PLURAL: "Nutzer werden gelöscht",
    INFO_LOADING_MOVE_MESSAGE: "Nutzer wird verschoben",
    INFO_LOADING_MOVE_MESSAGE_PLURAL: "Nutzer werden verschoben",
    INFO_LOADING_RESTORE_MESSAGE: "Nutzer wird wiederhergestellt",
    INFO_LOADING_RESTORE_MESSAGE_PLURAL: "Nutzer werden wiederhergestellt",

    _SUN: "So",
    _MON: "Mo",
    _TUE: "Di",
    _WED: "Mi",
    _THU: "Do",
    _FRI: "Fr",
    _SAT: "Sa",

    SECTION_PROJECT_SETTINGS_PAGE_TITLE: "Projekteinstellungen",
    SECTION_PROJECT_CALENDAR_TITLE: "Projektkalender",

    SECTION_SCHEDULING_MODE_TITLE: "Terminplan-Modus",
    SECTION_PROJECT_CURRENCY_TITLE: "Projektwährung",
    SECTION_SCHEDULING_MODE_SELECTION_DATES_HEADING: "Einzeln geplant",
    SECTION_SCHEDULING_MODE_SELECTION_DATES_OPTION:
        "Dieser Modus berechnet den Terminplan Ihres Projekts ohne dabei Abhängigkeiten zwischen verschiedenen Projektelementen zu berücksichtigen.",
    SECTION_SCHEDULING_MODE_SELECTION_EDGES_HEADING: "Zusammenhängend geplant",
    SECTION_SCHEDULING_MODE_SELECTION_EDGES_OPTION:
        "Dieser Modus berechnet den Terminplan Ihres Projekts basierend auf den Abhängigkeiten zwischen Ihren Projektelementen. Die Ergebnisse können stark von den benutzer-definierten Start- und Enddaten Ihrer Projektelemente abweichen.",
    SECTION_SCHEDULING_MODE_SELECTION_INFO_TOOLTIP:
        "Keine Sorge! Beim Wechsel zwischen Einzeln- und Zusammenhängend geplanter Berechnung gehen niemals Daten verloren. Sollten Sie mit den Ergebnissen des anderen Modus nicht zufrieden sein, können Sie jederzeit wieder zurückwechseln.",

    ERROR_NO_WORKDAYS_SELECTED_MESSAGE:
        "Sie müssen mindestens einen Arbeitstag auswählen",
    ERROR_NO_WORKHOURS_SELECTED_MESSAGE:
        "Bitte wählen Sie Ihre tägliche Arbeitszeit aus",
    ERROR_NO_TIMEZONE_SELECTED_MESSAGE: "Bitte wählen Sie eine Zeitzone aus",
    ERROR_INVALID_TIMEZONE_SELECTED_MESSAGE:
        "Bitte wählen Sie eine gültige Zeitzone aus",
    DELETE_PROJECT: "Projekt löschen",
    SECTION_PROJECT_INFOS_TITLE: "Projektinformationen",
    SECTION_SELECT_WORKING_DAYS_TITLE: "Arbeitstage",
    SECTION_SELECT_WORKING_HOURS_TITLE:
        "Bitte wählen Sie Ihre tägliche Arbeitszeit aus",
    INFO_CALENDAR_EDIT_SUCCESS_TITLE: "Kalender erfolgreich aktualisiert",
    INFO_CALENDAR_EDIT_LOADING_TITLE: "Kalender wird aktualisiert...",
    CONFIRM_DELETE_PROJECT_MESSAGE:
        "Möchten Sie dieses Projekt wirklich löschen?",
    INFO_PROJECT_EDIT_SUCCESS_TITLE: "Erfolgreich bearbeitet",
    INFO_PROJECT_EDIT_LOADING_TITLE: "Projekt wird aktualisiert...",
    INPUT_START_DESCRIPTION: "Anfang",
    INPUT_END_DESCRIPTION: "Ende",
    SECTION_SELECT_FIRST_PERIOD_TITLE: "Erste Arbeitszeit",
    SECTION_SELECT_SECOND_PERIOD_TITLE: "Zweite Arbeitszeit",
    SECTION_EXCEPTION_DAYS_TITLE: "Freie Tage",
    SECTION_EXCEPTION_DAYS_INFO:
        "Bitte speichern Sie alle Tage, an denen Ihr Prohjektteam nicht arbeiten wird, z.B. Feiertage.",
    INPUT_DAY_LABEL_DESCRIPTION: "Anlass",
    ERROR_DUPLICATED_DATES: "Sie haben den gleichen Tag mehrfach gewählt.",
    ACTION_ADD_MORE_EXCEPTION_DAY: "Tag hinzufügen",

    //Edit Project
    INFO_PROJECT_REMOVE_SUCCESS_TITLE: "Projekt erfolgreich gelöscht",
    ACTION_DELETE_PROJECT_TITLE: "Projekt löschen",
    ACTION_DELETE_PROJECT_CONFIRM_TITLE: "Wirklich löschen?",
    SECTION_EDIT_TITLE: "Bearbeiten",

    // edit Component
    INFO_COMPONENT_DATE_CHANGE_SUCCESS_TITLE: "{{name}} erfolgreich geändert",

    //////
    // Start of template list page
    //

    // subbar actions
    ACTION_CLONE_TEMPLATE: "Existierende Vorlage kopieren",
    ACTION_CREATE_TEMPLATE: "Neue Vorlage erstellen",

    // group by menu
    ACTION_UNGROUPED: "Ungruppiert",
    ACTION_CREATOR: "Verfasser",
    ACTION_CATEGORY: "Kategorie",
    ACTION_CODE: "Code",
    ACTION_REVISION: "Vorlagen-Version",
    ACTION_ACTIVITIES: "Anz. Aktivitäten",
    //
    // End of template list page

    // TEMPLATE
    TEMPLATE_GROUP_INPUT_HOURS_OF_WORK_TITLE: "Arbeitszeit",
    TEMPLATE_GROUP_TOOLTIP_DURATION_TITLE: "Dauer: ",
    TEMPLATE_GROUP_DURATION_TOOLTIP_TEXT_BEFORE:
        "Die Dauer repräsentiert den kritischen Pfad Ihres Workflows. Sowohl",
    TEMPLATE_GROUP_DURATION_TOOLTIP_LINK_TEXT: "Arbeitsstunden und -tage",
    TEMPLATE_GROUP_DURATION_TOOLTIP_TEXT_AFTER:
        "als auch Stunden und Tage werden dabei berücksichtigt.",
    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_TEXT:
        "Arbeitstage und -Stunden werden basieren auf Ihrem Projektkalender eingeplant. Tage und Stunden nutzen einen 24-Stunden-Kalender.",
    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_DISABLED_TEXT:
        "Definieren Sie geplante Dauern als Teil Ihrer Workflow-Vorlagen, um Sablonos Terminplanungsfunktionen nutzen zu können! Kontaktieren Sie uns gerne, um Zugriff zu diesen Funktionen zu erhalten.",
    CONFIRM_TEAM_DISABLED_TOOLTIP_TEXT:
        "Weisen Sie Ihren Aktivitäten prüfende Teams zu, damit diese im 4-Augenprinzip geprüft oder sogar abgelehnt werden können. Alles natürlich transparent dokumentiert in Ihrem Sablono Audit-Trail! Kontaktieren Sie uns gerne, um Zugriff zu diesen Funktionen zu erhalten.",
    TEMPLATE_ACTIVITY_EMPTY_CHECKLIST_TOOLTIP_TEXT:
        "QS Protokolle, die noch keine Kontrollpunkte beinhalten, können nicht angehängt werden. Bitte fügen Sie zunächst Punkte zur QS Protokollvorlage hinzu.",
    TEMPLATE_ACTIVITY_CHECKLIST_DISABLED_TOOLTIP_TEXT:
        "Weisen Sie Ihren Aktivitäten QS Protokolle zu und stellen Sie sicher, dass qualitätsrelevante Informationen bei der Fortschrittsmeldung von der Baustelle miterhoben werden! Kontaktieren Sie uns gerne, um Zugriff zu diesen Funktionen zu erhalten.",
    TEMPLATE_GROUP_HOURS_OF_WORK_TOOLTIP_TITLE: "Arbeitszeit: ",
    TEMPLATE_GROUP_HOURS_OF_WORK_TOOLTIP_TEXT:
        "Dieser Wert ist die Summe aller Aktivitäts-Dauern dieser Gruppe.",

    TEMPLATE_ACTIVITY_DURATION_TOOLTIP_LINK_TEXT:
        "Arbeitstage: {{days}}<br>Arbeitsstunden: {{times}}",
    SECTION_CREATE_TEMPLATE_TITLE: "Eine neue Vorlage erstellen",
    INPUT_TEMPLATE_NAME_TITLE: "Name",
    INPUT_TEMPLATE_CODE_TITLE: "Code",
    INPUT_UNIT_TITLE: "Zeiteinheit",
    INPUT_ASSIGNED_TEAM_TITLE: "Ausführendes Team",
    INPUT_REVIEW_TEAMS_TITLE: "Vorabnahme Team",
    INPUT_CONFIRM_TEAM_TITLE: "Prüfendes Team",
    _NOTES_CONFIRMATION_TEAM: "Abnehmendes Team",
    INPUT_OPTION_NO_CONFIRMATION_REQUIRED:
        "Keine gesonderte Prüfung notwendig.",
    INPUT_ASSIGNED_CHECKLIST_TITLE: "Angehängtes QS Protokoll",
    INPUT_ASSIGNED_CHECKLIST_NONE: "Kein QS Protokoll angehängt",
    INPUT_SELECT_ASSIGNED_TEAM_PLACEHOLDER: "Team auswählen",
    INPUT_SELECT_MIXED_TEAM_ASSIGNMENT_OPTION: "Mehrere Teams zugewiesen",
    INPUT_SELECT_ADD_NEW_TEAM_OPTION: "Neues Team erstellen",
    INPUT_TEMPLATE_DESCRIPTION_TITLE: "Beschreibung",
    INPUT_TEMPLATE_CATEGORY_TITLE: "Kategorie",
    INPUT_TEMPLATE_ACTIVITY_NAME_TITLE: "Aktivitätsname",
    INPUT_TEMPLATE_STATE_NAME_TITLE: "Zustandsname",
    INPUT_SELECT_PREDECESSOR_TITLE: "Weiteren Vorgänger auswählen",
    INPUT_SELECT_SUCCESSOR_TITLE: "Weiteren Nachfolger auswählen",
    INFO_TEMPLATE_ADD_SUCCESS_TITLE: "Vorlage erfolgreich erstellt.",
    INFO_TEMPLATE_EDIT_SUCCESS_TITLE: "Die Vorlage wurde erfolgreich editiert",
    INFO_TEMPLATE_REMOVE_MESSAGE:
        "{{success}} Vorlage{{(success == 1) ? '' : 'n'}} gelöscht. {{errors}} Fehler",
    INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATES_MESSAGE: "Suche nach Vorlage..",
    INFO_PLACEHOLDER_SEARCH_BAR_TEMPLATE_CONTENT_MESSAGE:
        "Durchsuchen Sie die Vorlage..",
    ACTION_GROUP_BY: "Gruppiere nach",
    ACTION_GROUP_BY_TYPE: "Gruppiere nach Typ",
    ACTION_GROUP_BY_CREATOR: "Gruppiere nach Verfasser",
    ACTION_GROUP_BY_CREATION_DATE: "Gruppiere nach Datum",
    ACTION_KEY: "Kürzel",
    ACTION_NAME: "Name",
    ACTION_DESCRIPTION: "Beschreibung",
    ACTION_TEXT: "Text",
    ACTION_TYPE: "Typ",
    ACTION_SAVE_TEMPLATE: "Workflow-Vorlage speichern",
    ACTION_DATE: "Datum",
    INFO_NO_TEMPLATES_YET_TITLE:
        "Keine Workflow-Vorlage für dieses Projekt angelegt",
    INFO_NO_TEMPLATES_YET_TEXT:
        "Workflow-Vorlagen können genutzt werden, um detaillierte Informationen über Ihre Bauabläufe zu speichern. Sie beinhalten Aktivitäten, die in Gruppen strukturiert und durch Abhängigkeiten verbunden werden können. <br /><img src='images/attach-template_de_slow.gif'/><br /> Nachdem Sie eine Workflow-Vorlage angelegt haben, weisen Sie diese Ihren Projektelementen zu und beginnen Sie Aktivitäten abzuhaken.",
    INFO_NO_TEMPLATES_YET_BUTTON_LEFT: "Neue erstellen",
    INFO_NO_TEMPLATES_SEARCH_1_MESSAGE: "Ihre Suchanfrage nach",
    INFO_NO_TEMPLATES_SEARCH_2_MESSAGE: "ergab keine Treffer.",
    INFO_NO_ACTIVITIES_IN_GROUP:
        "Dieser Gruppe wurden keine Aktivitäten zur Fortschrittsdokumentation zugeteilt.",
    INFO_NO_WORKFLOW_ASSIGNED_YET_TITLE:
        "Bitte weisen Sie eine Workflow-Vorlage zu",
    INFO_NO_WORKFLOW_ASSIGNED_YET_MESSAGE:
        "Um den Status von Aktivitäten verfolgen zu können, müssen Sie diesem Projektelement als nächstes eine Workflow-Vorlage zuweisen.",
    INFO_EMPTY_WORKFLOW_ASSIGNED_TITLE: "Workflow-Vorlage ohne Aktivitäten",
    INFO_EMPTY_WORKFLOW_ASSIGNED_MESSAGE:
        "Ihre zugewiesene Workflow-Vorlage enthält noch keine Aktivitäten zur Fortschrittserfassung. Erweiten Sie ihre Vorlage im Vorlageneditor.",
    SECTION_NO_GROUP_ELEMENT_TITLE: "Kein ",
    ACTION_ADD_TEMPLATE_DESCRIPTION: "Vorlage hinzufügen",
    SECTION_ALL_THE_TEMPLATES_1_TITLE: "Alle",
    SECTION_ALL_THE_TEMPLATES_2_TITLE: "Vorlagen",
    CONFIRM_DELETE_TEMPLATES_MESSAGE:
        "{{value}} Vorlage{{(value == 1) ? '' : 'n'}} wirklich löschen?",
    ALERT_DELETE_TEMPLATES_NOT_ALLOWED_TITLE: "Nicht möglich",
    ALERT_DELETE_TEMPLATES_NOT_ALLOWED_MESSAGE:
        "Workflow-Vorlagen können nicht gelöscht werden, wenn sie Projektelementen zugewiesen sind. Die Workflow-Vorlage/n, die Sie zu löschen versuchen, werden von {{value}} Projektelement{{(value > 1) ? 'en' : ''}} verwendet. \nBitte weisen Sie diesen Projektelementen eine neue Workflow-Vorlage zu um fortfahren zu können.",
    SECTION_GROUPS: "Gruppen",
    SECTION_ACTIVITIES: "Aktivitäten",
    INFO_ACTIVITY_TITLE: "Aktivität",
    ACTION_ADD_GROUP: "Eine neue Gruppe zu {{name}} hinzufügen",
    ACTION_ADD_ACTIVITY: "Eine neue Aktivität zu {{name}} hinzufügen",
    ACTION_DELETE_GROUP: "Gruppe löschen",
    ACTION_DELETE_ACTIVITY: "Aktivität löschen",
    INFO_TEMPLATE_NO_CHILDREN:
        "Diese Vorlage enthält bisher keine Gruppen oder Aktivitäten.",
    ACTION_TRANSFORM_ACTIVITY: "Aktivität zur Gruppe umwandeln",
    ACTION_DISCARD_CHANGES: "Änderungen zurücksetzen",
    ACTION_TEMPLATE_ADD_CONTENT: "Im Editor öffnen",
    ACTION_TEMPLATE_DELETE_NOTE: "Meldung entfernen",
    CONFIRM_DELETE_NOTE_MESSAGE:
        "Sind Sie sicher, dass Sie diese Meldung entfernen wollen?",
    SECTION_TEMPLATE_EDITOR: "Vorlage bearbeiten",
    CONFIRM_TEAM_OVERWRITE_CONFIRM_TITLE: "Teamzuweisungen überschreiben?",
    CONFIRM_TEAM_OVERWRITE_CONFIRM_TEXT:
        "Wenn Sie dieser Gruppe ein ausführendes Team zuweisen, überschreiben Sie die bereits für die darunterliegenden Aktivitäten zugewiesenen Teams. Sind Sie sicher, dass Sie fortfahren möchten?",
    CONFIRM_DELETE_GROUP_MESSAGE:
        "Möchten Sie die Gruppe {{name}} wirklich löschen? Alle Elemente der Gruppe werden ebenfalls gelöscht.",
    CONFIRM_DELETE_ACTIVITY_MESSAGE:
        "Möchten Sie die Aktivität {{name}} wirklich löschen? Bitte beachten Sie, dass nach der Neuzuweisung der Workflow-Vorlage der Fortschritt, die Notizen und die Bilder, die mit dieser Aktivität verbunden sind, für alle Ihre Projektelemente nicht mehr verfügbar sind. Diese Aktion kann nicht rückgängig gemacht werden.",
    INFO_TEMPLATE_NODE_REMOVE_SUCCESS_TITLE: "{{ name }} erfolgreich gelöscht.",
    CONFIRM_DISCARD_CHANGES_MESSAGE:
        "Sind Sie sicher, dass Sie alle Änderungen verwerfen und die Vorlage erneut vom Server laden möchten?",
    INFO_TRANSFORM_ACTIVITY:
        "Aktivitäten können keine Gruppen oder andere Aktivitäten untergeordnet werden. Möchten Sie dies tun, müssen Sie die Aktivität zunächst in eine Gruppe umwandeln.",
    DIALOG_TRANSFORM_ACTIVITY_OPTION:
        "Bitte wählen Sie eine der folgenden Vorgehensweisen",
    SELECTION_TRANSFORM_ACTIVITY_KEEP:
        "Eine neue Gruppe erstellen und die Aktivität in neue Gruppe einordnen.",
    SELECTION_TRANSFORM_ACTIVITY_DISCARD:
        "Eine neue Gruppe erstellen und diese Aktivität löschen.",
    INFO_CREATE_NEW_GROUP: "Eine neue Gruppe erstellen...",
    INFO_TEMPLATE_SAVED_SUCCESSFULL: "Vorlage erfolgreich gespeichert",
    INFO_CANNOT_DRAG_INTO_ACTIVTY_MESSAGE:
        "Sie können zu einer Aktivität keine anderen Aktitväten oder Gruppen zuordnen",
    INFO_CANNOT_DRAG_INTO_CHILD_MESSAGE:
        "Sie können diese Gruppe nicht in eine Ihrer Untergruppen verschieben",
    SECTION_PREDECESSOR_TITLE: "Vorgänger",
    SECTION_SUCCESSOR_TITLE: "Nachfolger",
    SECTION_CURRENT_TITLE: "Ausgewählte Aktivität",
    ACTION_EXPAND_ALL: "Alles ausklappen",
    ACTION_COLLAPSE_ALL: "Alles zusammenklappen",
    INFO_CIRCLE_PREDECESSOR_MESSAGE:
        "Diese Aktivität wurde im Workflow bereits als Nachfolger ausgewählt. Um Workflow-Zyklen zu vermeiden kann sie nicht zusätzlich als Vorgänger ausgewählt werden.",
    INFO_CIRCLE_SUCCESSOR_MESSAGE:
        "Diese Aktivität wurde im Workflow bereits als Vorgänger ausgewählt. Um Workflow-Zyklen zu vermeiden kann sie nicht zusätzlich als Nachfolger ausgewählt werden.",
    INFO_SAVING_MESSAGE: "Änderungen veröffentlichen...",
    INFO_DISCARDING_MESSAGE: "Verwerfen...",
    LABEL_EDIT_ACTIVITY: "Aktivität bearbeiten",
    INFO_LAST_CHANGES_TIME: "Änderungen auf diesem Gerät gespeichert",
    INFO_LAST_CHANGES_TIME_PUBLISH: "Jetzt veröffentlichen",
    SELECT_EXIT_EDITOR_TITLE: "Editor verlassen",
    SELECT_EXIT_EDITOR_MESSAGE:
        "Sie haben Ihre Änderungen noch nicht veröffentlicht. Wie möchten Sie fortfahren?",
    SELECT_EXIT_EDITOR_DISMISS: "Auf dieser Seite bleiben",
    SECTION_CHANGE_PARENT_DIALOG_TITLE: "Wählen Sie eine Gruppe",
    ACTION_MOVE_TO_ANOTHER_PARENT: "In andere Gruppe verschieben",
    ACTION_PUBLISH_AND_UPDATE_DELIVERABLES: "Speichern und zuweisen",
    INFO_UPDATING_DELIVERABLES_MESSAGE:
        "Projektelemente werden aktualisiert...",
    INFO_UPDATING_DELIVERABLES_SUCCESSFUL:
        "Projektelemente erfolgreich aktualisiert",
    INFO_UPDATING_DELIVERABLES_SUCCESSFUL_N:
        "{{deliverables}} Projektelement{{(deliverables ===0 || deliverables > 1)  ? 'en' : ''}} erfolgreich aktualisiert",
    INFO_PUBLISHING_MESSAGE: "Änderungen werden veröffentlicht...",
    INFO_TEMPLATE_PUBLISHED_SUCCESSFUL: "Vorlage erfolgreich veröffentlicht",
    TEMPLATE_EDITOR_NO_DATA_TITLE:
        "Keine {{(type=='GROUP')?'Gruppe':'Aktivität'}} hinzugefügt",
    TEMPLATE_EDITOR_NO_DATA_GROUP_TEXT:
        "Eine Gruppe beinhaltet mehrere Aktivitäten und kann zur Strukturierung Ihrer Vorlage verwendet werden. Fügen Sie im oberen Feld eine bestehende Gruppe hinzu oder erstellen Sie eine neue.",
    TEMPLATE_EDITOR_NO_DATA_ACTIVITY_TEXT:
        "Aktivitäten bestehen immer aus einer Aktivität sowie einem Zielzustand (z.B. liefern - geliefert). Fügen Sie im oberen Feld eine bestehende Aktivität hinzu oder erstellen Sie eine neue.",
    TEMPLATE_EDITOR_NO_PREDECESSOR_TITLE: "Kein Vorgänger ausgewählt",
    TEMPLATE_EDITOR_NO_PREDECESSOR_TEXT:
        "Aktivitäten, die vor der angezeigten ausgeführt werden müssen, heißen Vorgänger. Wählen Sie im oberen Feld eine Aktivität Ihrer Workflow-Vorlage als Vorgänger aus.",
    TEMPLATE_EDITOR_NO_SUCCESSOR_TITLE: "Kein Nachfolger ausgewählt",
    TEMPLATE_EDITOR_NO_SUCCESSOR_TEXT:
        "Aktivitäten, die erst nach der angezeigten ausgeführt werden können, heißen Nachfolger. Wählen Sie im oberen Feld eine Aktivität Ihrer Workflow-Vorlage als Nachfolger aus.",
    INFO_NO_ADDITIONAL_EDGE_POSSIBLE_IN_TEMPLATE_PREDECESSOR:
        "Alle bisher angelegten Aktivitäten würden bei erneuter Nutzung zu zirkulären Workflows führen. Legen Sie weitere Aktivitäten an, um weitere Vorgänger zu definieren.",
    INFO_NO_ADDITIONAL_EDGE_POSSIBLE_IN_TEMPLATE_SUCCESSOR:
        "Alle bisher angelegten Aktivitäten würden bei erneuter Nutzung zu zirkulären Workflows führen. Legen Sie weitere Aktivitäten an, um weitere Nachfolger zu definieren.",
    EDIT_TEMPLATE_ACTIVITY_DESCRIPTION_PLACEHOLDER:
        "Geben Sie Notizen, Beschreibungen oder Übersetzungen an, die Sie oder Ihre Partner sehen möchten.",
    INFO_PROGRESS_CHANGE_DISABLED_TOOLTIP:
        "Sie können den Status dieser Aktivität nicht verändern, da Sie nicht verantwortlich sind.",

    ACTION_EXPORT_GRAPHML_FILE: "Als .graphml Datei exportieren",

    // TEMPLATE EDITOR
    _LABOUR: "Arbeitskräfte",
    _LABOUR_UNIT: "Mann",
    LABOUR_IN_UNIT: "{{ amount }} Mann",

    // NOTE TEMPLATE PAGE
    INFO_NOTE_TEMPLATE_NO_DATA_CARD_TITLE:
        "Erstellen Sie Ihre erste Meldungsvorlage",
    INFO_NOTE_TEMPLATE_NO_DATA_CARD_TEXT:
        "Mit Meldungsvorlagen sparen Sie Zeit bei der Mängelerfassung. Erstellen Sie Standardtexte, die dann auf der Baustelle bei der Erstellung von Meldungen genutzt werden können.",
    INFO_NO_DATA_DUE_TO_FILTER_CONDITIONS:
        "Ihre eingestellten Filter- und Suchkriterien passen zu keiner vorhandenen Meldungs-Vorlage.",
    ACTION_NOTE_TEMPLATE_NO_DATA_BUTTON: "Erstellen sie ihre erste Vorlage",
    NOTE_TEMPLATE_TITLE: "Meldungstyp",
    CONFIG_CARD_TITLE: "Filter",
    INFO_PLACEHOLDER_SEARCH_BAR_NOTE_TEMPLATES_MESSAGE:
        "Suche nach Meldungsvorlagen..",
    INFO_NOTE_TEMPLATE_REMOVE_MESSAGE:
        "{{success}} Meldungs-Vorlage{{(success == 1) ? '' : 'n'}} gelöscht. {{errors > 0 ? errors + ' Fehler' : '' }}",
    CONFIRM_DELETE_NOTE_TEMPLATE_MESSAGE:
        "Wollen Sie {{value}} Meldungs-Vorlage{{(value == 1) ? '' : 'n'}} löschen?",
    INFO_NOTE_TEMPLATE_LOADING_REMOVE_MESSAGE: "Lösche Meldungsvorlagen",
    INPUT_SELECT_TYPE: "Wählen Sie einen Meldungstypen",
    SECTION_CREATE_NOTE_TEMPLATE_TITLE: "Eine neue Meldungs-Vorlage erstellen",
    INPUT_CODE_TITLE: "Kürzel",
    INPUT_NOTE_TEMPLATE_DESCRIPTION_TITLE: "Text der Meldungs-Vorlage",

    //PROJECT CLONING
    INFO_ANY_PROJECT_MESSAGE: "Es ist keine Projekt verfügbar.",
    INFO_SEARCH_NO_RESULT_PREFIX: "Ihre Suche nach",
    INFO_SEARCH_NO_RESULT_PROJECT: "passt zu keinem Ihrer Projekte.",
    SECTION_PROJECT_TO_BE_CLONED_TITLE:
        "Bitte wählen Sie ein Projekt zum Kopieren aus",
    SECTION_ENTER_PROJECT_DETAILS_TITLE:
        "Bitte geben Sie die Projektdetails ein.",
    INFO_COPYING_PROJECT_MESSAGE: "Projekt wird kopiert...",
    INFO_PROJECT_CLONED_SUCCESSFULLY_MESSAGE: "Projekt erfolgreich kopiert",
    INPUT_SEARCH_FOR_A_PROJECT: "Nach einem Projekt suchen",

    //PROJECT SELECTION
    INFO_PLACEHOLDER_SEARCH_BAR_PROJECT_SELECTION_MESSAGE:
        "Suche nach Projekt..",

    // sb-subbar
    INFO_ELEMENTS_SELECTED: "ausgewählt",

    DIALOG_CHANGE_DATES_TITLE: "Projektelementzeitraum bearbeiten",
    DIALOG_DATE_CHANGE_VALUE_NOT_DATE: "Der eingegebene Wert ist kein Datum.",
    DIALOG_CHANGE_DATE_WARNING_MESSAGE:
        "Die ausgewählten Projektelemente enthalten unterschiedliche Start- oder Solltermine. Hier dargestellt werden der früheste Start- und der späteste Solltermin. Beachten Sie, dass alle ausgewählten Projektelemente verschoben werden, sollten Sie speichern.",
    DIALOG_CHANGE_DATE_ALL_SELECTED_WARNING_MESSAGE:
        "Vorsicht! Alle ausgewählten Projektelemente werden durch die Terminänderungen betroffen sein. Vorhandene Datumswerte werden überschrieben.",

    // MAINTENANCE CARD
    INFO_MAINTENANCE_CARD_TITLE_PLANNED: "Wartungsarbeiten",
    INFO_MAINTENANCE_CARD_SUB_TITLE_PLANNED:
        "Aufgrund von Updates und Wartungsarbeiten ist unser System kurzfristig nicht erreichbar.",
    INFO_MAINTENANCE_CARD_TEXT_STARTER_PLANNED:
        "Wartungsfenster sind Freitag 22Uhr (MEZ) bis Samstag 2Uhr (MEZ), Samstag 22Uhr (MEZ) bis Sonntag 2Uhr (MEZ) und Sonntag 22Uhr (MEZ) bis Montag 2Uhr (MEZ)",
    INFO_MAINTENANCE_CARD_TEXT_PLANNED:
        "Bei Fragen wenden Sie sich bitte über <a href='mailto:support@sablono.com'>support@sablono.com</a> an unseren Support.",

    INFO_MAINTENANCE_CARD_TITLE_UNKNOWN: "Netzwerk Timeout",
    INFO_MAINTENANCE_CARD_SUB_TITLE_UNKNOWN:
        "Es scheint als gäbe es aktuell keine oder eine sehr langsame Verbindung zum Sablono Server...",
    INFO_NOTE_TYPE_EDIT_TEMPORARILY_UNAVAILABLE:
        "Änderungen der Meldungs-Art funktionieren derzeit nicht. Wir arbeiten hart daran, diese Funktion so schnell wie möglich wiederherzustellen.",
    INFO_MAINTENANCE_CARD_TEXT_STARTER_UNKNOWN:
        "Bitte stellen Sie sicher, dass Sie eine stabile Netzwerkverbindung haben und aktualisieren Sie die Seite.",
    INFO_MAINTENANCE_CARD_TEXT_UNKNOWN:
        "Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte unter <a href='mailto:support@sablono.com'>support@sablono.com</a>",
    INFO_MAINTENANCE_CARD_BUTTON_UNKNOWN: "Support Center",

    // AUTO SIGN UP
    SIGNUP_TITLE_FREEMIUM: "Erstellen Sie Ihr kostenloses Sablono Track-Konto",
    SIGNUP_TITLE_FULL: "Erstellen Sie Ihr Sablono-Konto",
    SIGNUP_SUCCESS_TITLE:
        "Bitte verifizieren Sie Ihre E-Mail über Ihren Posteingang innerhalb von 24 Stunden...",
    SIGNUP_SUCCESS_HUBSPOT_TITLE:
        "Sie möchten das Beste aus Ihrer Erfahrung mit der Plattform herauszuholen? Sprechen Sie mit einem unserer Produktexperten!",
    SIGNUP_SUCCESS_HUBSPOT_TEXT:
        "Wir helfen Ihnen gerne, Ihre Ziele mit der Plattform zu erreichen. Unser engagiertes Team ist für Sie da.",
    SIGNUP_SUCCESS_TEXT_WAIT_BUTTON: "klicken Sie hier.",
    SIGNUP_SUCCESS_TEXT_MAIL_SENT:
        "Die E-Mail wurde zum zweiten Mal gesendet. Sollte Sie die E-Mail nicht in den nächsten Minuten erreichen, kontaktieren Sie bitte unseren Support unter <a href='mailto:support@sablono.com'>support@sablono.com</a> or <a href='tel:0049030609807440'>+49 030 609807440</a>... Wir helfen Ihnen gerne!",

    SIGNUP_GENERIC_ERROR_TITLE: "Die Registrierung ist fehlgeschlagen",
    SIGNUP_GENERIC_ERROR_TEXT:
        "Es gibt unerwartete Probleme bei der Registrierung. Bitte kontaktieren Sie unseren Support unter <a href='mailto:support@sablono.com'>support@sablono.com</a> or <a href='tel:0049030609807440'>+49 030 609807440</a>... Wir helfen Ihnen gerne!",

    SIGNUP_FIRST_ERROR_TITLE: "Ups...",
    SIGNUP_FIRST_ERROR_TEXT:
        "Leider ist bei der Datenübertragung ein Fehler aufgetreten. Versuchen Sie es bitte noch einmal.",
    SIGNUP_FIRST_ERROR_BUTTON: "Zurück zur Registrierung",

    SIGNUP_ERROR_EMAIL_ALREADY_USED_TITLE: "Bereits vergeben",
    SIGNUP_ERROR_EMAIL_ALREADY_USED_TEXT:
        "Die von Ihnen angegebene E-Mail-Adresse wird bereits für einen Nutzer verwendet.",
    SIGNUP_ERROR_EMAIL_ALREADY_USED_BUTTON: "Andere E-Mail angeben",

    SIGNUP_ERROR_TITLE: "Es liegt ein Problem vor",
    SIGNUP_ERROR_TEXT:
        "Wir haben momentan ein unbekanntes Problem mit dem Registrierungsprozess. Bitte kontaktieren Sie unseren Support unter <a href='mailto:support@sablono.com'>support@sablono.com</a> or <a href='tel:0049030609807440'>+49 030 609807440</a>... Wir helfen Ihnen gerne!",

    SIGNUP_INFO_VERIFY_TITLE: "Wählen Sie ein Passwort",
    SIGNUP_INFO_VERIFY_TEXT:
        "Bitte wählen Sie abschließend ein sicheres Passwort bevor Sie Ihre Arbeit mit Sablono beginnen können.",
    SIGNUP_SUCCESS_VERIFY_TITLE: "Registrierung erfolgreich abgeschlossen",
    SIGNUP_SUCCESS_VERIFY_TEXT:
        "Gratulation! Verpassen Sie nicht unseren Sablono Erste-Schritte-Leitfaden unter <a target='_blank' href='http://support.sablono.com/deutsche-tutorials'>support.sablono.com</a>, falls Sie Fragen zur Nutzung der Plattform haben.",
    SIGNUP_SUCCESS_VERIFY_BUTTON: "Zum Support Center",
    ACTION_SIGNUP_VERIFY_TEXT: "Aktivieren",

    SIGNUP_ERROR_VERIFY_FAILED_TITLE: "Ups...",
    SIGNUP_ERROR_VERIFY_FAILED_TEXT:
        "Die Freischaltung ist fehlgeschlagen. Verwenden Sie den Link bitte erneut.",

    SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TITLE: "Ups...",
    SIGNUP_ERROR_VERIFY_TOKEN_INVALID_TEXT:
        "Der verwendete Link ist nicht gültig. Das kann verschiedene Gründe haben.",

    SIGNUP_EULA:
        " Ich habe die <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_DE.pdf' target='_blank'>Nutzungsvereinbarung und Lizenzbestimmungen gegenüber Endanwendern</a> der Sablono GmbH gelesen und akzeptiere diese.*",
    SIGNUP_DATA_PRIVACY:
        "Ich bin damit einverstanden, dass Sablono die von mir eingegebenen Daten auch in sogenannte Drittstaaten übermittelt (Art. 49 Abs. 1 lit. a) DS-GVO). Dabei bin ich mir bewusst, dass in manchen Drittstaaten (wie z.B. den USA) ein Zugriff durch Kontroll- und / oder Überwachungsbehörden nicht ausgeschlossen werden kann. Hiergegen stehen mir ggfs. weder die Geltendmachung von Betroffenenrechten noch die Anrufung von Gerichten offen. Die Übermittlung erfolgt sowohl zu Zwecken der Vertragserfüllung als auch zur werblichen Kontaktaufnahme durch Sablono. Meine Einwilligung ist frei widerruflich.*<br><br>Auf die <a href='https://www.sablono.com/de/datenschutz' target='_blank'>Datenschutzerklärung</a> wird hingewiesen Bitte beachten Sie dort insbesondere unseren Hinweis zur Übermittlung in Drittstaaten.",
    SIGNUP_MARKETING_CONSENT:
        "Ich möchte zukünftig zu Produkten von Sablono per E-Mail und telefonisch informiert werden. Meine Einwilligung ist frei widerruflich.*",

    //Visualization page
    SECTION_VISUALIZATION_PAGE_TITLE: "Fortschrittsvisualisierung",
    DIALOG_NO_MATCH_SVG_TITLE: "Keinen passenden Fortschritt gefunden",
    DIALOG_NO_MATCH_SVG_CONTENT:
        "Wir konnten keinen passenden Fortschritt Ihres Sablono-Projekts für diese Datei finden. Bitte prüfen Sie, ob die Projektelement-Codes Ihrer Datei mit dem Sablono-Projekt übereinstimmen und ob bereits Workflow-Vorlagen zugewiesen wurden.",
    INFO_PLACEHOLDER_SEARCH_BAR_VISUALIZATION: "Suche nach Visualisierung..",

    DIALOG_BAD_PRINT_WARNING_TITLE: "Browser nicht unterstützt",
    DIALOG_BAD_PRINT_WARNING_CONTENT:
        "Ihr Browser unterstützt die Druckfunktionalität für die Visualisierung nicht. Bitte laden Sie sich Google Chrome herunter und benutzen Sie diesen.",

    INFO_UPLOAD_VISUALIZATION_TEXT:
        "Laden Sie eine Abbildung Ihres Projektes hoch, die Informationen über Ihre Projektelemente enthält (SVG Datei)",
    INFO_UPLOAD_NEW_VISUALIZATION_MESSAGE:
        "Laden Sie eine Zeichnung hoch, die die Kürzel aller dargestellten Projektelemente enthält (SVG Datei).",
    INFO_GENERATE_NEW_VISUALIZATION_MESSAGE:
        "Erstellen Sie eine neue schematische Abbildung Ihres Projekts.",
    ACTION_UPLOAD_NEW_VISUALIZATION: "SVG Abbildung hochladen",
    ACTION_GENERATE_NEW_VISUALIZATION: "Visualisierung erstellen",

    SECTION_VISUALIZATION_SHOW_PROGRESS: "Soll-Ist-Vergleich anzeigen",
    SECTION_VISUALIZATION_SHOW_STAGE: "Status anzeigen",
    SECTION_VISUALIZATION_SHOW_CURRENT_ACTIVITY: "Teams anzeigen",
    SECTION_VISUALIZATION_SHOW_DONE_ACTIVITY: "Status abfragen",
    SECTION_VISUALIZATION_PRINT_TOOLTIP:
        "Visualisierung drucken oder mit einem PDF-Drucker als PDF speichern.",
    SECTION_VISUALIZATION_PROGRESS: "Soll-Ist-Vergleich",
    SECTION_VISUALIZATION_STAGE: "Status",
    SECTION_VISUALIZATION_CURRENT_ACTIVITY: "Teams",
    "0_INFO_LEGEND_NO_STAGE": "Bisher keine Aktivität abgeschlossen",
    "0_ACTIVITIES_PLANNED_LEGEND": "Keine Aktivitäten geplant",

    ERROR_SVG_DOES_NOT_EXIST:
        "Die angeforderte Visualisierung wurde nicht auf unserm Server gefunden. Bitte wählen Sie eine aus der Liste.",
    ERROR_FILE_IS_NOT_SVG:
        "Die hochgeladene Datei scheint nicht im SVG Format gespeichert zu sein. Bitte wählen Sie eine andere Datei.",
    CONFIRM_SVG_DELETION_TITLE: "Löschen der Visualisierung",
    CONFIRM_SVG_DELETION_MESSAGE: "Möchten Sie die Visualisierung löschen?",
    INFO_SVG_DELETION_SUCCESSFUL: "Visualisierung erfolgreich gelöscht",

    // Visualization configuration section
    INFO_VISUALIZATION_CONFIG_NO_TEMPLATE_EMPTY_STATE_MESSAGE:
        "Es scheint als hätten Sie noch keine Workflow-Vorlage in diesem Projekt erstellt.<br>Visualisierungen können erst erstellt werden, wenn eine Workflow-Vorlage angelegt wurde.",

    SELECT_VISUALIZATION_CONFIG_DELIVERABLE_TYPE_MESSAGE:
        "Welche Projektelemente sollen in der Visualisierung dargestellt werden?",
    SELECT_VISUALIZATION_CONFIG_STRUCTURE_NODES_MESSAGE:
        "Welche Teile der Projektstruktur sollen in der Visualisierung dargestellt werden?",
    SELECT_VISUALIZATION_CONFIG_SVG_TITLE_MESSAGE:
        "Definieren Sie nun bitte die in der Visualisierung dargestellten Titel.",
    SELECT_VISUALIZATION_CONFIG_LABEL_ROW_PROPERTY_MESSAGE:
        "Mit welcher Information sollen die Ebenen in der Visualisierung beschriftet werden?",
    SELECT_VISUALIZATION_CONFIG_LABEL_CELL_PROPERTY_MESSAGE:
        "Mit welcher Information sollen die Projektelemente in der Visualisierung beschriftet werden?",
    SELECT_VISUALIZATION_CONFIG_LABEL_PROPERTY_SUB_MESSAGE:
        "Welche Zeichen dieser Information sollen dargestellt werden?",
    SELECT_VISUALIZATION_CONFIG_SVG_NAME_MESSAGE:
        "Unter welchem Namen soll die Visualisierung gespeichert werden?",

    SECTION_VISU_CONFIG_DELIVERABLE_TYPE_STEPPER: "Workflow-Vorlage auswählen",
    SECTION_VISU_CONFIG_STRUCTURE_NODES_STEPPER: "Projektstruktur auswählen",
    SECTION_VISU_CONFIG_SVG_TITLE_STEPPER: "Titel definieren",
    SECTION_VISU_CONFIG_LABEL_PROPERTY_STEPPER: "Beschriftung definieren",
    SECTION_VISU_CONFIG_SVG_NAME_STEPPER: "Visualisierung benennen",

    INPUT_VISUALIZATION_CONFIG_DELIVERABLE_TYPE_DESCRIPTION:
        "Wählen Sie eine Workflow-Vorlage...",
    INPUT_VISUALIZATION_CONFIG_STRUCTURE_NODES_DESCRIPTION:
        "Wählen Sie alle relevanten Projektstruktur-Teile aus...",
    INPUT_VISUALIZATION_CONFIG_HEADER_RIGHT_DESCRIPTION: "Titel:",
    INPUT_VISUALIZATION_CONFIG_HEADER_LEFT_DESCRIPTION: "Projektstruktur:",
    INPUT_VISUALIZATION_CONFIG_LABEL_PROPERTY_DESCRIPTION:
        "Dargestellte Information:",
    INPUT_VISUALIZATION_CONFIG_FIRST_CHAR_DESCRIPTION:
        "Position erstes Zeichen",
    INPUT_VISUALIZATION_CONFIG_SECOND_CHAR_DESCRIPTION:
        "Position letztes Zeichen",
    INFO_VISUALIZATION_CONFIG_LABEL_PROPERTY_LIST: "Beispiele:",
    INPUT_VISUALIZATION_CONFIG_SVG_NAME_DESCRIPTION:
        "Definieren Sie einen Namen...",

    // Visualization generated SVG default headings
    SECTION_VISUALIZATION_GENERATED_SVG_HEADER_LEFT_TITLE: "Ebenen",
    SECTION_VISUALIZATION_GENERATED_SVG_HEADER_RIGHT_TITLE: "Titel",

    // Visualization bulk update
    START_BULK_UPDATE: "Auswahl starten",
    UPDATE_PROGRESS: "Status für Auswahl ändern",
    BULK_UPDATE_MODE_TITLE:
        "Markieren Sie Projektelemente, die aktualisiert werden sollen.",
    DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_TITLE:
        "Unterschiedliche Workflow-Vorlagen ausgewählt...",
    DIALOG_NON_UNIQUE_DELIVERABLE_TYPE_SELECTION_MESSAGE:
        "Der Status für eine Auswahl von Projektelementen kann nur geändert werden, wenn diese die gleiche Workflow-Vorlage verwenden. Bitte passen Sie Ihre Auswahl entsprechend an.",

    // Visualization legend keys
    VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_APPLICABLE:
        "Aktivität nicht enthalten (N/A)",
    VISUALIZATION_LEGEND_ENTRY_STAGE_NOT_STARTED: "Nicht gestartet",
    VISUALIZATION_LEGEND_ENTRY_STAGE_AVAILABLE:
        "Verfügbar, da Vorgänger abgeschlossen",
    VISUALIZATION_LEGEND_ENTRY_STAGE_STARTED: "Gestartet",
    VISUALIZATION_LEGEND_ENTRY_STAGE_COMPLETED: "Abgeschlossen & Geprüft",

    // Process Viewer
    SECTION_PROCESS_VIEWER_TITLE: "Status Tracker",
    SECTION_PROCESS_VIEWER_HIDE_ACTIVITIES_SWITCH_TEXT:
        "Abgeschlossene Aktivitäten ausblenden",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_TITLE:
        "Keine Projektelemente vorhanden",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_MESSAGE:
        "Bisher wurden in diesem Projekt keine Projektelemente angelegt.",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_ACTION_1:
        "@:SECTION_DASHBOARD_NO_DATA_CARD_1_ACTION",
    SECTION_PROCESS_VIEWER_NO_DATA_CARD_ACTION_2:
        "@:SECTION_DASHBOARD_NO_DATA_CARD_2_ACTION",
    SECTION_PROCESS_VIEWER_PAGE_NO_FILTERED_DATA:
        "@:SECTION_DELIVERABLES_PAGE_NO_FILTERED_DATA",
    SECTION_PROCESS_VIEWER_SELECT_ACTIVITIES_MESSAGE:
        "Markieren Sie Aktivitäten, um Abhängigkeiten zu definieren.",
    SECTION_PROCESS_VIEWER_SELECTED_ACTIVITIES_MESSAGE:
        "{{activitiesNum}} Aktivitäten ausgewählt.",
    SECTION_PROCESS_VIEWER_PREDECESSOR_MESSAGE:
        "{{activitiesNum}}  Aktivitäten ausgewählt. Jetzt bitte Vorgänger dieser Auswahl definieren…",
    SECTION_PROCESS_VIEWER_SUCCESSOR_MESSAGE:
        "{{activitiesNum}}  Aktivitäten ausgewählt. Jetzt bitte Nachfolger dieser Auswahl definieren…",
    SECTION_PROCESS_PREVIOUS_PAGE: "Zeige vorherige Aktivitäten",
    SECTION_PROCESS_NEXT_PAGE: "Zeige nächste Aktivitäten",
    DIALOG_CREATE_DEPENDENCY_LOADING_TITLE: "Einen Moment bitte...",
    DIALOG_CREATE_DEPENDENCY_LOADING_TEXT:
        "Wir erstellen gerade Ihre Abhängigkeiten. Dies kann einige Sekunden dauern.",

    INFO_PROCESS_VIEWER_NOTE_CREATED: "Meldung erfolgreich dokumentiert",

    // Deliverables list page
    SECTION_DELIVERABLES_PAGE_TITLE: "Projektelemente",
    SECTION_DELIVERABLES_PAGE_SUBTITLE: "Zurück zum Projektüberblick",
    SECTION_DELIVERABLES_PAGE_FILTERS: "Aktive Filter: ",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_CODE_NAME: "Name & Code",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_STRUCT_DESC:
        "Projektstruktur & Beschreibung",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_DATES: "Start- & Enddatum",
    SECTION_DELIVERABLES_PAGE_TABLE_HEADER_STATUS: "Workflow & Status",
    SECTION_DELIVERABLES_PAGE_NO_FILTERED_DATA:
        "Es existieren keine Projektelemente zu den aktiven Filtern.",
    INFO_DELIVERABLES_COUNT:
        "<b>{{filteredItems}} / {{totalItems}}</b> Projektelement{{(filteredItems ===0 || filteredItems > 1)  ? 'e' : ''}} in der Liste",
    INFO_LIST_UPDATED_MESSAGE: "Liste aktualisiert",
    INFO_LIST_SELECTION_REMOVED_MESSAGE: "Auswahl aufgehoben",
    SEARCHABLE_SELECT_SELECTION_REMOVED_MESSAGE: "Auswahl aufgehoben",
    SELECTED_ACTIVITIES__SECTION_PRINT_HEADER: "Ausgewählte Aktivitäten",

    ACTION_ASSIGN_TEMPLATE_ERROR_MAX_REQUEST:
        "Die Anzahl der ausgewählten Projektelemente übersteigt die technisch handhabbare Menge Ihres Browser.",
    ACTION_ASSIGN_TEMPLATE_LOADING:
        "Die Workflow-Vorlage wird Ihren ausgewählten Projektelementen zugewiesen.",
    ACTION_ASSIGN_TEMPLATE_SUCCESS_N:
        "{{changed}} von {{total}} Projektelement{{(total ===0 || total > 1)  ? 'en' : ''}} wurde eine Vorlage zugewiesen",

    ACTION_OPEN_BULK_PROGRESS_CHANGE_DIALOG: "Status für Auswahl ändern",
    DIALOG_BULK_PROGRESS_UPDATE_TITLE: "Status für Auswahl ändern",
    DIALOG_BULK_PROGRESS_UPDATE_HEADER:
        "Bitte wählen Sie alle Aktivitäten, die Sie aktualisieren möchten:",
    INFO_BULK_PROGRESS_CHANGE_MESSAGE:
        "Wenn Sie speichern, wird der Status aller gewählten Aktivitäten für alle vorher ausgewählten Projektelemente überschrieben.",
    INFO_BULK_PROGRESS_CHANGE_SUCCESS:
        "{{items}} Aktivitäten wurden auf '{{state}}' gesetzt.",
    DIALOG_BULK_PROGRESS_UPDATE_ACTION_HEADER:
        "Nun wählen Sie bitte den Status, den Sie für die Aktivitäten setzen möchten:",
    DIALOG_DELIVERABLE_TYPE_SELECTION_TITLE:
        "Aktivieren Sie den Workflow-Filter",
    DIALOG_DELIVERABLE_TYPE_SELECTION_MESSAGE:
        "Um Aktivitäten anpassen oder Aktualisierungen erfassen zu können, aktivieren Sie bitte zunächst den Workflow-Filter. Diesen finden Sie an dritter Stelle im Filter-Overlay, welches Sie in der rechten oberen Ecke öffnen können.",

    ACTION_OPEN_BULK_UPDATE_OPTIONS_DIALOG: "Aktualisierungen erfassen",
    DIALOG_BULK_UPDATE_OPTIONS_TITLE: "Projektelement-Auswahl aktualisieren",
    DIALOG_BULK_UPDATE_OPTION_UPDATE_PROGRESS_TITLE:
        "Status Ihrer Aktivitäten aktualisieren",
    DIALOG_BULK_UPDATE_OPTION_CREATE_NOTE_TITLE:
        "Meldung an Aktivitäten anhängen",

    DIALOG_BULK_CREATE_NOTE_TITLE: "Meldung für Auswahl erstellen",
    DIALOG_BULK_CREATE_NOTE_HEADER:
        "Bitte wählen Sie die Aktivität, für die Sie eine Meldung erstellen möchten:",

    DIALOG_BULK_PDF_EXPORT_TITLE: "QS Protokolle PDFs exportieren",
    DIALOG_BULK_PDF_EXPORT_TEXT:
        "Wählen Sie die Aktivitäten aus, für welche die QS Protokolle exportiert werden sollen. Wir werden für diese die PDFs erstellen und Ihnen eine E-Mail schicken.",
    DIALOG_BULK_PDF_EXPORT_BY_DATE_TEXT:
        "Wählen Sie den Tag, für den Sie alle hochgeladenen Protokolle als PDFs exportieren möchten. Wir werden anschließend die PDFs erstellen und Ihnen per E-Mail schicken.",
    DIALOG_BULK_PDF_EXPORT_EMPTY_TITLE: "Keine QS Protokolle verfügbar",
    DIALOG_BULK_PDF_EXPORT_EMPTY_TEXT:
        "Für die von Ihnen getroffene Auswahl sind keine ausgefüllten QS Protokolle verfügbar. Um PDFs exportieren zu können, wählen Sie bitte Projektelemente und Aktivitäten, für die ausgefüllte QS Protokolle gespeichert wurden.",
    DIALOG_BULK_PDF_EXPORT_BY_DATE_EMPTY_TEXT:
        "An dem ausgewählten Tag sind keine QS Protokolle hochgeladen worden. Um PDFs exportieren zu können, wählen Sie bitte ein anderes Datum.",
    DIALOG_BULK_PDF_EXPORT_SUCCESS_TITLE: "Ihre PDFs werden erstellt...",
    DIALOG_BULK_PDF_EXPORT_SUCCESS_TEXT:
        "Nachdem wir Ihre {{numberOfReports}} PDFs erstellt haben, werden wir sie an {{email}} verschicken.<br>Bitte beachten Sie, dass dies abhängig von der Menge der exportierten PDFs einige Zeit dauern kann (für 1000 PDFs bspw. bis zu 60min).<br>Sollten Sie größere Mengen von PDFs exportieren, bitten wir Sie mit der nächsten Anfrage zu warten, bis Sie eine E-Mail für Ihren aktuellen Export erhalten haben.",

    DIALOG_DELIVERABLE_CHECKLIST_PDF_EXPORT_TITLE:
        "Qualitätssicherungsdokumente für Projektelement exportieren",
    DIALOG_DELIVERABLE_CHECKLIST_PDF_SUCCESS_MESSAGE:
        "Sobald Ihr PDF erstellt wurde, senden wir es per E-Mail an {{email}}.<br>Bitte beachten Sie, dass dies für größere Projektelemente einige Zeit in Anspruch nehmen kann.",

    ACTION_CONFIGURE_DELIVERABLES: "Projektelemente anpassen",
    DIALOG_DELIVERABLE_SET_CONFIG_TITLE: "@:ACTION_CONFIGURE_DELIVERABLES",
    DIALOG__DELIVERABLE_SET__ASSIGN_TEMPLATE__OPTION_TITLE:
        "Workflow-Vorlage zuordnen",
    DIALOG__DELIVERABLE_SET__ASSIGN_STRUCTURE__OPTION_TITLE:
        "Projektstruktur ändern",
    DIALOG__DELIVERABLE_SET__CHANGE_DATES__OPTION_TITLE:
        "Projektelementzeitraum bearbeiten",
    DIALOG__DELIVERABLE_SET__ASSIGN_DELIVERABLE_TYPE__OPTION_TITLE:
        "Projektelementtyp ändern",
    DIALOG__DELIVERABLE_SET__ASSIGN_RESPONSIBLE_MANAGER__OPTION_TITLE:
        "Verantwortlichen Bauleiter zuweisen",
    DIALOG__DELIVERABLE_SET__DELETE__OPTION_TITLE:
        "@:DIALOG_DELETE_DELIVERABLES_TITLE",

    // Activity set configuration
    ACTION_CONFIGURE_ACTIVITIES: "Aktivitäten anpassen",
    DIALOG_ACTIVITY_SET_CONG_TITLE: "@:ACTION_CONFIGURE_ACTIVITIES",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_END_TITLE: "Enddatum festlegen",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_START_TITLE: "Startdatum festlegen",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_TITLE:
        "Benutzerdefinierte Aktivitäts-Daten entfernen",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_DATE_UNSET_SUBMIT: "Daten entfernen",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_TITLE:
        "Aktivitäten aus Workflow ausblenden",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_TITLE:
        "Ausgeblendete Aktivitäten einblenden",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES:
        "Bitte wählen Sie die Aktivitäten, die für die vorher ausgewählten Projektelemente wieder sichtbar gemacht werden sollen:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY:
        "Bitte wählen Sie die Aktivitäten, für die Sie ein benutzerdefiniertes Startdatum festlegen wollen:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_END_DATE:
        "Bitte wählen Sie die Aktivitäten, für die Sie ein benutzerdefiniertes Enddatum festlegen wollen:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_UNSET:
        "Bitte wählen Sie die Aktivitäten, für die Sie alle benutzerdefinierten Start- und Enddaten entfernen wollen:",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_SELECT_ACTIVITIES:
        "Wählen Sie alle Aktivitäten, die Sie aus dem Workflow der ausgewählten Projektelemente ausblenden möchten:",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_DATE:
        "Wählen Sie ein Datum für ",
    DIALOG_ACTIVITY_SET_OPTION_STAGE_SELECT_ACTIVITY_HINT:
        "Bitte beachten Sie, dass ein frühestmögliches Start- oder End-Datum nur dann Einfluss auf die Terminplanberechnung hat, wenn es nicht direkt den definierten Workflows und Abhängigkeiten widerspricht.",
    DIALOG_ACTIVITY_SET_OPTION_DELETE_ACTIVITY_SUBMIT: "Aktivitäten ausblenden",
    DIALOG_ACTIVITY_SET_OPTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUBMIT:
        "Ausgeblendete Aktivitäten einblenden",
    DIALOG_ACTIVITY_UNSET_WHAT_TEAM:
        "Für welches Team möchten Sie den Standard wieder verwenden?",

    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_TITLE: "Aktivitäts-Dauern ändern",
    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SELECT:
        "Wählen Sie alle Aktivitäten der vorher gewählten Projektelemente, für die Sie die geplante Dauer ändern möchten:",
    DIALOG_ACTIVITY_SET_OPTION_SET_DURATION_SUBMIT: "Dauern ändern",
    ACTION_SET_DURATION_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} {{(activities === 0 || activities > 1) ? 'Dauern': 'Dauer'}}  erfolgreich geändert.",

    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_TITLE:
        "Benutzerdefinierte Aktivitäts-Dauern entfernen",
    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SELECT:
        "Wählen Sie alle Aktivitäten der vorher gewählten Projektelemente, für die Sie die benutzerdefinierte Dauer entfernen und die ursprünglich in der Workflow-Vorlage geplante Dauer reaktivieren möchten:",
    DIALOG_ACTIVITY_UNSET_OPTION_SET_DURATION_SUBMIT:
        "Ursprüngliche Dauer nutzen",
    ACTION_UNSET_DURATION_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} {{(activities === 0 || activities > 1) ? 'Dauern': 'Dauer'}}  erfolgreich geändert.",

    DIALOG_ASSIGN_AREA_MANAGER_TITLE: "Verantwortlichen Bauleiter zuweisen",
    DIALOG_ASSIGN_AREA_MANAGER_TEXT:
        "Bitte geben Sie die E-Mail des Kollegens ein, der die Verantwortung über die ausgewählten Projektelemente übernehmen soll. Bitte beachten Sie, dass der Nutzer bereits einem Team des Projektes zugeordnet sein muss.",
    DIALOG_ASSIGN_AREA_MANAGER_ACTION: "Bauleiter zuweisen",
    ACTION_ASSIGN_AREA_MANAGER_SUCCESS_N:
        "{{displayName}} wurde als Bauleiter für {{deliverables}} Projektelement{{(deliverables === 1) ? '' : 'e'}} eingesetzt.",

    ACTION_SEND_REQUEST_FOR_PROGRESS: "Anfrage senden",
    ACTION_OPEN_REQUEST_FOR_PROGRESS_DIALOG: "Aktualisierungsanfrage",
    DIALOG_REQUEST_FOR_UPDATE_TITLE: "Aktualisierungsanfrage",
    DIALOG_REQUEST_FOR_UPDATE_CONTENT_HEADER:
        "Bitte geben Sie die E-Mail des Kollegens ein, von dem Sie Informationen anfordern möchten. Bitte beachten Sie, dass der Nutzer bereits einem Team des Projektes zugeordnet sein muss.",
    DIALOG_PICK_PROJECT_MEMBER_USERS_PLACEHOLDER:
        "E-Mail Adresse , Nachname oder Unternehmen des Partners...",
    DIALOG_PICK_PROJECT_MEMBER_USER_NOT_FOUND:
        "Bitte fügen Sie den Nutzer zunächst zu Ihrem Projekt hinzu.",
    DIALOG_REQUEST_FOR_UPDATE_WARNING:
        "Es scheint als haben Sie eine sehr große Anzahl von Projektelementen ausgewählt. Wir empfehlen kleinere Aktualisierungsanfragen zu versenden, da Ihr Partner ansonsten von der Menge der angeforderten Information überwältigt sein könnte.",
    TOAST_REQUEST_FOR_PROGRESS_SUCCESS:
        "Aktualisierungsanfrage für {{deliverables}} Projektelement{{(deliverables ===0 || deliverables > 1) ? 'e' : ''}} an {{username}} gesendet",

    ACTION_OPEN_ASSIGN_TYPE_DIALOG: "Projektelement-Typ zuweisen",
    DIALOG_ASSIGN_TYPE_TITLE: "Projektelement-Typ zuweisen",
    DIALOG_ASSIGN_TYPE_TEXT:
        "Bitte erstellen oder wählen Sie einen Typ, den Sie den zuvor ausgewählten Projektelementen zuweisen möchten:",
    DIALOG_ASSIGN_TYPE_ACTION: "Zuweisen",
    DIALOG_PICK_TYPE_TO_ASSIGN_PLACEHOLDER: "Typ erstellen oder auswählen...",
    DIALOG_PICK_TYPE_TO_ASSIGN_NOT_FOUND:
        "Dieser Typ existiert in diesem Projekt noch nicht...",

    // Structure  and dates dialog

    DIALOG_ASSIGN_STRUCTURE_TITLE: "Projektstruktur der Projektelemente ändern",
    DIALOG_ASSIGN_STRUCTURE_SELECTED_STRUCTURE_TEXT:
        "Die ausgewählten Projektelemente werden in folgenden Teil der Projektstruktur verschoben:",
    ACTION_ASSIGN_STRUCTURE: "Projektstruktur der Projektelemente ändern",
    DIALOG_CHANGE_DATES_OPTION_BOTH_TITLE:
        "Start- und Soll-Termin gemeinsam ändern",
    DIALOG_CHANGE_DATES_OPTION_START_TITLE: "Nur den Starttermin ändern",
    DIALOG_CHANGE_DATES_OPTION_END_TITLE: "Nur den Soll-Termin ändern",

    // Delete deliverables dialog
    DIALOG_DELETE_DELIVERABLES_TITLE: "Projektelemente löschen",
    DIALOG_DELETE_DELIVERABLES_TEXT_N:
        "Wollen Sie wirklich {{items}} Projektelement{{(items === 0 || items > 1) ? 'e': ''}} löschen?",
    DIALOG_DELETE_DELIVERABLES_OK: "Ja, löschen",

    TOAST_DELETE_DELIVERABLES_SUCCESS:
        "{{items}} Projektelement{{(items === 0 || items > 1) ? 'e': ''}} erfolgreich gelöscht.",
    DIALOG_DELETE_DELIVERABLES_LOADING_TEXT:
        "Projektelemente werden löschen...",

    ACTION_ASSIGN_STRUCTURE_SUCCESS_N:
        "{{deliverables}} Projektelement{{(deliverables ===0 || deliverables > 1) ? 'e wurden' : 'wurde'}} verschoben.",
    ACTION_CHANGE_DATES_SUCCESS_N:
        "Die Daten von {{deliverables}} Projektelement{{(deliverables ===0 || deliverables > 1) ? 'e wurden' : 'wurde'}} verändert.",
    ACTION_SET_ACTIVITY_DUE_DATES_SUCCESS_N:
        "{{(activities > 1) ? 'Die End-Daten' : ' Das End-Datum'}} von {{activities}}/{{totalActivitiesForBulkOperation}} Aktivität{{(activities === 0 || activities > 1) ? 'en wurden' : ' wurde'}} verändert.",
    ACTION_SET_ACTIVITY_START_DATES_SUCCESS_N:
        "{{(activities > 1) ? 'Die Start-Daten' : ' Das Start-Datum'}} von {{activities}}/{{totalActivitiesForBulkOperation}} Aktivität{{(activities === 0 || activities > 1) ? 'en wurden' : ' wurde'}} verändert.",
    ACTION_DELETE_BULK_ACTIVITY_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Aktivität{{(activities === 0 || activities > 1) ? 'en': ''}} erfolgreich ausgeblendet.",
    ACTION_BRING_BACK_NOT_APPLICABLE_ACTIVITIES_SUCCESS:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Aktivität{{(activities === 0 || activities > 1) ? 'en': ''}} eingeblendet",
    ACTION_UNSET_ACTIVITY_DATES_SUCCESS_N:
        "Die benutzerdefinierten Daten von {{activities}}/{{totalActivitiesForBulkOperation}} {{(activities === 0 || activities > 1) ? 'Aktivitäten' : 'Aktivität'}} wurden entfernt.",

    DIALOG_CHANGE_DATES_RANGE:
        "Behalte Dauer von {{days}} Tag{{(deliverables ===0 || deliverables > 1) ? 'en' : ''}} bei.",
    DIALOG_CHANGE_DATES_LOADING_TEXT: "Daten werden geändert...",
    DIALOG_CHANGE_DATES_DERIVE_FROM_SCHEDULE_HINT_TEXT: "Datum löschen",
    DIALOG_ASSIGN_STRUCTURE_LOADING_TEXT:
        "Projektelemente werden verschoben...",

    //Details Overlay
    _TIMEZONE: "Zeitzone",
    _DELAY: "Prognose",
    _DELAYED_BY_X_DAYS:
        "{{days}} Tag{{(days ===0 || days > 1) ? 'e' : ''}} verspätet",
    _SOURCE: "Quelle",
    _CREATED_WITH_EXCEL: "Importiert aus Excel",
    _CREATED_WITH_PRIMAVERA: "Importiert aus Primavera",
    _CREATED_WITH_MSP: "Importiert aus Microsoft Project",
    _CREATED_WITH_ASTA: "Importiert aus Asta Powerproject",
    _CREATED_WITH_CLONE: "Aus einem anderen Projekt kopiert",
    _CREATED_WITH_USER: "Erstellt mit Sablono",
    _SHOW_ALL: "Zeige alle Aktivitäten",
    _SHOW_ALL_CHECKLISTS: "Alle QA-Protokolle anzeigen",

    INFO_BEHIND_SCHEDULE_EXPLANATION_MESSAGE:
        "Mindestens eine Aktivität hinter dem Zeitplan",
    OPEN_ACTIVITY_UPDATE_TIMELINE: "Erfassungen für diese Aktivität anzeigen",

    //Dependencies
    SECTION_DEPENDENCIES_TITLE: "Abhängigkeiten",
    INFO_NO_CHECKLIST_ITEMS:
        "Es scheint als wurde dieses QS Protokoll ohne geprüfte Protokollpunkte hochgeladen.",
    INFO_DELIVERABLES_DEPENDENCIES_SCHEDULE_MODE_TEXT:
        'Im "einzeln geplant"-Modus haben Abhängigkeiten keinen Einfluss auf den Terminplan. In den Projekteinstellungen können Sie in den "zusammenhängend geplant"-Modus wechseln.',
    INFO_DELIVERABLE_PREDECESSOR_ADD_TEXT:
        "Projektelemente, die vor dem Angezeigten ausgeführt werden müssen, heißen Vorgänger.",
    INFO_DELIVERABLE_SUCCESSOR_ADD_TEXT:
        "Projektelemente, die erst nach dem Angezeigten ausgeführt werden können, heißen Nachfolger.",
    INFO_DETAILS_OVERLAY_FILTER_TEXT: "Nach Team filtern",
    INFO_EMPTY_DETAILS_OVERLAY_STATE_TITLE: "Keine Aktivitäten zugewiesen",
    INFO_EMPTY_DETAILS_OVERLAY_STATE_MESSAGE:
        "Es scheint, dass dem Team keine Aktivitäten für diese Sequenz zugewiesen wurden.",
    TOAST_DELETE_CUSTOM_DATES: "Benuzterdefiniertes Datum gelöscht",
    SELECT_PREDECESSOR_DEPENDENCY_TITLE:
        "Wählen Sie eine Vorgänger aus der Liste",
    SELECT_SUCCESSOR_DEPENDENCY_TITLE:
        "Wählen Sie eine Nachfolger aus der Liste",
    ACTION_ADD_PREDECESSOR: "Vorgänger hinzufügen",
    ACTION_ADD_SUCCESSOR: "Nachfolger hinzufügen",
    ACTION_CLOSE_DEPENDENCY_MANAGER: "Editieren beenden",
    SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_PRED:
        "Suchen Sie nach Projektelementen als Vorgänger anhand von Name, Kürzel oder Beschreibung...",
    SECTION_DEPENDENCIES_SEARCH_PLACEHOLDER_SUCC:
        "Suchen Sie nach Projektelementen als Nachfolger anhand von Name, Kürzel oder Beschreibung...",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_DIALOG_TITLE: "Abhängigkeit löschen",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_MULTIPLE_DIALOG_TEXT:
        "Soll diese Abhängigkeit wirklich gelöscht werden? Da diese automatisch von einer Projektelement-Abhängigkeit abgeleitet wurde, werden alle zugehörigen Abhängigkeiten ({{value}}) ebenfalls gelöscht.",
    SECTION_DEPENDENCIES_CONFIRM_DELETE_SINGLE_DIALOG_TEXT:
        "Soll diese Abhängigkeit wirklich gelöscht werden?",

    INFO_ACTIVITY_DEPENDENCY_NOT_FULFILLED:
        "<b>{{activitySource}}</b>{{ deliverableSource ? ' von <b>' + deliverableSource + '</b>' : ''}} muss abgeschlossen sein, bevor <b>{{activityTarget}}</b>{{ deliverableTarget ? ' von <b>' + deliverableTarget + '</b>' : ''}} begonnen werden kann.",
    INFO_ACTIVITY_DEPENDENCY_COMPLETED:
        "<b>{{activitySource}}</b>{{ deliverableSource ? ' von <b>' + deliverableSource + '</b>' : ''}} ist abgeschlossen, weshalb <b>{{activityTarget}}</b>{{ deliverableTarget ? ' von <b>' + deliverableTarget + '</b>' : ''}} begonnen werden kann.",

    ERROR_EDGE_WOULD_INTRODUCE_CYCLE:
        "Das Anlegen dieser Abhängigkeit würde einen Zyklus erzeugen.", // DB error code
    INFO_DATE_CHANGE_CONFLICT_STRATEGY_MESSAGE:
        "Bei möglichen Konflikten der Datumswerte (End- vor Start-Datum) wird der nicht manuell gesetzte Wert verworfen.",

    // notes
    INFO_ALL_ISSUES_RESOLVED: "Alle Probleme wurden behoben.",
    INFO_NO_NOTES_REPORTED_TITLE: "Es wurden keine Meldungen dokumentiert.",

    INFO_ISSUES_RESOLVED:
        "{{closed || 0}}/{{total}} Problem{{closed === 1 ? '' : 'e'}} behoben",

    ACTION_PRINT_DELIVERABLES_REPORT: "Drucke Projektelementbericht",
    ACTION_PRINT_ACTIVITIES_REPORT: "Drucke Aktivitätsbericht",
    ACTION_PRINT_STRUCTURE_REPORT: "Drucke Projektstrukturbericht",
    ACTION_PRINT_REPORT: "Drucke Bericht",
    ACTION_PRINT_REPORTS: "Berichte drucken",
    ACTION_EXPORT_DATA: "Daten exportieren",
    ACTION_SHOW_PROCESS_VIEWER: "Zu den Aktivitäten",
    ACTION_SHOW_DELIVERABLES_LIST: "Zu den Projektelementen",
    ACTION_ASSIGN_AS_PREDECESSOR: "Vorgänger der Auswahl definieren",
    ACTION_ASSIGN_AS_SUCCESSOR: "Nachfolger der Auswahl definieren",

    INFO_DELIVERABLES_REPORT_TITLE: "Statusbericht Projektelemente",
    INFO_DELIVERABLES_REPORT_TEXT:
        "Dieser Statusbericht enthält den aktuellen Fortschritt aller momentan in der Liste angezeigter Projektelemente.",
    INFO_ACTIVITIES_REPORT_TITLE: "Statusbericht Aktivitäten",
    INFO_ACTIVITIES_REPORT_TEXT:
        "Dieser detaillierte Statusbericht enthält den aktuellen Fortschritt aller momentan in der Liste angezeigter Projektelemente sowie der zugeordneten Aktivitäten.",
    INFO_STRUCTURE_REPORT_TEXT:
        "Dieser Statusbericht enthält den aktuellen Fortschritt Ihres Projektes dargestellt anhand der angelegten Projektstruktur.",
    INFO_DELIVERABLES_AND_ACTIVITIES_REPORT_TITLE:
        "Projektdaten exportieren (CSV)",
    INFO_QR_CODES_TITLE: "QR-Codes",

    OVERVIEW_TITLE: "Statusbericht Projektstruktur",
    OVERVIEW_TEXT:
        "Dieser Statusbericht enthält den aktuellen Fortschritt dargestellt anhand der Projektstruktur.",

    INFO_QR_CODES_TEXT:
        "Mit QR-Codes können die Projektelemente auf der Baustelle gekennzeichnet und so die zugehörigen Aktivitäten in der mobile App leichter gefunden werden.",
    INFO_QA_CHECKLIST_PDFS_TITLE: "QS Protokolle PDFs",
    INFO_QA_CHECKLIST_PDFS_TEXT:
        "Im nächsten Schritt können Sie die Aktivitäten auswählen, für die Ihre QS Protokolle als PDFs exportiert werden sollen.",
    INFO_QA_CHECKLIST_PDFS_BY_DATE_TITLE: "QS Protokolle PDFs",
    INFO_QA_CHECKLIST_PDFS_BY_DATE_TEXT:
        "Dieser Export enthält alle PDFs der QS Protokolle, die an einem gewählten Tag hochgeladen wurden. Im nächsten Schritt können Sie den Tag auswählen, für den Sie den Export machen wollen.",

    INFO_NO_FILTERED_DATA: "Es existieren keine Daten zu den aktiven Filtern.",
    SECTION_CHOOSE_REPORT_TYPE: "Wählen Sie einen Bericht",

    // LeanBoard
    SECTION_LEANBOARD_TITLE: "Lean Board",
    SECTION_LEANBOARD_EMPTY_MESSAGE:
        "Für den angezeigten Zeitraum sind keine Aktivitäten geplant.",
    ACTION_LEANBOARD_GO_TO_PREV_WEEK: "Zur vorherigen Wochen",
    ACTION_LEANBOARD_GO_TO_NEXT_WEEK: "Zur nächsten Woche",
    ACTION_LEANBOARD_OPEN_OVERLAY: "Detailansicht öffnen",
    ACTION_LEANBOARD_FORECAST_DELIVERABLE_DATES: "Prognostiziere alle",
    ACTION_LEANBOARD_SHOW_AGGREGATED_DELIVERABLES:
        "Enthaltene Projektelemente anzeigen",
    ACTION_LEANBOARD_ACCEPT_ALL: "Alle akzeptieren",
    ACTION_LEANBOARD_OPEN_LATE_ACTIVITIES_PANEL:
        "{{lateActivities}} verspätete Aktivitäten",
    DIALOG_LATE_ACTIVITIES_TITLE:
        "Aktivitäten hinter dem Zeitplan für {{deliverableName}}",
    DIALOG_LATE_ACTIVITIES_MESSAGE:
        "Klicken Sie auf Aktivitäten, um neue aktuell geplante Daten zu definieren.",
    ACTION_LEANBOARD_MOVE_TO_THIS_WEEK: "zur dargestellten Woche",
    INFO_LEANBOARD_UPDATING_SCHEDULE: "Lädt...",
    ONE_WEEK: "1 Woche",
    TWO_WEEKS: "2 Wochen",
    THREE_WEEKS: "3 Wochen",
    FOUR_WEEKS: "4 Wochen",
    EIGHT_WEEKS: "8 Wochen",
    TWELVE_WEEKS: "12 Wochen",
    COLUMN_TIMEFRAME_DAYS: "Tage",
    COLUMN_TIMEFRAME_WEEKS: "Wochen",

    DIALOG_LEAVE_LEANBOARD_WITH_OPEN_SESSION_HEADER:
        "Laufende Terminplanungs-Sitzung...",
    DIALOG_LEAVE_LEANBOARD_WITH_OPEN_SESSION_MESSAGE:
        "Sie führen aktuell eine Terminplanungs-Sitzung durch. Sind Sie sicher, dass Sie die Seite verlassen möchten, ohne Ihre Ergebnisse zu veröffentlichen oder verwerfen? Bitte beachten Sie, dass andere Nutzer keine Terminplanungsänderungen durchführen können, so lange Ihre Sitzung noch läuft.",
    ACTION_STAY_IN_LEANBOARD_SESSION: "Hier bleiben",
    ACTION_DISCARD_LEANBOARD_SESSION_AND_LEAVE: "Verlassen & verwerfen",
    ACTION_RELEASE_LEANBOARD_SESSION_AND_LEAVE: "Verlassen & veröffentlichen",
    ACTION_KEEP_LEANBOARD_SESSION_OPEN_AND_LEAVE:
        "Verlassen & Sitzung offen lassen",

    INFO_LEANBOARD_DELIVERABLE_SELECTED:
        "Eine Aktivität dieses Projektlementes wurde ausgewählt.",

    // Request for update
    SECTION_REQUEST_TEXT:
        "Projektelemente aktualisieren oder nach anderen suchen, falls Ihre Arbeiten nicht Teil der Liste sind.",
    SECTION_REQUEST_SUCCESS_TITLE: "Aktualisierung erfolgreich",
    SECTION_REQUEST_SUCCESS_TEXT:
        "Ihr Update wurde gespeichert. Danke für Ihre Mitarbeit!",
    SECTION_REQUEST_UPLOAD_BUTTON: "Fortschritt speichern",
    SECTION_REQUEST_INFORMATION_OPT_OUT_BUTTON: "Andere Projektelemente suchen",
    SECTION_REQUEST_ERROR_PERMISSION_DENIED_TITLE: "Zugriff verweigert",
    SECTION_REQUEST_ERROR_PERMISSION_DENIED_TEXT:
        "Es scheint als wäre der von Ihnen genutzte Link für einen anderen Kollegen bestimmt. Bitte gehen Sie sicher, dass die E-Mail für Sie bestimmt war und kontaktieren Sie im Zweifel den Absender.",
    SECTION_REQUEST_ERROR_CLOSED_TITLE: "Link abgelaufen",
    SECTION_REQUEST_ERROR_CLOSED_TEXT:
        "Der von Ihnen verwendete Link wurde bereits genutzt, um einen Fortschrittsbericht auf die Plattform hochzuladen. Bitte gehen Sie sicher, dass Sie die richtige E-Mail verwendet haben.",
    SECTION_REQUEST_ERROR_NOT_EXIST_TITLE: "Keine offene Anfrage",
    SECTION_REQUEST_ERROR_NOT_EXIST_TEXT:
        "Oops... Es scheint als läge keine offene Aktualisierungsanfrage für den von Ihnen genutzten Link vor. Bitte gehen Sie sicher, dass sie einen gültigen Link geklickt haben.",
    ACTION_SELECT_PROJECT: "Ein Projekt öffnen",

    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_PLACEHOLDER: "Suche ...",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_TITLE:
        " Suche nach Projektelemente",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_MESSAGE:
        "Suchen Sie nach Projektelementen anhand von Name, Code oder Beschreibung.",
    SECTION_DELIVERABLES_PROGRESS_LIST_SEARCH_HINT_START_SEARCH_ACTION:
        "Suche starten",
    SECTION_DELIVERABLES_PROGRESS_LIST_EMPTY_SEARCH_RESULTS_TITLE:
        "Keine Projektelemente gefunden",
    SECTION_DELIVERABLES_PROGRESS_LIST_EMPTY_SEARCH_RESULTS_MESSAGE:
        "Die Suche nach <b>{{searchTerm}}</b> ergab keinen Treffer.",
    SECTION_DELIVERABLES_PROGRESS_LIST_PREDECESSOR_DONE_BADGE:
        "Vorgänger abgeschlossen",
    SECTION_DELIVERABLES_PROGRESS_LIST_SCHEDULED_BADGE: "Arbeit geplant",

    // Reset password
    SECTION_RESET_PASSWORD_LINK: "Passwort vergessen?",
    SECTION_RESET_PASSWORD_TEXT:
        "Bitte geben Sie Ihre E-Mail ein. Wir schicken Ihnen eine E-Mail mit den weiteren Schritten.",
    SECTION_RESET_PASSWORD_BUTTON: "Neues Passwort anfordern",
    SECTION_RESET_PASSWORD_SUCCESS_TITLE: "Nur noch wenige Schritte...",
    SECTION_RESET_PASSWORD_SUCCESS_TEXT:
        'Bitte überprüfen Sie Ihren E-Mail Eingang, um fortzufahren. Falls Sie die E-Mail nicht finden, dann schauen Sie bitte auch in Ihrem Spam-Ordner nach oder kontaktieren Sie unseren Kundenservice: <a href="mailto:support@sablono.com">support@sablono.com</a>',
    SECTION_RESET_PASSWORD_ERROR_TITLE: "Ups...",
    SECTION_RESET_PASSWORD_ERROR_TEXT:
        'Es scheint als sei etwas schief gelaufen. Bitte versuchen Sie es in Kürze noch einmal. Sollten Sie weiterhin Probleme haben, kontaktieren Sie bitte unseren Kundenservice: <a href="mailto:support@sablono.com">support@sablono.com</a>',
    SECTION_RESET_PASSWORD_ERROR_LIMIT_ATTEMPT_EXCEEDED_TITLE:
        "Maximale Anzahl Versuche überschritten",
    SECTION_RESET_PASSWORD_ERROR_LIMIT_ATTEMPT_EXCEEDED_TEXT:
        "Sie haben die maximale Anzahl der Versuche überschritten, bitte versuchen Sie es später erneut.",
    SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TITLE: "Nutzer existiert nicht",
    SECTION_RESET_PASSWORD_ERROR_USER_NOT_FOUND_TEXT:
        "Es scheint als wäre dieser Nutzer noch nicht für Sablono registriert. Bitte überprüfen Sie, ob Sie alles richtig geschrieben haben.",
    SECTION_RESET_PASSWORD_MAIL_NOT_DELIVERED_TITLE: "Ups...",
    SECTION_RESET_PASSWORD_MAIL_NOT_DELIVERED_TEXT:
        'Es scheint als hätten wir Ihnen die E-Mail mit dem temporären Passwort nicht an die angegebene Adresse zustellen können. Bitte kontaktieren Sie unseren Kundenservice: <a href="mailto:support@sablono.com">support@sablono.com</a> Wir werden uns schnellstmöglich bei Ihnen melden.',

    //Issues Page
    SECTION_ISSUES_PAGE_TITLE: "Meldungen",
    INFO_ISSUES_COUNT:
        "<b>{{filteredItems}} / {{totalItems}}</b> Meldung{{(filteredItems ===0 || filteredItems > 1)  ? 'en' : ''}} in der Liste",
    INPUT_ISSUE_TEXT_SEARCH_TITLE: "Meldungstext",
    INPUT_ISSUE_TEXT_SEARCH_PLACEHOLDER: "Geben Sie einen Meldungstext ein",
    INPUT_ISSUE_KEY_SEARCH_LABEL: "Meldungskürzel",
    INPUT_ISSUE_KEY_PLACEHOLDER: "Geben Sie ein Meldungskürzel ein",
    INPUT_ISSUE_TYPE_LABEL: "Meldungstyp",
    INPUT_ISSUE_STATUS_LABEL: "Status",
    INPUT_ISSUE_TEMPLATE_KEY_LABEL: "Meldungsvorlagenkürzel",
    INPUT_ISSUE_TEMPLATE_KEY_PLACEHOLDER:
        "Geben Sie ein Meldungsvorlagenkürzel ein",
    INPUT_ISSUE_AUTHOR_SEARCH_LABEL: "Erfasser",
    INPUT_ISSUE_AUTHOR_PLACEHOLDER: "Geben Sie den Namen eines Erfassers ein",
    INPUT_ISSUE_DELIVERABLE_SEARCH_LABEL: "Projektelement Suche",
    INPUT_ISSUE_DELIVERABLE_PROCESS_SEARCH_LABEL: "Projektelement Workflow",
    INPUT_ISSUE_DELIVERABLE_SEARCH_PLACEHOLDER:
        "Suchen Sie nach Meldungen anhand von Projektelment Name, Code oder Beschreibung...",
    INPUT_DELIVERABLE_NAME_LABEL: "Projektelement-Name",
    INPUT_DELIVERABLE_NAME_PLACEHOLDER:
        "Geben Sie einen Projektelement-Namen ein",
    INPUT_DELIVERABLE_CODE_LABEL: "Projektelement-Code",
    INPUT_DELIVERABLE_CODE_PLACEHOLDER:
        "Geben Sie einen Projektelement-Code ein",
    INPUT_DELIVERABLE_DESC_LABEL: "Projektelement-Beschreibung",
    INPUT_DELIVERABLE_DESC_PLACEHOLDER:
        "Geben Sie eine Projektelement-Beschreibung ein",
    INPUT_ACTIVITY_SEARCH_LABEL: "Aktivitäts Suche",
    INPUT_ACTIVITY_SEARCH_PLACEHOLDER:
        "Suchen Sie nach Meldungen anhand von Aktivitäts Namen oder Code...",
    INPUT_ACTIVITY_NAME_LABEL: "Aktivitäts-Name",
    INPUT_ACTIVITY_NAME_PLACEHOLDER: "Geben Sie einen Aktivitäts-Namen ein",
    INPUT_ACTIVITY_DESC_LABEL: "Aktivitäts-Beschreibung",
    INPUT_ACTIVITY_DESC_PLACEHOLDER:
        "Geben Sie eine Aktivitäts-Beschreibung ein",

    INPUT_ISSUE_ISSUE_RESPONSIBLE_TEAM_PLACEHOLDER:
        "Wählen Sei ein verantwortliches Team aus",

    INPUT_ISSUE_ISSUE_CONFIRMATION_TEAM_PLACEHOLDER:
        "Wählen Sie ein abnehmendes Team aus",

    INPUT_ISSUE_GEOLOCATION_INFO:
        "Ihr Projektadministrator hat festgelegt, dass Fotos inkl. Geo-Standort hochgeladen werden sollen.",

    //create new issue
    DIALOG_ISSUE_EDITOR_TITLE: "Neue Meldung dokumentieren",
    DIALOG_EDIT_ISSUE_TITLE: "Meldung bearbeiten",
    DIALOG_EDIT_ISSUE_TOOLTIP: "@:ACTION_TEMPLATE_ADD_CONTENT",
    DIALOG_EDIT_ISSUE_SUCCESS: "@:INFO_PROJECT_EDIT_SUCCESS_TITLE",
    DIALOG_ISSUE_EDITOR_TEAM_SELECTOR_LABEL: "Verantwortliches Team",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_ERROR_REQUIRED:
        "Um fortzufahren, bitte wählen Sie ein abnehmendes Team",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_LABEL: "Abnehmendes Team",
    DIALOG_ISSUE_EDITOR_TEAM_CONFIRMATION_NONE:
        "Keine gesonderte Abnahme gefordert",
    INPUT_ISSUE_DESCRIPTION_TITLE:
        "Beschreiben Sie die neue Meldung möglichst aussagekräftig",
    ACTION_ADD_ATTACHMENT_TO_NOTE: "Anhang hinzufügen",

    //close issue
    SECTION_ISSUE_CONFIRM_CLOSE_ISSUE_TITLE: "Status ändern",
    SECTION_ISSUE_CONFIRM_CLOSE_ISSUE_TEXT:
        "Wollen Sie diese Meldung wirklich schließen? Dies kann nicht rückgängig gemacht werden.",
    ACTION_CLOSE_ISSUE: "Behoben",
    ACTION_OPEN_ISSUE: "Offen",
    ACTION_FINISH_ISSUE: "In Prüfung",
    ACTION_CONFIRM_ISSUE: "Behoben",
    ACTION_REJECT_ISSUE: "Nicht behoben",

    //print issue report dialog
    DIALOG_PRINT_ISSUE_REPORT_TITLE: "Wählen Sie einen Bericht",
    SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TITLE:
        "PDF Meldungsbericht inkl. Fotos",
    SECTION_PRINT_PDF_ISSUE_REPORT_WITH_IMAGES_TEXT:
        "Dieser Bericht enthält die {{filteredIssueCount}} momentan in der Liste angezeigte Meldungen.",
    SECTION_PRINT_CSV_ISSUE_REPORT_TITLE: "CSV Tabelle",
    SECTION_PRINT_CSV_ISSUE_REPORT_TEXT:
        "Diese Tabelle enthält alle {{totalIssueCount}} im Projekt vorliegenden Meldungen und kann in Microsoft Excel weiterverarbeitet werden.",

    //user defined dates
    INFO_DELETING_ALL_USER_DEFINED_DATES_MESSAGE:
        "Benutzerdefinierte Daten werden gelöscht",
    INFO_USER_DEFINED_DATE: "Benutzerdefiniertes Datum",
    USER_DEFINED_DATE_CONFLICT:
        "Benutzerdefinierte & berechnete Daten kollidieren",
    INFO_USER_DEFINED_DATE_EXPLAINED:
        "Dieses benutzerdefinierte Datum widerspricht den definierten Workflows und Abhängigkeiten. Daher wird es zwar hier dargestellt, beeinflusst den Terminplan allerdings nicht.",
    //Automatically calculated durations
    INFO_EQUAL_DISTRIBUTED_DURATIONS:
        "Berechnete Dauer – Die Dauer dieser Aktivität wurde auf Grundlage der Dauer des Projektelements berechnet. Im Vorlageneditor können Aktivitäten feste Dauern zugewiesen werden.",
    //Scheduling on demand - You project is in dirty mode
    INFO_SCHEDULING_ON_DEMAND_DIRTY_MODE_TEXT:
        "Sie haben Änderungen am Projekt vorgenommen (bspw. Projektelemente gelöscht oder neue Abhängigkeiten erstellt), die noch nicht im Terminplan berücksichtigt sind.",
    ACTION_RECALCULATE_SCHEDULE: "Terminkalendar neuberechnen",

    //////
    // Start of Commercial page
    //
    SECTION_COMMERCIAL_PAGE_TITLE: "Budgetübersicht",
    SECTION_COMMERCIAL_PRO_PAGE_TITLE: "Budgetübersicht",
    ACTION_NAV_TO_COMMERCIAL_PRO: "Budgetübersicht",

    ACTION_NAV_TO_DASHBOARD_PRO: "Project Control Center",
    SECTION_DASHBOARD_PRO: "Project Control Center",

    ACTION_NAV_TO_COMMERCIAL: "Budgetübersicht",
    SECTION_COMMERCIALS_PAGE_LOADING_DATA_MESSAGE: "Lade Kostendaten",
    SECTION_COMMERCIALS_PAGE_NO_DATA_TITLE: "Keine Kostengruppe verfügbar",
    SECTION_COMMERCIALS_PAGE_NO_DATA_MESSAGE:
        "Kostengruppen helfen Ihnen, den Fortschritt Ihres Projektes besser bewerten zu können. Bisher ist noch keine Kostengruppe für Sie verfügbar. Dies kann entweder heißen, dass noch keine erstellert wurde oder dass Sie nicht über ausreichende Rechte verfügen diese zu sehen.",

    // subbar actions
    ACTION_SETUP_COST_GROUPS: "Kostengruppe anlegen",

    // create cost group dialog
    DIALOG_CREATE_COST_GROUP_TITLE: "Neue Kostengruppe anlegen",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_TYPE:
        "Zunächst wählen Sie bitte die Workflow-Vorlage, für deren Aktivitäten Sie eine Kostengruppe anlegen möchten:",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_ACTIVITIES:
        "Nun wählen Sie bitte alle Aktivitäten, die zur Kostengruppe gehören:",
    DIALOG_CREATE_COST_GROUP_DESCRIPTION_STEP_NAME:
        "Wie lautet der Titel der Kostengruppe?",
    DIALOG_CREATE_COST_GROUP_NEW_NAME_PLACEHOLDER: "Titel",

    // cost group
    ACTION_DELETE_COST_GROUP: "Kostengruppe löschen",
    COST_GROUP_SAVED: "Kostengruppe gespeichert",

    // cost group details - statistics
    INFO_COST_GROUP_STAGES_TITLE: "Details der Kostengruppe",
    INFO_COST_GROUP_STAGE_WEIGHT: "% des Budgets",
    INFO_COST_GROUP_STAGE_BUDGET_ALLOCATED: "Budget zugewiesen",
    INFO_COST_GROUP_STAGE_BUDGET_STARTED: "Begonnen",
    INFO_COST_GROUP_STAGE_BUDGET_WAITING_FOR_CONFIRMATION: "Wartet auf Prüfung",
    INFO_COST_GROUP_STAGE_BUDGET_COMPLETED: "Abgeschlossen & Geprüft",
    INFO_COST_GROUP_STAGE_PERCENTAGE_OF_COST_GROUP_SPENT:
        "Kostengruppen-Budgets verbrauch",

    // cost group details - configuration error
    ERROR_COST_GROUP_CONFIG_TOTAL_WEIGHT_TITLE:
        "Bitte verteilen Sie 100,00% des Budgets.",
    ERROR_COST_GROUP_CONFIG_TOTAL_WEIGHT_MESSAGE: "Aktuell verteilt:",
    ERROR_COST_GROUP_CONFIG_REMAINING_WEIGHT_MESSAGE: "Zu verteilen:",

    // cost group header
    INPUT_COST_GROUP_ENTER_BUDGET_TITLE: "Budget",
    COST_GROUP_BUDGET_ALLOCATED: "Budget zugewiesen",
    COST_GROUP_BUDGET_STARTED: "Begonnen",
    COST_GROUP_BUDGET_WAITING_FOR_CONFIRMATION: "Wartet auf Prüfung",
    COST_GROUP_BUDGET_COMPLETED: "Abgeschlossen & Geprüft",
    COST_GROUP_PERCENT_COST_COMPLETED: "Kostengruppenbudget fertiggestellt",

    ERROR_COST_GROUP_WEIGHT_INPUT_PATTERN_MESSAGE: "Keine Zahl",
    ERROR_COST_GROUP_WEIGHT_BUDGET_MIN_MESSAGE: "0 ist das Minimalbudget",

    COST_GROUP_NUMBER_OF_COMMERCIAL_MANAGERS:
        "Kaufmännische Leiter: {{numberOf}}",
    COST_GROUP_NUMBER_OF_COMMERCIAL_REVIEWERS:
        "Kaufmännische Mitarbeiter: {{numberOf}}",

    COST_GROUP_ADD_MEMBER_DIALOG_HEADER: "Nutzer hinzufügen oder entfernen",
    COST_GROUP_ADD_MEMBER_DIALOG_TEXT_MANAGER:
        "<b>Kaufmännische Leiter</b> können die Budgets dieses Teams ändern und sehen sowie Zugriffsrechte verwalten.",
    COST_GROUP_ADD_MEMBER_DIALOG_TEXT_REVIEWER:
        "<b>Kaufmännische Mitarbeiter</b> können die Budgets dieses Teams sehen allerdings keine Änderungen vornehmen.",
    COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_HEADER: "Sind Sie sicher?",
    COST_GROUP_CONFIRM_REMOVE_YOURSELF_DIALOG_TEXT:
        "Nachdem Sie sich selbst entfernt haben, können Sie sie nicht länger die Budgets dieses Teams sehen oder editieren. Möchten Sie fortfahren?",

    COMMERCIAL_PAGE_SECTION_COMBINED_STAGES:
        "Aktivitäten, die in mehreren Workflow-Vorlagen vorkommen",
    ACTION_COMBINE_COST_GROUP: "Aktivitäten zusammenfassen",
    DIALOG_COMBINE_COST_GROUP_TITLE: "Aktivitäten zusammenfassen",
    DIALOG_COMBINE_COST_GROUP_STAGE_SELECT_ACTIVITIES_DESCRIPTION:
        "Bitte wählen Sie alle Aktivitäten, die Sie zu einer gemeinsamen Reihe der Kostengruppe zusammenfassen möchten:",
    DIALOG_COMBINE_COST_GROUP_STAGE_ENTER_NAME_DESCRIPTION:
        "Bitte geben Sie den Namen der zusammengefassten Reihe an:",
    DIALOG_CONFIRM_COMBINE: "Zusammenfassen",

    COST_GROUP_STAGE_SELECTOR_SEARCHBAR_PLACEHOLDER:
        "Suchen Sie nach Aktivitäten anhand von Name und Workflow-Vorlage...",
    DIALOG_COMBINE_COST_GROUP_STAGE_ENTER_NAME_PLACEHOLDER: "Name",

    //
    // End of Commercial  page

    //////
    // Start of CSV progress export headers
    //
    DELIVERABLE_CODE: "Projektelement-Code",
    DELIVERABLE_NAME: "Projektelement-Name",
    DELIVERABLE_DESC: "Projektelement-Beschreibung",
    PROJECT_STRUCTURE: "Projektstruktur",
    PLANNED_DELIVERABLE_START_DATE:
        "Benutzerdefinierter Start (Projektelement)",
    PLANNED_DELIVERABLE_END_DATE: "Benutzerdefinierter Ende (Projektelement)",
    EARLIEST_DELIVERABLE_START_DATE: "Frühester Start (Projektelement)",
    EARLIEST_DELIVERABLE_END_DATE: "Frühestes Ende (Projektelement)",
    LATEST_DELIVERABLE_START_DATE: "Spätester Start (Projektelement)",
    LATEST_DELIVERABLE_END_DATE: "Spätestes Ende (Projektelement)",
    DELIVERABLE_SCHEDULE: "Projektelement-Zeitplan",
    DELIVERABLE_PROGRESS: "Projektelement-Fortschritt",

    ACTIVITY_ID: "Aktivität ID",
    ACTIVITY_NAME: "Aktivität-Name",
    ACTIVITY_DESCRIPTION: "Aktivität-Beschreibung",
    ACTIVITY_PLANNED_DURATION: "Geplante Dauer",
    ACTIVITY_ASSIGNED_TEAM_NAME: "Ausführendes Team",
    PLANNED_ACTIVITY_START_DATE: "Benutzerdefinierter Start (Aktivität)",
    PLANNED_ACTIVITY_END_DATE: "Benutzerdefiniertes Ende (Aktivität)",
    EARLIEST_ACTIVITY_START_DATE: "Frühester Start (Aktivität)",
    EARLIEST_ACTIVITY_END_DATE: "Frühestes Ende (Aktivität)",
    LATEST_ACTIVITY_START_DATE: "Spätester Start (Aktivität)",
    LATEST_ACTIVITY_END_DATE: "Spätestes Ende (Aktivität)",
    ACTIVITY_ACTUAL_START_DATE: "Aktivität-Startdatum (Ist)",
    ACTIVITY_ACTUAL_END_DATE: "Aktivität-Enddatum (Ist)",
    ACTUAL_START_REPORTED_BY: "Start dokumentiert von",
    ACTUAL_END_REPORTED_BY: "Ende dokumentiert von",
    ACTIVITY_OPEN_CLAIM_QUANTITY: "Offene Mängel",
    ACTIVITY_OPEN_OBSTRUCTION_QUANTITY: "Offene Behinderungen",
    ACTIVITY_CURRENT_PROGRESS: "Aktueller Status",
    ACTIVITY_SCHEDULE: "Aktivität-Zeitplan",
    ACTIVITY_REQUIRED_CHECKLIST: "QS Protokoll angehängt",
    ACTIVITY_LAST_PLANNED_START: "Arbeitsplan Start (Aktivität)",
    ACTIVITY_LAST_PLANNED_END: "Arbeitsplan Ende (Aktivität)",
    ACTIVITY_LAST_PLANNED_DURATION: "Arbeitsplan Dauer (Arbeitstage)",
    ACTIVITY_CONFIRMATION_TIME: "Aktivität-Prüfungsdatum (Ist)",
    ACTIVITY_CONFIRMATION_REPORTER: "Geprüft von",
    ACTIVITY_STATE: "Status",
    //
    // End of CSV progress export headers

    //////
    // Start of checklist template abstract page
    //
    CHECKLIST_TEMPLATE_PAGE_NAME: "QS Protokolle",
    CHECKLIST_TEMPLATE_ADD_NEW: "Neues QS Protokoll erstellen",
    //
    // End of checklist template abstract page

    //////
    // Start of checklist template 'list' page
    //
    CHECKLIST_TEMPLATE_LIST_LOADING_DATA_MESSAGE: "Lade QS Protokolle",
    CHECKLIST_TEMPLATE_LIST_EMPTY_TITLE:
        "Erstellen Sie Ihr erstes Qualitätssicherungs-Protokoll",
    CHECKLIST_TEMPLATE_LIST_EMPTY_TEXT:
        "QS Protokolle gewährleisten hohe Qualität der Arbeit. Nachdem Sie ein Protokoll angelegt haben, können Sie es Ihren Aktivitäten in der Workflow-Vorlage zuweisen. Anschließend wird es abgefragt, wenn sich der Status der Aktivitäten ändert.",
    CHECKLIST_TEMPLATE_LIST_EMPTY_BUTTON:
        "Erstellen Sie Ihr erstes QS Protokoll",

    CHECKLIST_TEMPLATE_LIST_NUMBER_OF_ITEMS: "Anzahl Protokollpunkte",
    CHECKLIST_TEMPLATE_LIST_CREATION: "Erstellt",

    CREATED_BY:
        "Erstellt am {{timestamp | amDateFormat:dateFormat}} von {{inspector}}",

    CREATE_NEW_CHECKLIST_TEMPLATE_TITLE: "Neues QS Protokoll erstellen",
    CREATE_NEW_CHECKLIST_TEMPLATE_DESCRIPTION:
        "Bitte wählen Sie zunächst einen Namen für Ihr neues QS Protokoll:",
    CREATE_NEW_CHECKLIST_TEMPLATE_NAME_PLACEHOLDER: "QS Protokoll Name",
    CREATE_NEW_CHECKLIST_TEMPLATE_CONFIRM: "QS Protokoll erstellen",
    //
    // End of checklist template 'list' page

    //////
    // Start of checklist template 'editor' page
    //
    CREATE_CHECKLIST_EDITOR_TITLE: "Protokoll bearbeiten",
    CREATE_CHECKLIST_EDITOR_ITEMS: "Punkte:",
    CREATE_CHECKLIST_EDITOR_ADD_NEW: "Hinzufügen",
    CREATE_CHECKLIST_EDITOR_ADD_NEW_PLACEHOLDER:
        "Neuen Protokollpunkt hinzufügen...",
    CREATE_CHECKLIST_EDITOR_SAVED: "QS Protokoll erfolgreich gespeichert.",

    CREATE_CHECKLIST_EDITOR_SETTINGS_LABEL: "Zwingend für",
    CREATE_CHECKLIST_EDITOR_SETTINGS_TOOLTIP:
        "Zwingende Protokollpunkte werden in der mobile App hervorgehoben und können nicht vom Prüfenden Team mit 'nicht geprüft' beantwortet werden.",

    CREATE_CHECKLIST_EDITOR_IS_MANDATORY: "Alle Teams",
    CREATE_CHECKLIST_EDITOR_IS_NOT_MANDATORY: "Keine Teams",
    CREATE_CHECKLIST_EDITOR_IS_CONFIRM_MANDATORY: "Nur Prüfendes Team",
    CREATE_CHECKLIST_EDITOR_IS_WORK_MANDATORY: "Nur Ausführendes Team",

    CREATE_CHECKLIST_EDITOR_REQUIRE_IMAGE_LABEL: "Bild erforderlich",

    //
    // End of checklist template 'editor' page

    SECTION_CHECKLISTS_TITLE: "QS Protokolle",
    CHECKLISTS_TAB_NUMBER_UPLOADED: "QS Protokolle hochgeladen",
    CHECKLISTS_TAB_NUMBER_NON_STARTED: "QS Protokolle nicht gestartet",
    CHECKLISTS_TAB_NUMBER_ATTACHED_TO_ACTIVITY: "Angehängt an",
    CHECKLISTS_TAB_DONE_BY: "Abgeschlossen am {{timestamp}} von {{inspector}}",
    CHECKLISTS_TAB_NO_CHECKLISTS_FOR_FILTER:
        "Keine Aktivitäten mit verknüpften QS-Protokollen zugewiesen",
    CHECKLISTS_TAB_NO_CHECKLISTS_MESSAGE_FOR_FILTER:
        "Es scheint, dass dem Team keine Aktivitäten mit verknüpften QS-Protokollen für diese Sequenz zugewiesen wurden.",
    CHECKLISTS_TAB_NO_CHECKLISTS: "Kein QS-Protokoll verknüpft",
    CHECKLISTS_TAB_NO_CHECKLISTS_MESSAGE:
        "QS-Protokolle ermöglichen es Ihnen, eine Liste von Punkten für die Aktivität zu prüfen. In den Workflow-Vorlagen können QS-Protokolle mit Aktivitäten verknüpft werden.",
    CHECKLISTS_TAB_NO_WORKFLOW_ASSIGNED: "Keine Workflow-Vorlage zugewiesen",
    CHECKLISTS_TAB_NO_WORKFLOW_ASSIGNED_MESSAGE:
        "Die Zuweisung von Workflow-Vorlagen ermöglicht es Ihnen, einen höheren Detaillierungsgrad für Ihre Projektelemente zu erfassen und Checklisten zu den Aktivitäten hinzuzufügen.",

    CHECKLISTS_DETAILS_RESPONSIBLE_TEAM: "Ausführendes Team",
    CHECKLISTS_DETAILS_CONFIRMING_TEAM: "Prüfendes Team",
    CHECKLISTS_DETAILS_REPORTED_BY: "Erfasst durch:",
    CHECKLISTS_DETAILS_RESULT_Y: "Ja",
    CHECKLISTS_DETAILS_RESULT_N: "Nein",
    CHECKLISTS_DETAILS_RESULT_B: "Nicht geprüft",
    CHECKLISTS_DETAILS_RESULT_NA: "Unzutreffend",
    CHECKLISTS_DETAILS_RESULT_EMPTY: "",

    BACKGROUND_MENU_GLOBAL_SETTINGS: "Einstellungen",
    BACKGROUND_MENU_RELEVENT_ONLY: "Nur meine Projektelemente anzeigen",

    TEAM_USERS:
        "Nutzer, die für alle Projektelemente verantwortlich sind, die keinem Bauleiter zugewiesen wurden",
    TEAM_CONSTRUCTION_MANAGERS:
        "Bauleiter, die für bestimmte Projektelemente verantwortlich sind",
    TEAM_ADD_REPLACEMENT: "Definiere Vertretung",
    TEAM_REPLACEMENT_ACTIVE: "Aktiviert",
    TEAM_REPLACEMENT_INACTIVE: "Deaktiviert",
    TEAM_REMOVE_REPLACEMENT: "Vertretung löschen",
    TEAM_NO_EXISTING_REPLACEMENT: "Keine Vertretung angelegt",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TITLE:
        "Verschiebung Bauleiter bestätigen",
    SECTION_TEAMS_MOVE_YOURSELF_FROM_CONSTRUCTION_MANAGER_TEXT:
        "Sind Sie sicher, dass Sie diesen Bauleiter in ein anderes Team verschieben wollen? Wenn Sie fortfahren, werden alle ihm zugewiesenen Projektelemente wieder verfügbar für alle Nutzer dieses Teams. Dies kann nur über die Projektelement-Seite rückgängig gemacht werden.",

    WEEK_BOARD_OPTIONS: "Optionen",
    WEEK_BOARD_CHOOSE_SIZE: "Großes Aktivitäts-Design anzeigen",
    WEEK_BOARD_SHOW_ACTUALS: "Ist-Daten anzeigen, sofern verfügbar",
    WEEK_BOARD_SIZES_SMALL: "Klein",
    WEEK_BOARD_SIZES_LARGE: "Groß",
    WEEK_BOARD_PREVIOUS: "Vorherige Seite",
    WEEK_BOARD_NEXT: "Nächste Seite",

    DATE_ORIGIN_ACTUAL: "(Ist)",
    DATE_ORIGIN_PREDICTED: "@:DATE_ORIGIN_LOOK_AHEAD",
    DATE_ORIGIN_FORECASTED: "(Prognostiziert)",
    DATE_ORIGIN_LOOK_AHEAD: "(Arbeitsplan)",
    DATE_ORIGIN_BASELINE: "(Terminplan)",

    ACTIVITY_DETAILS_BASELINE_DURATION: "Dauer (Terminplan)",
    ACTIVITY_DETAILS_STRUCTURE_DISPLAYED_DURATION: "Projektstruktur Dauer",
    ACTIVITY_DETAILS_MEAN_DURATION_LOOKAHEAD: "Mittelwert-Dauer (Arbeitsplan)",
    ACTIVITY_DETAILS_BASELINE_START: "Start (Terminplan)",
    ACTIVITY_DETAILS_BASELINE_DUE: "Ende (Terminplan)",
    ACTIVITY_DETAILS_ACTUAL_START: "Ist Start",
    ACTIVITY_DETAILS_ACTUAL_DONE: "Ist Ende",
    ACTIVITY_DETAILS_ACTUAL_CONFIRMED: "Ist Geprüft",
    ACTIVITY_DETAILS_LATEST_DURATION: "Dauer (Arbeitsplan)",
    ACTIVITY_DETAILS_LATEST_START: "Start (Arbeitsplan)",
    ACTIVITY_DETAILS_NEW_DURATION: "Neue Dauer",
    ACTIVITY_DETAILS_DISPLAYED_DURATION: "Aktuelle Dauer",
    ACTIVITY_DETAILS_NEW_START: "Neuer Start",
    ACTIVITY_DETAILS_DISPLAYED_START: "Aktueller Start",
    ACTIVITY_DETAILS_NEW_DUE: "Neues Ende",
    ACTIVITY_DETAILS_DISPLAYED_DUE: "Aktuelles Ende",
    ACTIVITY_DETAILS_LATEST_DUE: "Ende (Arbeitsplan)",
    ACTIVITY_DETAILS_NUMBER_OF_CHANGES: "Anzahl der Neuplanungen",
    ACTIVITY_DETAILS_FORECAST_START: "Prognostizierter Start",
    ACTIVITY_DETAILS_FORECAST_DUE: "Prognostiziertes Ende",
    ACTIVITY_DETAILS_ACCEPT_FORECAST: "Als Arbeitsplan-Datum nutzen",
    ACTIVITY_DETAILS_FORECAST_START_TOOLTIP:
        "Übernehmen Sie den prognostizierten als neues Arbeitsplan Start. Anschließend müssen Sie noch speichern und Ihre Änderungen ins Projekt veröffentlichen.",
    ACTIVITY_DETAILS_FORECAST_DUE_TOOLTIP:
        "Übernehmen Sie das prognostizierte- als neues Arbeitsplan Ende. Anschließend müssen Sie noch speichern und Ihre Änderungen ins Projekt veröffentlichen.",
    ACTIVITY_DETAILS_MOVE_FOLLOWING_DATES:
        "Alle folgenden Aktivitäten entsprechend Ihrer Änderungen und der definierten Abhängigkeiten planen.",
    ACTIVITY_DETAILS_MOVE_FOLLOWING_DATES_TOOLTIP:
        "Wenn die Checkbox markiert ist, dann berechnet Sablono automatisch einen neuen aktuellen Terminplan für alle Nachfolger dieser Aktivität. Bitte beachten Sie, dass dadurch neue Start- und Enddaten für alle folgenden Aktivitäten definiert werden.",
    ACTIVITY_DETAILS_RESPONSIBLE_TEAM: "Ausführendes Team",
    ACTIVITY_DETAILS_PLANNED_LABOUR: "Geplante Arbeitskräfte",
    ACTIVITY_DETAILS_CONFIRMING_TEAM: "Prüfendes Team",
    ACTIVITY_DETAILS_DELIVERABLE_CODE: "Projektelement-Code",
    ACTIVITY_DETAILS_DELIVERABLE_NAME: "Projektelement-Name",
    ACTIVITY_DETAILS_PROJECT_STRUCTURE: "Projektstruktur",
    ACTIVITY_DETAILS_NUMBER_OF_DELIVERABLES: "Enthaltene Projektelemente",
    ACTIVITY_DETAILS_NUMBER_OF_FORECASTED_ACTIVITIES: "prognostiziert",
    ACTIVITY_DETAILS_REQUIRED_PRODUCTIVITY: "Geplante Produktivität",
    ACTIVITY_DETAILS_REQUIRED_PRODUCTIVITY_UNIT: "Projektelemente pro Tag",
    ACTIVITY_DETAILS_LAST_CHECKED_BY: "Zuletzt geprüft von",
    ACTIVITY_DETAILS_LAST_CHECKED_ON: "Zuletzt geprüft am",
    ACTIVITY_DETAILS_CHANGE_DATE: "Aktuelles Ende wählen",
    ACTIVITY_DETAILS_NO_CONFIRMATION_REQUIRED:
        "Keine gesonderte Abnahme gefordert",
    ACTIVITY_DETAILS_START_SESSION: "Sitzung starten",
    ACTIVITY_DETAILS_CHANGE_DATES_OF_ACTIVITIES: "Daten anpassen",
    ACTIVITY_DETAILS_HAS_CHANGED_THIS_SESSION:
        "Dieses Arbeitsplan Ende wurde in der laufenden Sitzung geändert.",
    ACTIVITY_DETAILS_SAVED_IN_SESSION: "Terminplanungs-Sitzung gespeichert",
    ACTIVITY_DETAILS_BULK_LOOK_AHEAD_SAVED:
        "{{changed}} von {{all}} Arbeitsplan-Daten wurden geändert.",

    FILTER_MENU: "Aktive Filter: {{activeFilterCount}}",
    FILTER_MENU_SEARCH_PLACEHOLDER: "Suche in {{items}} Einträgen...",

    ACTION_DOWNLOAD_WEEKPLAN: "Drucken",
    SESSION_MENU_BULK_CHANGES_CANCEL: "Fertig",
    SESSION_MENU_BULK_CHANGES_START: "Mehrere Aktivitäten ändern",
    SESSION_MENU_BULK_CHANGES_APPLY: "Daten der Auswahl anpassen",
    SESSION_MENU_BULK_CHANGES_ACTIVITIES:
        "{{activitiesNum}} Aktivitäten ausgewählt.",
    SESSION_MENU_START: "Neue Terminplanungs-Sitzung starten",
    SESSION_MENU_SAVE_AND_RELEASE: "Veröffentlichen",
    SESSION_MENU_CANCEL: "Verwerfen",
    SESSION_MENU_COMMIT_TO_ALL_FORECASTED_DATES:
        "Alle prognostizierten Daten als neuen Arbeitsplan übernehmen",
    ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_TITLE:
        "Laufende Terminplanungs-Sitzung auf dem Lean Board...",
    ERROR_DELIVERABLE_MODIFIED_IN_CONCURRENT_SESSION_MESSAGE:
        "Die folgenden Nutzer haben Ihre Arbeitsplan-Änderungen noch nicht veröffentlicht: {{users}}. Bitte gehen Sie sicher, dass alle Änderungen durch die verantwortlichen Nutzer entweder veröffentlicht oder verworfen wurden, bevor Sie weiter am Projekt-Terminplan arbeiten.",
    COMMIT_TO_FORECAST_CONFIRM_DIALOG_TITLE:
        "Alle prognostizierten Daten als neuen Arbeitsplan übernehmen",
    COMMIT_TO_FORECAST_CONFIRM_DIALOG_CONTENT:
        "Sind Sie sicher, dass Sie alle prognostizierten Daten als neuen Arbeitsplan übernehmen wollen? Bitte beachten Sie, dass dies auch Projektelemente einschließt, die Sie aktuell evtl. nicht auf Ihrem Bildschirm sehen (bspw. weil diese nicht zu Ihren Filtereinstellungen passen).",
    FORECAST_DATES_CONFIRM_DIALOG_TITLE:
        "Möchten Sie wirklich alle Projektelemente prognostizieren?",
    FORECAST_DATES_CONFIRM_DIALOG_TITLE_CONTENT:
        "Alle Prognostizieren betrifft alle Projektelemente, die zu Ihren aktuellen Filtern passen. Bitte beachten Sie, dass diese auch auf vorherigen/nächsten Seiten angezeigt werden können. Möchten Sie fortfahren?",
    FORECAST_DATES_ACCEPT_ALL_DIALOG_TITLE:
        "Möchten Sie wirklich alle prognostizierten Daten als Arbeitsplan verwenden?\n",
    FORECAST_DATES_ACCEPT_ALL_DIALOG_CONTENT:
        "Alle Akzeptieren betrifft alle prognostizierten Projektelemente, auch wenn diese nicht den aktuellen Filtern entsprechen oder auf vorherigen/nächsten Seiten angezeigt werden. Möchten Sie fortfahren?\n",

    WORKFLOW_ACTIVITY_DETAIL_DELETE_CUSTOM_DATE:
        "Benutzerdefinierte Daten löschen",
    WORKFLOW_ACTIVITY_DETAIL_QA_CHECKLIST: "Angehängtes QS Protokoll",
    WORKFLOW_ACTIVITY_DETAIL_LOOK_AHEAD_DURATION: "Dauer (Arbeitsplan)",
    WORKFLOW_ACTIVITY_BASELINE_DURATION: "Dauer (Terminplan)",
    WORKFLOW_GROUP_ACTIVITIES_COMPLETED: "Aktivitäten abgeschlossen",
    ACTIVITY_DETAILS_REVIEW_TEAMS: "Vorabnahme Team",

    WORKFLOW_BULK_LOOK_AHEAD_MULTI:
        "ALT + Klick, um eine Ebene der Projektstruktur auszuwählen",
    WORKFLOW_BULK_LOOK_AHEAD_BUSY_TITLE: "Ihr Terminplan wird berechnet...",
    WORKFLOW_BULK_LOOK_AHEAD_BUSY_PROGRESS:
        "Es werden noch {{items}} Aktivitäten neu geplant.",
    WORKFLOW_BULK_LOOK_AHEAD_DIALOG_TITLE:
        "Arbeitsplan-Daten für Auswahl ändern",
    WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_TITLE:
        "Eine andere Aktivität bereits ausgewählt...",
    WORKFLOW_BULK_LOOK_AHEAD_ERROR_SELECTION_MESSAGE:
        "Es scheint als haben Sie bereits eine andere Aktivität dieses Projektelements ausgewählt. Wenn Sie diese auswählen möchten, dann wählen Sie bitte die vorher gewählte ab.",
    WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_TITLE:
        "Aktivität bereits abgeschlossen...",
    WORKFLOW_BULK_LOOK_AHEAD_COMPLETED_ACTIVITY_SELECTION_ERROR_MESSAGE:
        "Für abgeschlossene Aktivitäten können die Arbeitsplan-Daten nicht mehr geändert werden. Sie werden mit ihrem Ist-Start und -Ende im Lean Board angezeigt.",

    // Daily logs page
    SITE_DIARY_TEAMS_CARD_TITLE_LEFT: "Teams",
    SITE_DIARY_TEAMS_CARD_TITLE_MANAGERS: "Aufsicht",
    SITE_DIARY_TEAMS_CARD_TITLE_FOREMEN: "Facharbeiter",
    SITE_DIARY_TEAMS_CARD_TITLE_WORKERS: "Helfer",
    SITE_DIARY_TEAMS_CARD_TITLE_OTHER: "Andere",
    SITE_DIARY_TEAMS_CARD_TITLE_TOTAL: "Summe",
    SITE_DIARY_PROGRESS_CARD_TITLE: "Fortschritt",
    SITE_DIARY_PROGRESS_CARD_INFORMATION:
        "Zuletzt aktualisiert am {{createdAt}}. Die Informationen können aktualisiert werden, bis der Bautagebuch-Eintrag abgeschlossen wurde.",
    SITE_DIARY_PROGRESS_CARD_TOOLTIP:
        "Diese Aktivität wurde am {{dateStarted}} begonnen.",
    SITE_DIARY_MACHINES_CARD_TITLE: "Maschinen",
    SITE_DIARY_MACHINES_CARD_PLACEHOLDER:
        "Geben Sie an, welche Maschinen und wie viele am ausgewählten Tag verwendet wurden.",
    SITE_DIARY_EVENTS_CARD_TITLE: "Besondere Ereignisse",
    SITE_DIARY_EVENTS_CARD_PLACEHOLDER:
        "Geben Sie besondere Vorkommnisse oder weitere Notizen für den ausgewählten Tag an.",
    ACTION_CHOOSE_A_DATE: "Datum auswählen",
    ACTION_FINALISE_ENTRY: "Eintrag abschließen",
    SITE_DIARY_CALENDAR_TODAY: "Heute",
    SITE_DIARY_CALENDAR_FINALISED: "Abgeschlossen",
    SITE_DIARY_CALENDAR_FINALISED_BY: "Abgeschlossen von {{finaliser}}",

    SITE_DIARY_INFO_CARD_LOW: "Min.",
    SITE_DIARY_INFO_CARD_HIGH: "Max.",
    SITE_DIARY_INFO_CARD_CONDITIONS: "Wetterlage",
    SITE_DIARY_INFO_CARD_DEPARTMENT: "Auftraggeber",
    SITE_DIARY_INFO_CARD_TEMPERATURE_UNIT: "&deg;C",

    SITE_DIARY_CARD_INPUT_ERROR_MIN_MESSAGE:
        "Bitte wählen Sie eine Zahl über {{value}}.",
    SITE_DIARY_CARD_INPUT_ERROR_MAX_MESSAGE:
        "Bitte wählen Sie eine Zahl unter {{value}}.",

    SITE_DIARY_PROGRESS_REPORTED_BY: "Aktualisiert von {{inspector}}",
    SITE_DIARY_PROGRESS_EMPTY:
        "Es scheint als wurde am ausgewählten Tag weder Fortschritt noch eine Meldung dokumentiert...",
    SITE_DIARY_NOTE_CREATED_BY: "Aktualisiert von {{inspector}}",

    SITE_DIARY_CONFIRM_LEAVE_TITLE: "Nicht gespeicherte Änderungen",
    SITE_DIARY_CONFIRM_LEAVE_TEXT:
        "Sie haben Änderungen an diesem Bautagebuch-Eintrag vorgenommen, die noch nicht gespeichert wurden. Sind Sie sicher, dass Sie die Seite verlassen wollen ohne vorher zu speichern?",

    SITE_DIARY_SAVE_SUCCESS: "Bautagebuch erfolgreich gespeichert",
    SITE_DIARY_PAGINATION_SELECTION: "Seite {{currentPage}} von {{totalPages}}",

    SITE_DIARY_SHOW_DETAILS: "Mehr Details",
    SITE_DIARY_HIDE_DETAILS: "Weniger Details",

    SITE_DIARY_CONFIRM_FINALISE_ENTRY_TITLE: "Sind Sie sicher?",
    SITE_DIARY_CONFIRM_FINALISE_ENTRY_TEXT:
        "Nachdem dieser Bautagebuch-Eintrag abgeschlossen wurde, kann er nicht weiter bearbeitet werden. Soll der Eintrag jetzt abgeschlossen werden?",

    COST_GROUP_ENTER_SEARCH_TERM: "Suchen Sie nach Kostengruppen...",
    COST_GROUP_SEARCH_EMPTY:
        "Es gibt keine Kostengruppen passend zu den gewählten Filtern.",

    COST_GROUP_CONFIRM_DELETE_TITLE: "Wirklich entfernen?",
    COST_GROUP_CONFIRM_DELETE_BODY:
        "Sind Sie sicher? Dies kann nicht rückgängig gemacht werden.",
    COST_GROUP_FORECASTED_VALUES_HINT:
        "Sie haben ein Datum in der Zukunft ausgewählt. Die Angaben auf der Budgetübersicht werden anhand des Projektplans berechnet.",

    COST_GROUP_CSV_COLUMN_WORK_PACKAGE: "Arbeitspaket",
    COST_GROUP_CSV_COLUMN_BUDGET_SHARE: "% des Budgets",
    COST_GROUP_CSV_COLUMN_BUDGET_ALLOCATED: "Budget zugewiesen",
    COST_GROUP_CSV_COLUMN_STARTED: "{{currency}} begonnen",
    COST_GROUP_CSV_COLUMN_PERCENT_STARTED: "% der Aktivitäten begonnen",
    COST_GROUP_CSV_COLUMN_TOTAL_STARTED: "Anzahl der begonnenen Aktivitäten",
    COST_GROUP_CSV_COLUMN_WAITING_FOR_CONFIRMATION:
        "{{currency}} wartet auf Prüfung",
    COST_GROUP_CSV_COLUMN_PERCENT_WAITING_FOR_CONFIRMATION:
        "% der Aktivitäten wartet auf Prüfung",
    COST_GROUP_CSV_COLUMN_TOTAL_WAITING_FOR_CONFIRMATION:
        "Anzahl der auf Prüfung wartenden Aktivitäten",
    COST_GROUP_CSV_COLUMN_FINISHED_AND_CONFIRMED:
        "{{currency}} abgeschlossen und geprüft",
    COST_GROUP_CSV_COLUMN_PERCENT_FINISHED_AND_CONFIRMED:
        "% der Aktivitäten abgeschlossen und geprüft",
    COST_GROUP_CSV_COLUMN_TOTAL_FINISHED_AND_CONFIRMED:
        "Anzahl der abgeschlossenen und geprüften Aktivitäten",
    COST_GROUP_CSV_COLUMN_PERCENT_COST_GROUP_BUDGET_SPENT:
        "% des Kostengruppen-Budget verbraucht",
    COST_GROUP_CSV_COLUMN_DATE_OF_GENERATION: "Bericht exportiert am",
    COST_GROUP_CSV_COLUMN_FIGURES_UP_TO_DATE: "Beinhaltet Updates bis zum",

    ACTIVITIES_LIST_SELECTOR_HEADER_ACTIVITY: "Aktivität",
    ACTIVITIES_LIST_SELECTOR_HEADER_TEAM: "Ausführendes Team",
    ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_TEAM: "Standard Ausführendes Team",
    ACTIVITIES_LIST_SELECTOR_HEADER_STANDARD_CONFIRMING_TEAM:
        "Standard Prüfendes Team",
    ACTIVITIES_LIST_SELECTOR_HEADER_CONFIRMATION_TEAM:
        "@:CHECKLISTS_DETAILS_CONFIRMING_TEAM",
    ACTIVITIES_LIST_SELECTOR_SEARCHBAR_PLACEHOLDER:
        "Suchen Sie nach Aktivitäten anhand von Workflow-Vorlage, Name und Team...",

    SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TITLE:
        "Ungültiger Bestätigungscode",
    SECTION_CONFIRM_RESET_PASSWORD_MISMATCH_CODE_ERROR_TEXT:
        "Es scheint als haben Sie einen ungültigen Bestätigungscode angegeben. Bitte versuchen Sie es erneut.",
    SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TITLE:
        "Abgelaufener Bestätigungscode",
    SECTION_CONFIRM_RESET_PASSWORD_EXPIRED_CODE_ERROR_TEXT:
        "Ups... Es scheint als sei Ihr Verifizierungscode abgelaufen ist. Bitte fordern Sie einen neuen an und versuchen Sie es erneut.",
    SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TITLE:
        "Benutzer kann nicht bestätigt werden",
    SECTION_VERIFY_EMAIL_UNAUTHORIZED_ERROR_TEXT:
        "Der Benutzer ist bereits bestätigt",
    SECTION_VERIFICATION_CODE_RESENT:
        "Ein neuer Verifizierungscode wurde an Ihre registrierte E-Mail Adresse gesendet. Bitte verwenden Sie den Link in der neuen E-Mail, um es erneut zu versuchen.",

    ERROR_EMAIL_FORMAT: "Bitte geben Sie eine gültige Email-Adresse ein",

    LEGEND_STAGE_MODE_DEFAULTS: "Sonstige",

    /**
     * Context: Information to display when options are disabled.
     *
     * Cases:
     *  - A checklist is required and this feature is currently not supported.
     */

    INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS___TITLE:
        "Vorabnahme Teams erforderlich",
    INFO__ACTIVITY_STATE_CHANGE_SELECT__UNSUPPORTED_WEB_FEATURE_REVIEW_TEAMS__MESSAGE:
        "Diese Aktivität muss mit Hilfe von Vorabnahme Teams geprüft werden, was ausschließlich über die Sablono mobile App möglich ist. Bitte nutzen Sie sie, um Fortschritt zu melden.",
    MAKING_DECISION_LOCAL_DATA_HEADER: "Lokal gespeicherte Workflow-Vorlage",
    MAKING_DECISION_LOCAL_DATA_MESSAGE:
        "Sie öffnen gerade eine bisher nur lokal {{timeAgo}} auf diesem Gerät gespeicherte Workflow-Vorlage. Möchten Sie diese weiter bearbeiten oder die neueste Version vom Sablono Server herunterladen?",
    DISCARD_AND_DOWNLOAD_NEW_VERSION:
        "Zurücksetzen & Neueste Version herunterladen",

    DELETE_PROJECT_DIALOG_WARN_USER:
        "Möchten Sie dieses Projekt wirklich löschen? Damit werden alle zugehörigen Informationen aus allen Sablono Systemen entfernt.",
    DELETE_PROJECT_DIALOG_WARN_USER_INFO:
        "Warnung: Dies kann NICHT rückgängig gemacht werden.",
    DELETE_PROJECT_DIALOG_WARN_USER_NEXT: "Weiter",
    DELETE_PROJECT_DIALOG_WARN_USER_ALT: "Datenexport anfragen",
    DELETE_PROJECT_DIALOG_PASSWORD:
        "Bitte geben Sie Ihr Sablono Passwort ein, um dieses Projekt unwiderruflich zu löschen.",
    DELETE_PROJECT_DIALOG_WRONG_PASSWORD: "Ihre Passwort ist nicht gültig.",
    DELETE_PROJECT_DIALOG_COPY_NAME_TO_DELETE:
        "Bitte kopieren Sie den Namen Ihres Projekts <b>{{projectName}}</b>, um zu bestätigen, dass Sie dieses Projekt dauerhaft löschen möchten. Warnung: Dies kann NICHT rückgängig gemacht werden.",
    DELETE_PROJECT_DIALOG_REPEAT_PROJECT_NAME_PLACEHOLDER:
        "Geben Sie den Namen zur Bestätigung ein",
    _ERASE: "Löschen",

    ARCHIVE_PROJECT: "Projekt archivieren",
    ARCHIVE_PROJECT_CONFIRMATION:
        "Möchten Sie dieses Projekt wirklich archivieren? Danach hat kein Nutzer mehr Zugriff, die Daten bleiben allerdings auf dem Sablono server erhalten und können in der Zukunft reaktiviert werden.",

    DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL_SUBJECT: "Datenexport anfragen",
    DELETE_PROJECT_DIALOG_EXPORT_DATA_EMAIL:
        "Hallo Sablono Support,%0D%0A%0D%0A" +
        "Ich möchte das Projekt (ID: {{projectId}}) löschen aber vorher einen Datenexport erhalten. Bitte kontaktieren Sie mich, um weitere Schritte zu besprechen.%0D%0A" +
        "Mit freundlichen Grüßen,,%0D%0A" +
        "{{username}}",

    ACTIVITIES_LIST_SELECTOR_SEARCHBAR_PLACEHOLDER_WITHOUT_PROCESS_TEMPLATE:
        "Suchen Sie nach Aktivitäten anhand von Name und Team...",

    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_TITLE:
        "Möchten Sie fortfahren?",
    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_MESSAGE:
        "Wenn Sie die Workflow-Vorlage für bereits gestartete Projektelemente ersetzen möchten, nutzen Sie bitte die untenstehende Box. Diese Aktion kann nur durch den Sablono Support rückgängig gemacht werden.",
    DIALOG_CONFIRM_BATCH_CHANGE_PROCESS_TEMPLATE_CHECKBOX_MESSAGE:
        "Ja, ich möchte bestehende Fortschrittsdaten überschreiben",
    ERR_FR_MAX_BODY_LENGTH_EXCEEDED:
        "Es scheint als hätten Sie eine zu hohe Anzahl von Aktivitäten für einen einzelnen Export ausgewählt. Bitte versuchen Sie es erneut mit für die gleiche Auswahl an Projektelementen, allerdings mit einer geringeren Anzahl von Aktivitäten. Falls das Problem bestehen bleibt, kontaktieren Sie bitte unseren Support.",

    FILTER_BY_PROCESS_TEMPLATES_TITLE: "Nach Workflow filtern",
    FILTER_BY_PROCESS_TEMPLATES_ALL_TEMPLATES: "Alle Workflows",

    FILTER_BY_DELIVERABLE_TYPE_TITLE: "Nach Projektelement-Typ filtern",
    FILTER_BY_DELIVERABLE_TYPE_ALL_TEMPLATES: "Alle Projektelement-Typen",

    DASHBOARD_PROJECT_BAR_CHART_TITLE: "Alle Aktivitäten",
    SECTION_PROJECT_OVERVIEW_TITLE: "Projektübersicht",
    ACTION_NAV_TO_PROJECT_OVERVIEW_TITLE: "Projektübersicht",

    PROJECT_OVERVIEW_PROCESS_TEMPLATES_TITLE: "Workflows",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_TITLE: "Projektelement-Typen",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_TITLE:
        "Projektelement-Typen erstellen",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_MESSAGE:
        "Sobald Sie Ihre Projektelemente in Typen kategoriesiert haben, wird Ihnen hier eine Zusammenfassung pro Typ angezeigt.",
    PROJECT_OVERVIEW_DELIVERABLE_TYPES_NO_DATA_ACTION: "@:GO_TO_DELIVERABLES",

    DASHBOARD_BAR_CHART_TOTAL_LABEL: "Insgesamt:",
    DASHBOARD_BAR_CHART_TOTAL_TEXT: "@:_ACTIVITIES_N",
    _DELIVERABLE_TYPE: "Projektelement-Typ",

    REMOVE_DELIVERABLE_TYPE: "Projektelement-Typ entfernen",
    DIALOG_UNASSIGNED_TYPE_ACTION: "Entfernen",

    //    TUTORIAL LINKS

    TUTORIAL_BAR_CHART:
        "http://support.sablono.com/de-form/articles/4828856-balkendiagramm-soll-ist-vergleich",
    TUTORIAL_S_CURVE:
        "http://support.sablono.com/de-form/articles/4828855-s-kurve-soll-ist-vergleich",
    TUTORIAL_PRODUCTIVITY_CHART:
        "https://support.sablono.com/de-form/articles/8704401-produktivitatsdiagramm-soll-ist-vergleich-pro-kw",
    TUTORIAL_DELIVERABLE_TYPES:
        "http://support.sablono.com/de-form/articles/4838516-how-to-projektelemente-nach-typ-strukturieren",
    TUTORIAL_IMPORT_PROGRESS:
        "http://support.sablono.com/de-form/articles/5393783-how-to-fortschrittsdaten-aus-microsoft-excel-importieren",

    DELIVERABLE_LINK_TO_MOBILE_LABEL: "In mobiler App öffnen",
    DELIVERABLE_LINK_TO_MOBILE: "Klicken Sie hier",
    ERROR_OPEN_DEEP_LINK_TO_NATIVE_APP_MESSAGE:
        "Bitte stellen Sie sicher, dass die Sablono App installiert ist, um sie zu öffnen",
    DELIVERABLE_VIEW: "Projektelemente",
    STRUCTURE_VIEW: "Projektstruktur",

    PAGINATION_FETCH_FROM_SOURCE_ERROR_MESSAGE:
        "Das Laden weiterer Daten im Hintergrund ist fehlgeschlagen. Die dargestellten Information in der aktuellen Ansicht sind eventuell nicht vollständig.",
    UPLOAD_SVG_FILE:
        "Su archivo se está cargando - Esto puede tardar unos momentos...",

    TIME_RANGE_SELECTOR_TITLE:
        "Wählen Sie den Zeitraum, der auf dem Lean Board dargestellt werden soll",
    TIME_RANGE_SELECTOR_FROM: "Start Datum",
    TIME_RANGE_SELECTOR_TO: "End Datum",
    TIME_RANGE_SELECTOR_EXTRA: "Vordefinierte Zeiträume",
    TIME_RANGE_SELECTOR_CURRENTLY_SELECTED: "Aktuell gewählt",
    TIME_RANGE_SELECTOR_START: "Start",
    TIME_RANGE_SELECTOR_END: "Ende",

    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_TITLE: "Teams ändern",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SELECT:
        "Bitte wählen Sie die Aktivitäten der zuvor ausgewählten Projektelemente aus, für die Sie die vordefinierten Teams Ihrer Workflow-Vorlage ändern möchten:",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_SUBMIT: "Teams ändern",
    DIALOG_ACTIVITY_SET_OPTION_SET_TEAM_DROPDOWN_DESCRIPTION:
        "Für welches Team möchten Sie den Standard ändern?",

    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_TITLE:
        "Benutzerdefinierte Teams entfernen",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SELECT:
        "Bitte wählen Sie die Aktivitäten der zuvor ausgewählten Projektelemente aus, für die Sie die benutzerdefinierten Teams entfernen und stattdessen den Standard Ihrer Workflow-Vorlagen verwenden möchten:",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_SUBMIT: "Standard-Teams verwenden",
    DIALOG_ACTIVITY_SET_OPTION_UNSET_TEAM_DROPDOWN_DESCRIPTION:
        "Für welches Team möchten Sie den Standard wieder verwenden?",

    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_RESPONSIBLE:
        "@:ACTIVITY_DETAILS_RESPONSIBLE_TEAM",
    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_CONFIRMATION:
        "@:ACTIVITY_DETAILS_CONFIRMING_TEAM",
    DIALOG_ACTIVITY_SET_OPTION_TEAM_DROPDOWN_BOTH: "Beide",
    DIALOG_ACTIVITY_SET_SUCCESS_TEXT:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Teams erfolgreich geändert.",

    SECTION_EXCEL_IMPORT_PROGRESS_TEXT:
        "Bitte stellen Sie vor dem Import sicher, dass Ihre Excel das folgende Format hat:<br>" +
        "Projektelement Code - Aktivitäts-Name - Status - Zeitstempel - Datumsformat - Projekt-ID<br>" +
        "Bitte beachten Sie, dass Sie nur Aktivitäten aktualisieren können, für die Ihr Team verantwortlich ist.",

    SECTION_EXCEL_IMPORT_PROGRESS_TITLE: "@:SECTION_EXCEL_IMPORT_TITLE",
    SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TITLE:
        "Fortschritt erfolgreich importiert",
    SECTION_EXCEL_IMPORT_PROGRESS_SUCCESS_TEXT:
        "{{numberOfUpdates}} Status-Updates wurden in Ihr Projekt importiert",
    SECTION_EXCEL_IMPORT_UPLOADED_WITH_FAILURES:
        "Leider konnten einige Ihrer Status-Updates nicht in Ihr Projekt importiert werden:",

    CS_REVERT_CONFIRMATION_ACTION: "Anfrage: Prüfung zurücknehmen",
    CS_RESET_ACTIVITY_DIALOG_HEADER: "Anfrage: Aktivität zurücksetzen",
    CS_REVERT_CONFIRMATION_DIALOG_HEADER: "Anfrage: Prüfung zurücknehmen",
    CS_RESET_ACTIVITY_DIALOG_CONTENT:
        "Wenn Sie diese Aktivität zurücksetzen möchten, was das Löschen aller verbundenen Daten beinhaltet, kopieren Sie bitte den folgenden Text und fügen Sie ihn in eine Nachricht an Ihren Sablono-Support ein:",
    CS_REVERT_CONFIRMATION_DIALOG_CONTENT:
        "Wenn Sie die Prüfung dieser Aktivität einschließlich aller damit verbundenen Daten rückgängig machen möchten, kopieren Sie bitte den folgenden Text und fügen ihn in eine Nachricht an Ihren Sablono-Support ein:",

    IMPORT_PROGRESS_SUCCESS: "Aktivität erfolgreich aktualisiert",
    IMPORT_SUCCESS: "Aktivität erfolgreich aktualisiert",
    IMPORT_PROGRESS_CHECKLIST_REQUIRED:
        "Ein QS-Protokoll muss beim Aktualisieren des Aktivitätsstatus ausgefüllt werden",
    IMPORT_PROGRESS_NOT_ALLOWED_TRANSITION:
        "Die bereitgestellten Status Updates stehen im Konflikt mit Sablono Workflow-Logik",
    IMPORT_PROGRESS_ALREADY_UPDATED:
        "Das bereitgestellte Status-Update existiert bereits",
    IMPORT_ACTIVITY_DUPLICATE_NAME:
        "Der angegebene Aktivitätsname existiert zweimal in dem entsprechenden Projektelement",
    IMPORT_PROGRESS_NOT_RESPONSIBLE_TEAM:
        "Ihr Team ist nicht für die Aktivität verantwortlich",
    IMPORT_PROGRESS_TIMESTAMP_IN_THE_FUTURE:
        "Der angegebene Zeitstempel des Status-Updates liegt in der Zukunft",
    IMPORT_ACTIVITY_NOT_FOUND:
        "Der angegebene Aktivitätsname ist im entsprechenden Projektelement nicht vorhanden",
    IMPORT_USER_DEFINED_DATES_ONLY_ONE_DATE_IS_ALLOWED:
        "Es sollte nur eines der Start- oder Enddaten angegeben werden",
    IMPORT_USER_DEFINED_DATES_NO_DATE_AND_DURATION_GIVEN:
        "Es muss entweder das Start- oder Enddatum oder die Dauer angegeben werden",
    IMPORT_USER_DEFINED_DATES_DELIVERABLE_IS_LOCKED:
        "Liefergegenstand ist derzeit in der Planungssitzung gesperrt",
    IMPORT_USER_DEFINED_DATES_INVALID_UNIT_OF_WORK: "Falsche Arbeitseinheit",
    IMPORT_PROGRESS_WRONG_PROJECT:
        "Die angegebene Projekt-ID passt nicht zum aktuell geöffneten Projekt",
    ERROR_PROGRESS_ENTRIES_LIMITATION:
        "Sie können nur maximal 500 Status-Updates auf einmal importieren. Falls Sie mehr Updates importieren möchten, dann teilen Sie Ihre Datei bitte in mehrere und importieren Sie mehrfach hintereinander.",
    ERROR_DATES_ENTRIES_LIMITATION:
        "Sie können nur maximal 10000 Datum-Updates auf einmal importieren. Falls Sie mehr Updates importieren möchten, dann teilen Sie Ihre Datei bitte in mehrere und importieren Sie mehrfach hintereinander.",
    ERROR_PDF_CREATION_MESSAGE:
        "Das angeforderte PDF ist zu groß, um erstellt zu werden. Bitte verkleinern Sie die ausgewählten Daten und versuchen Sie es erneut.",
    ERROR_REQUEST_SIZE_EXCEEDED_MESSAGE:
        "Die angeforderten Daten haben die unterstützten Grenzen überschritten. Bitte reduzieren Sie die Menge der enthaltenen Daten und versuchen Sie es erneut.",
    ERROR_NOTE_REQUEST_SIZE_EXCEEDED_MESSAGE:
        "Ihr angeforderter Bericht überschreitet unser Exportlimit von {{sizeLimitInMb}} MB. Ihre Anfrage enthält {{numberOfNotes}} Meldungen inkl. {{numberOfImagesIncluded}} Fotos ({{sizeEstimateInMbOfImages}} MB). Bitte reduzieren Sie die Größe Ihrer Exportanfrage mit Hilfe der Filter. Wenn Sie diese Meldung weiterhin sehen, wenden Sie sich bitte an unseren Support.",
    INTERNAL_SERVER_ERROR: "@:ERROR_UNKNOWN_MESSAGE",
    FORBIDDEN:
        "Sie haben keine ausreichenden Rechte für diese Interaktion. Bitte kontaktieren Sie Ihren Projektadministrator, um bspw. Ihre Rolle zu ändern.",

    EULA_PROMPT_TITLE:
        "Wir haben unsere Lizenzbestimmungen gegenüber Endanwendern (EULA) und Datenschutzerklärung aktualisiert",
    EULA_PROMPT_CONTENT:
        'Wir  möchten  Sie  darüber  informieren,  dass  wir  unsere  EULA  und  Datenschutzbestimmungen angepasst haben.<br><br>Hier finden Sie aktuelle Version der <a href="https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_DE.pdf" target="_blank">Lizenzbestimmungen gegenüber Endanwendern (EULA)</a>. <br><br>Den Text der Datenschutzerklärung finden Sie unter <a href="https://www.sablono.com/de/datenschutz" target="_blank">https://www.sablono.com/de/datenschutz</a>.<br><br>Sie brauchen weiter nichts zu tun. Falls Sie Widerspruch einlegen wollen, tun Sie das bitte innerhalb 30 Tagen per E-Mail an <a href=\'mailto:support@sablono.com\'>support@sablono.com</a>.<br><br>Ihr Sablono Team',
    EULA_CONFIRM_READ_TEXT: "Ich habe die EULA gelesen und verstanden",
    EULA_PROMPT_ACTION_ACCEPT: "Ok",
    EULA_PROMPT_ACTION_READ: "EULA als PDF öffnen",
    EULA_PROMPT_ACTION_SKIP: "Erinnere mich später",
    EULA_LINK:
        "https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_DE.pdf",
    EULA_REVIEW_EULA: "Die EULA überprüfen",
    PRIVACY_POLICY: "Datenschutzbestimmungen",
    PRIVACY_POLICY_URL:
        "https://www.sablono.com/de/rechtliches/datenschutzbestimmungen",

    SECTION_TOOLS_DIALOG_USAGE_NUMBER: "({{active}} Projektelemente aktiv)",

    IMPORT_DELIVERABLE_EXCEL_TUTORIAL_URL:
        "https://support.sablono.com/de-form/articles/2967630-how-to-ein-microsoft-excel-projekt-importieren",
    IMPORT_ASTA_TUTORIAL_URL:
        "https://support.sablono.com/de-form/articles/2960319-how-to-einen-asta-power-project-terminplan-importieren",
    CREATE_VISUAL_TRACKER_TUTORIAL_URL:
        "https://support.sablono.com/de-form/articles/2911811-visuelle-tracker-auf-sablono-erstellen",
    VISUAL_TRACKER_SVG_UPLOAD_TUTORIAL_URL:
        "https://support.sablono.com/de-form/articles/5461453-how-to-fortschrifttvisualisierungen-mit-inkscape-erstellen",
    GO_TO_TUTORIAL_ACTION: "Tutorial öffnen",
    COST_GROUP_TUTORIAL_URL:
        "https://support.sablono.com/de-form/articles/4828779-wie-kann-ich-eine-kostengruppe-einrichten",
    PROCESS_TEMPLATES_TUTORIAL_URL:
        "https://support.sablono.com/de-form/articles/2967824-how-to-eine-prozessvorlage-erstellen",
    QA_CHECKLIST_TUTORIAL_URL:
        "https://support.sablono.com/de-form/articles/2904883-was-sind-qs-protokolle-und-wie-verwendet-man-sie",
    NOTE_TEMPLATES_TUTORIAL_URL:
        "https://support.sablono.com/de-form/articles/2967699-how-to-eine-meldungsvorlage-erstellen",
    NOTES_TUTORIAL_URL:
        "https://support.sablono.com/de-form/articles/5433250-hinzufugen-von-meldungen-uber-die-webplattform",
    NOTES_EMPTY_STATE_HEADER: "Erstellen Sie Ihre ersten Meldungen",
    NOTES_EMPTY_STATE_CONTENT:
        "Auf Sablono können Meldungen für Projektelemente, Aktivitäten und QS Protokollpunkte erstellt werden. Lesen Sie das Tutorial, wenn Sie mehr erfahren möchten.",
    LEGEND_LINE_DUE_TODAY: "Heute fällig",
    LEGEND_LINE_DUE_THIS_WEEK: "Ende dieser Woche fällig",
    LEGEND_LINE_DUE_NEXT_WEEK: "Ende nächster Woche fällig",

    MAIN_CONTRACTOR: "Generalunternehmer",
    SUBCONTRACTOR: "Subunternehmer",
    POD_SUPPLIER: "Lieferant für vorgefertigte Badezimmer",
    HOUSE_BUILDER: "Hausbauunternehmen",
    ARCHITECT: "Architekt",
    DEVELOPER: "Projektentwickler",
    HOUSING_ASSOCIATION: "Wohnungsbaugesellschaft",
    MANUFACTURER: "Zulieferer",
    CONSULTANT: "Berater",
    OTHER: "Andere",
    CONSTRUCTION_MANAGER: "Projektmanager",
    CONSTRUCTION_DIRECTOR: "Baudirektor",
    PROJECT_OR_PACKAGE_MANAGER: "Bauleiter",
    COMMERCIAL_DIRECTOR: "Kaufmann",
    COMMERCIAL_MANAGER: "Kaufmännischer Bauleiter",
    PLANNER: "Planer",
    PROJECT_DIRECTOR: "Projektleiter",
    BOARD_DIRECTOR: "Geschäftsführer",
    CONSTRUCTION_OPERATIVE: "Bauarbeiter",
    SUB_CONTRACTOR_MANAGER: "Vorarbeiter",
    CENTRAL_BUSINESS_OR_OFFSITE_MANAGEMENT: "Unternehmenszentrale",
    THIRD_PARTY_INSPECTOR: "Unabhängiger Prüfer",
    SECTION_REGISTRATION_JOB_TITLE: "Position",
    SECTION_REGISTRATION_COMPANY_TYPE: "Art der Firma",

    IMAGE_OVERLAY_INFO_TITLE_LOCATION: "Standort",
    IMAGE_OVERLAY_INFO_TITLE_ALTITUDE: "Höhenlage",
    IMAGE_OVERLAY_INFO_TITLE_LONGITUDE: "Längengrad",
    IMAGE_OVERLAY_INFO_TITLE_LATITUDE: "Breitengrad",
    SIGNUP_NEW_TEXTS_WITH_MARKETING_CONSENT:
        "Mit der Anmeldung für Sablono erklären Sie sich mit unserer <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_DE.pdf' target='_blank'>Endbenutzer-Lizenzvereinbarung</a> und unserer <a href='https://www.sablono.com/de/datenschutz' target='_blank'>Daten- und Datenschutzrichtlinie</a> inkl. der Übermittlung in sog. Drittländer einverstanden. Sablono wird Ihnen relevante Informationen über unsere Produkte zukommen lassen. Sie können sich jederzeit von diesen Mitteilungen abmelden.",
    SIGNUP_NEW_TEXTS_WITHOUT_MARKETING_CONSENT:
        "Mit der Anmeldung für Sablono erklären Sie sich mit unserer <a href='https://www.sablono.com/hubfs/Website%20-%20Legal/230803_Sablono_EULA_INT_DE.pdf' target='_blank'>Endbenutzer-Lizenzvereinbarung</a> und unserer <a href='https://www.sablono.com/de/datenschutz' target='_blank'>Daten- und Datenschutzrichtlinie</a> inkl. der Übermittlung in sog. Drittländer einverstanden.",
    ACTION_ADD_COMMENT: "Kommentare und Fotos hinzufügen",
    ADD_NOTE_COMMENT_DIALOG_TITLE:
        "Kommentare und Fotos zur Meldung hinzufügen",
    ADD_NOTE_COMMENT_REDIRECT_DIALOG_TITLE: "Status ändern",
    ADD_NOTE_COMMENT_REDIRECT_DIALOG_TEXT:
        "Möchten Sie die Statusänderung mit einem Kommentar oder einem Foto ergänzen?",
    ADD_NOTE_COMMENT_DESCRIPTION_TITLE:
        "Geben Sie einen Text ein, der den Kommentar zur Notiz beschreibt",

    RESET_ACTIVITY_TITLE: "Aktivität zurücksetzen",
    RESET_ACTIVITY_CONFIRMATION:
        "Um fortzufahren, geben Sie bitte das Wort RESET ein",
    RESET_ACTIVITY_ACTION: "Alle Fortschritts- und Qualitätsdaten löschen",
    REQUEST_ACTIVITY_UPDATE: "Kollegen um ein Fortschrittsupdate bitten",
    REQUEST_ACTIVITY_UPDATE_EMAIL_SUBJECT:
        "{{projectName}} - Bitte aktualisieren Sie den Status der angehängten Aktivität auf Sablono",
    REQUEST_ACTIVITY_UPDATE_EMAIL_BODY:
        "Guten Tag! \n\n Bitte aktualisieren für mich Sie den Status der folgenden Aktivität auf Sablono: \n * {{deliverableCode}} - {{activityName}} \n * https://app.sablono.com/{{deliverableUrl}} \n\n Dazu können Sie den Link entweder nutzen, um die Aktivität in Ihrer mobilen Sablono App anzuzeigen oder Sie loggen sich über die Web App ein. \n\n Danke \n {{userName}}",
    RESET_ACTIVITY_DESCRIPTION:
        "Möchten Sie diese Aktivität wirklich <b>auf “nicht begonnen”</b> zurücksetzen?\n\nWenn Sie fortfahren, werden Fortschritts- inkl. Ist-Daten, angehängte Meldungen und QS Protokolle entfernt. Dies kann nur mit Unterstützung von Sablono rückgängig gemacht werden.",
    BULK_RESET_ACTIVITIES_TITLE: "Aktivitäten auf nicht begonnen zurücksetzen",
    BULK_RESET_ACTIVITIES_DESCRIPTION:
        "Möchten Sie die ausgewählten Aktivitäten wirklich <b>auf “nicht begonnen”</b> zurücksetzen?\n\nWenn Sie fortfahren, werden Fortschritts- inkl. Ist-Daten, angehängte Meldungen und QS Protokolle entfernt. Dies kann nur mit Unterstützung von Sablono rückgängig gemacht werden.",
    BULK_RESET_ACTIVITIES_HEADER:
        "Bitte wählen Sie die Aktivitäten der zuvor ausgewählten Projektelemente aus, die Sie zurücksetzen möchten. Dazu gehören Fortschritts- inkl. Ist-Daten, angehängte Meldungen und QS Protokolle entfernt.",
    BULK_RESET_ACTIVITIES_SUBMIT: "Aktivitäten zurücksetzen",
    BULK_RESET_ACTIVITIES_TOAST:
        "{{activities}}/{{totalActivitiesForBulkOperation}} Aktivitäten zurücksetzen",
    DIALOG_PRINT_ISSUE_REPORT_EMAIL_BEING_CREATED_TITLE:
        "Ihre Meldungen werden als PDF exportiert",
    DIALOG_PRINT_ISSUE_REPORT_EMAIL_BEING_CREATED_TEXT:
        "Nach der erfolgreichen Erstellung Ihres Meldungs-PDF-Berichts, senden wir ihn an {{email}}. Bitte beachten Sie, dass dies basierend auf der Menge hinzugefügter Fotos einige Zeit in Anspruch nehmen kann.",
    SEND_REPORT_TO_ADDITIONAL_EMAILS:
        "QS Protokolle inkl. Kommentar mit Ihren Kollegen teilen",

    INITIAL_ASSIGNMENT_OF_MULTIPLE_COMMERCIAL_MANAGERS_ERROR:
        "Sie dürfen nur den ersten Kaufmännischen Leiter zu diesem Team hinzufügen, danach übernimmt dieser die weitere Administration.",
    PDF_EXCEEDS_LIMIT:
        "Ihr hochgeladenes PDF ist größer als 50MB. Bitte laden Sie eine kleinere Version hoch.",
};

module.exports = translation;
